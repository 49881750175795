<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import store from '@/store'
import axios from 'axios';
//import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";


import vSelect from "vue-select";
//import Modal from '../../../components/Modal.vue';
import AjoutFournisseur from '../fournisseurs/AjoutFournisseur.vue';
import Swal from 'sweetalert2';
//import ModalNoBorder from '../../../components/ModalNoBorder.vue';
//const mode = ref('intervention');
//const submode = ref('demande_devis')
const objet = ref('')
const message = ref('')
const residents_fournisseur = ref([])
const mode_fournisseur = ref(false)
const lot = ref([])
const immeubles = ref([])
const immeubles_types = ref('Tous')
const showFilterImmeuble = ref(false)
const entreprise = ref('')
const entreprises = ref([])
const metiers = ref([])
const metier = ref('copropriete')
const categorie = ref({
    gestionnaire: false,
    negociateur: false,
    assistante: false,
    comptable: false,
    imm_entreprise: false,
    directeur: false,
    direction_generale: false,
    informatique: false,
    ressources_humaines: false,

})
const clefs = ref([])
const mandataires = ref([])
const immeuble = ref({
    nom: '',
    id: 0

})
const etages = ref([])
const batiments = ref([])
const escaliers = ref([])
const portes = ref([])
const immeuble_full = ref({})

const lotsValueProp = ref([])
const new_declarant = ref({
    nom: '',
    prenom: '',
    civilite: 'Civilité',
    mail: '',
    phone: '',
    adresse: '',
    code_postal: '',
    ville: '',
    pays: '',
    imm_aff: [],
    lot: '',
    mandataire: '',
    connaissance_localisation: 'connait_son_proprietaire',
    resident: true
})
const all_persos_loading = ref(false)
const showModalDeclarant = ref(true)
const search_declarant = ref('')
const metier_block = ref(true)
const etablissements = ref([])
const etablissement = ref('')
const showModalAddEntreprise = ref(false)
const showAjoutPerso = ref(false)




//const mode_contrat = ref('avec')
const lotsValue = ref([])
const resident = ref('')
const cops = ref([])
const all_persos = ref([])
const onLotChange = (value) => {
    console.log(value)
    //  getImmeubleCoproprietaires()
}


// get v-model from parent
const emit = defineEmits(['refresh'])

onMounted(() => {
    /*const imm = props.immeubleprop;
    immeuble.value = imm
    console.log("immeuble.value", immeuble.value)
    getImmeubleCoproprietaires();
    getMetiers()
    getImmeubleFull();*/
})


watch(immeubles_types, () => {
    showFilterImmeuble.value = false;
})


const props = defineProps({
    callingInfo: {
        type: Object,
        required: true
    }
})

// listen callingInfo prop
watch(() => props.callingInfo, (value) => {
    // convert +33XXXXXXX to 0XXXXXXXXX
    if (value.num) {
        if (value.num.includes('+33')) {
            var numtmp = value.num.replace('+33', '0');
        search_declarant.value = numtmp;
        }
    }
})





const getImmeubleFull = async () => {
    await store.dispatch('immeuble/getImmeuble', immeuble.value.id)
    const imm = store.getters['immeuble/oneImmeuble']
    console.log(imm)
    //immeuble.value = imm;
    immeuble_full.value = imm

    clefs.value = [];
    imm.attributes.forEach(element => {
        if (element.attr_type === 'codes') {
            // json decode value
            element.value = JSON.parse(element.value);
            element.value.forEach(el => {
                el.title = el.code + ' - ' + el.localisation;
                clefs.value.push(el);
            });
        }
    });
    mandataires.value = [];


    imm.lots.forEach(element => {
        if (element.perso_mini && element.perso_mini.id) {
            if (element.perso_mini.mandataire && element.perso_mini.mandataire.id) {

                if (mandataires.value.findIndex(el => el.id === element.perso_mini.mandataire.id) === -1) {
                    element.perso_mini.mandataire.lots = JSON.parse(JSON.stringify([element]));
                    element.perso_mini.mandataire.title = element.perso_mini.mandataire.nom;
                    mandataires.value.push(element.perso_mini.mandataire);
                } else {
                    mandataires.value[mandataires.value.findIndex(el => el.id === element.perso_mini.mandataire.id)].lots.push(element);
                }
            }
            //mandataires.value.push(element);
        }

        // batiments
        if (batiments.value.findIndex(el => el === element.bat) === -1) {
            batiments.value.push(element.bat);
        }
        // etages
        if (etages.value.findIndex(el => el === element.etage) === -1) {
            etages.value.push(element.etage);
        }
        // escaliers
        if (escaliers.value.findIndex(el => el === element.escalier) === -1) {
            escaliers.value.push(element.escalier);
        }
        // portes
        if (portes.value.findIndex(el => el === element.porte) === -1) {
            portes.value.push(element.porte);
        }



    });






}

const getAllPerso = async () => {
    console.log(search_declarant.value)
    // if numbers detected and no letter, add space between two numbers even if number is not fully completed
    if (search_declarant.value.match(/^[0-9 ]+$/) != null) {
        search_declarant.value = search_declarant.value.replace(/(\d)(\d)(?=\d)/g, '$1$2 ');

        if (search_declarant.value.length < 10) {
            return;
        }

    }



    if (search_declarant.value.length < 4) {
        return;
    }
    all_persos_loading.value = true;
    await store.dispatch('perso/persosList', {
        search: search_declarant.value,
        page: 1,
        perpage: 20
    })
    var persostmp = store.getters['perso/persosList']?.data

    persostmp = persostmp.map(personn => {
        const imm_aff = personn.imm_links.reduce((acc, imm) => {
            if (imm.immeuble_mini && !acc.includes(imm.immeuble_mini)) {
                if (imm.actif > 0 || (imm.bail_actif && imm.bail_actif.date_fin_bail_effective === null)) {
                    if (acc.findIndex((i) => i.id === imm.immeuble_mini.id) === -1) {
                        acc.push(imm.immeuble_mini);
                    }

                }
            }
            return acc;
        }, []);
        return { ...personn, imm_aff };
    });
    console.log(persostmp)
    all_persos_loading.value = false;
    all_persos.value = persostmp;
}





watch(() => search_declarant.value, () => {
    getAllPerso()
})

const getImmeubleCoproprietaires = async () => {
    await store.dispatch('immeuble/getImmeubleResidents', immeuble.value.id)
    const imm = store.getters['immeuble/oneImmeubleResidents']
    console.log(imm)
    cops.value = [];

    imm.lots.forEach(element => {
        let s = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === element.EXTAPI_PERSO_ID);
        if (s === -1) {
            let elnew = { bail: null, EXTAPI_IMM_ID: element.EXTAPI_IMM_ID, perso_mini: element.perso_mini, lots: [element], EXTAPI_PERSO_ID: element.EXTAPI_PERSO_ID };

            elnew.title = element.perso_mini.nom + ' ' + element.perso_mini.prenom;
            elnew.nom = element.perso_mini.nom;
            elnew.prenom = element.perso_mini.prenom;
            elnew.locations = [];
            if (element.locataires.length > 0) {
                element.locataires.forEach(locataire => {
                    if (locataire.bail_actif) {
                        locataire.bail_actif.ext_locataire.forEach(loca => {
                            if (elnew.locations.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID) === -1) {

                                elnew.locations.push(loca)
                            }
                        });
                    }
                });
            }

            cops.value.push(elnew);
        } else {

            if (element.locataires.length > 0) {
                element.locataires.forEach(locataire => {
                    if (locataire.bail_actif) {
                        locataire.bail_actif.ext_locataire.forEach(loca => {
                            if (cops.value[s].locations.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID) === -1) {
                                cops.value[s].locations.push(loca)
                            }
                        });
                    }
                });
            }
            cops.value[s].lots.push(element);
        }

        element.locataires.forEach(locataire => {
            if (locataire.bail_actif) {
                locataire.bail_actif.ext_locataire.forEach(loca => {
                    loca.title = loca.perso_mini.nom + ' ' + loca.perso_mini.prenom;
                    loca.nom = loca.perso_mini.nom;
                    loca.prenom = loca.perso_mini.prenom;
                    let s2 = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID);
                    if (s2 === -1) {
                        let lt = { ...loca, bail: { lots: [{ ...element, perso_mini: element.perso_mini }] }, lots: [{ ...element, perso_mini: element.perso_mini }], locataire: true };
                        cops.value.push(lt);
                    }
                });


            }
        }

        )

        // if cop already match with the correct one


    });

    /*
        imm.coplots_actifs.forEach(element => {
            let s = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === element.EXTAPI_PERSO_ID);

            if (s === -1) {
                if (element.date_vente === null) {
                    if (element.lots === undefined) {
                        element.lots = [];
                    }
                    element.title = element.perso_mini.nom + ' ' + element.perso_mini.prenom;
                    cops.value.push(element);
                }
            } else {
                if (element.date_vente === null) {
                    cops.value[s].lots.push(element.lots[0]);
                }
            }
            element.lots.forEach(lot => {
                lot.baux_actifs.forEach(tbail => {
                    tbail.ext_locataire.forEach(loca => {
                        loca.title = loca.perso_mini.nom + ' ' + loca.perso_mini.prenom;
                        let s2 = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID);
                        if (s2 === -1) {
                            let lt = { ...loca, lots: [{ ...lot, perso_mini: element.perso_mini }], locataire: true };
                            cops.value.push(lt);
                        }
                    });
                });
            });
        })
    */
    cops.value = cops.value.sort((a, b) => a.title.localeCompare(b.title));


    // search for resident
    if (resident.value) {
        const s = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === resident.value.EXTAPI_PERSO_ID);
        if (s !== -1) {
            resident.value = cops.value[s];
            //update lotsValue
            lotsValue.value = [];
            resident.value.lots.forEach(element => {
                lotsValue.value.push({ title: element.EXTAPI_LOT_ID + ' - ' + element.typedescr.texte + ' - Bat ' + element.bat + ' - Etage ' + element.etage, value: element.id });

            });

        }
    }
}

watch(() => immeuble.value, (value) => {
    if(value.id === 0) return;
    if(value.id === undefined) return;
    console.log(value)
    //    immeuble.value = imm;
    getImmeubleFull();
    //getImmeubleContrats();
    getImmeubleCoproprietaires()
})
/*
const isSuivantPossible = computed(() => {
    if (mode.value === 'intervention' && submode.value === 'demande_devis'
        && mode_contrat.value === 'sans' && entreprise.value && metier.value && etablissement.value

    ) {
        return true;
    }
    if (mode.value === 'intervention' && submode.value === 'demande_intervention' && mode_contrat.value === 'sans' && entreprise.value && metier.value && etablissement.value) {
        return true;
    }
    return false;
})*/

// watch change on declarant
const onResidentChange = async (value, tiers = 0) => {
    // set
    value.title = value.nom + ' ' + value.prenom;
    lotsValue.value = [];
    value.tiers = tiers;
    showModalDeclarant.value = false
    resident.value = value;
    mode_fournisseur.value = false;

    // get

    /*
            console.log(value)
            lotsValue.value = [];
            lot.value = '';
            value.lots.forEach(element => {
                lotsValue.value.push({ title: element.EXTAPI_LOT_ID + ' - ' + element.typedescr.texte + ' - Bat ' + element.bat + ' - Etage ' + element.etage, value: element.id });
                if (element.typedescr.texte == 'APPARTEMENT') {
                    lot.value = { title: element.EXTAPI_LOT_ID + ' - ' + element.typedescr.texte + ' - Etage ' + element.etage, value: element.id }
                }
            });

    */

    if (value.qualite.includes('F')) {
        mode_fournisseur.value = true;
    }


    // get immeubles
    immeubles.value = [];
    if (!value.imm_links || value.imm_links.length === 0) {
        return;
    }
    value.imm_links.forEach(element => {
        if (element.immeuble_mini) {
            if (element.immeuble_mini.nom === null) {
                element.immeuble_mini.nom = '';
            }
            if (element.immeuble_mini.adresse1 === null) {
                element.immeuble_mini.adresse1 = '';
            }
            element.immeuble_mini.title = element.immeuble_mini.nom + ' - ' + element.immeuble_mini.adresse1;
            immeubles.value.push(element.immeuble_mini);
        }
    });
    // getImmeubleResidents();

    if (immeubles.value.length === 1) {
        immeuble.value = immeubles.value[0];
        await getImmeubleCoproprietaires();
    }





}

const getImmeubles = async (search_tmp, loading) => {
    const search = search_tmp
    console.log(search)
    loading(true)

    switch (immeubles_types.value) {
        case "Tous":
            await store.dispatch('immeuble/immeublesList', { search: search })
            break;
        case "Syndic":
            await store.dispatch('immeuble/immeublesList', { search: search, metier: 'copro' })
            break;
        case "Gérance":
            await store.dispatch('immeuble/immeublesList', { search: search, metier: 'gerance' })
            break;
        case "Inactifs":
            await store.dispatch('immeuble/immeublesList', { search: search, pertemandat: 1 })
            break;
    }
    const imm = store.getters['immeuble/immeublesList'].data
    console.log(imm)
    imm.forEach(element => {
        if (element.nom === null) {
            element.nom = '';
        }
        element.title = element.nom + ' - ' + element.adresse1;
    });
    /* imm.forEach(element => {
         if (element.denominationUniteLegale === null) {
             element.denominationUniteLegale = '';
         }
         if (element.nomUniteLegale === null) {
             element.nomUniteLegale = '';
         }
         element.title = element.denominationUniteLegale + ' ' + element.nomUniteLegale;
     });*/
    immeubles.value = imm;
    loading(false)

}





const distanceImmeuble = (imm) => {
    const m = Math.sqrt(Math.pow(imm.latitude - immeuble.value.lat, 2) + Math.pow(imm.longitude - immeuble.value.lng, 2));

    return m
}

const onEntrepriseChange = (value) => {
    console.log(value)
    entreprise.value = value;
    // appliquer le code métier dans le champ
    // remove dot
    const search = value.activitePrincipaleUniteLegale


    metier.value = metiers.value.find(a => a.code === search);

    var etablissements_tmp = value.fournisseurs;
    // order etablissements by distance from immeuble
    etablissements_tmp = etablissements_tmp.sort((a, b) => distanceImmeuble(a) - distanceImmeuble(b));
    etablissements_tmp = etablissements_tmp.filter(a => a.etatAdministratifEtablissement === 'A');

    metier_block.value = false;
    etablissements_tmp.forEach(
        element => {
            element.title = element.typeVoieEtablissement + ' ' + element.libelleVoieEtablissement + ' ' + element.codePostalEtablissement + ' ' + element.libelleCommuneEtablissement;
        }
    )

    if (etablissements_tmp.length === 1) {
        etablissement.value = etablissements_tmp[0];
    }

    etablissements.value = etablissements_tmp;

}


const getMetiers = async () => {
    /*const search = search_tmp
    console.log(search)*/
    //loading(true)

    await store.dispatch('fournisseur/metiersList', {})


    const imm = store.getters['fournisseur/metiersList']
    console.log(imm)
    metiers.value = imm;
    if (imm.length === 1) {
        metier.value = imm[0];
    }

    // IndexedDB version

    //loading(false)

}


// listen to global addfournisseur emitted event this.$emit('addfournisseur', re);


const onAddFournisseur = async (re) => {
    console.log(re)
    if (re.denominationUniteLegale === null) {
        re.denominationUniteLegale = '';
    }
    if (re.nomUniteLegale === null) {
        re.nomUniteLegale = '';
    }
    re.title = re.denominationUniteLegale + ' ' + re.nomUniteLegale;
    entreprises.value.push(re);
    entreprise.value = re;


    await getMetiers()
    onEntrepriseChange(re);

    showModalAddEntreprise.value = false;


}



const backdropClickAddFournisseur = (event) => {
    if (event.target.id == 'backdrop') {
        showModalAddEntreprise.value = false
    }
}

const ajoutPersoMode = () => {
    new_declarant.value = {
        nom: '',
        prenom: '',
        civilite: 'Civilité',
        mail: '',
        phone: '',
        adresse: '',
        code_postal: '',
        ville: '',
        pays: '',
        imm_aff: [],
        mandataire: '',
        connaissance_localisation: 'connait_son_proprietaire',
        resident: true
    }
    // get search value and set it to new_declarant
    if (search_declarant.value.length > 3) {
        // if @ is in search_declarant, it's a mail
        if (search_declarant.value.includes('@')) {
            new_declarant.value.mail = search_declarant.value;
        } else {
            // if it's a phone number
            if (search_declarant.value.match(/^[0-9 ]+$/) != null) {
                new_declarant.value.phone = search_declarant.value;
            } else {
                // if there is a space, it's a name and firstname
                if (search_declarant.value.includes(' ')) {
                    const name = search_declarant.value.split(' ');
                    new_declarant.value.nom = name[0];
                    new_declarant.value.prenom = name[1];
                } else {
                    new_declarant.value.nom = search_declarant.value;
                }
            }
        }
    }
    showAjoutPerso.value = true;
}


// utiliser la base adresse nationale pour compléter l'adresse
const getAdresse = async () => {
    console.log(new_declarant.value)
    if (new_declarant.value.code_postal.length < 5) {
        return;
    }
    if (new_declarant.value.code_postal.length > 5) {
        new_declarant.value.code_postal = new_declarant.value.code_postal.substr(0, 5);
    }
    fetch('https://api-adresse.data.gouv.fr/search/?q=' + new_declarant.value.code_postal)
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.features.length > 0) {
                new_declarant.value.ville = data.features[0].properties.city;
            }
        });
}

const onDeclarantProprietaireChange = (value) => {
    console.log(value)
    lotsValueProp.value = [];
    new_declarant.value.lot = '';
    value.lots.forEach(element => {
        lotsValueProp.value.push({ title: element.EXTAPI_LOT_ID + ' - ' + element.typedescr.texte + ' - Bat ' + element.bat + ' - Etage ' + element.etage, value: element.id, perso_mini: element.perso_mini });
        if (element.typedescr.texte.includes('APPARTEMENT')) {
            //    new_declarant.value.lot = { title: element.EXTAPI_LOT_ID + ' - ' + element.typedescr.texte + ' - Etage ' + element.etage, value: element.id }
        }
    });
}

const searchResults = ref([]);

const searchAddress = async () => {
    if (new_declarant.value.adresse < 3) return;

    try {
        const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(new_declarant.value.adresse)}&limit=5`);
        searchResults.value = response.data.features;
    } catch (error) {
        console.error('Erreur lors de la recherche d\'adresse:', error);
    }
};

const selectAddress = (object, adresse) => {

    object.adresse = adresse.properties.name;
    object.code_postal = adresse.properties.postcode;
    object.ville = adresse.properties.city;

    // Effacer les résultats de recherche
    searchResults.value = [];
};

const collabActives = computed(() => {
    let collabs = [];
    collabs = JSON.parse(localStorage.getItem('collabs'));

    console.log("COLLIMM1", immeuble_full.value.collaborateurs)
    if (immeuble_full.value.id > 0) {
        console.log("COLLIMM2", immeuble_full.value.collaborateurs)
        collabs = immeuble_full.value.collaborateurs;
    }
    return collabs.filter(collab => {
        if (collab.metier === metier.value) {
            return true;
        }
        let autres = JSON.parse(collab.en_charge_services);
        if (autres && metier.value && autres.includes(metier.value)) {
            return true;
        }
        if (autres && categorie.value.informatique && autres.includes('informatique')) {
            return true;
        }
        if (autres && categorie.value.comptable && autres.includes('comptable')) {
            return true;
        }
        if (autres && categorie.value.ressources_humaines && autres.includes('ressources_humaines')) {
            return true;
        }
        if (autres && categorie.value.direction_generale && autres.includes('direction_generale')) {
            return true;
        }


    }


    ).filter(collab => {
        if (collab.qualite === 'Gestionnaire' && categorie.value.gestionnaire) {
            return true;
        }
        if (collab.qualite === 'Négociateur' && categorie.value.negociateur) {
            return true;
        }
        if (collab.qualite === 'Assistante' && categorie.value.assistante) {
            return true;
        }
        if (collab.qualite === 'Comptable' && categorie.value.comptable) {
            return true;
        }
        if (collab.qualite === 'Directeur' && categorie.value.directeur) {
            return true;
        }
        let autres = JSON.parse(collab.en_charge_services);
        if (autres && categorie.value.informatique && autres.includes('informatique')) {
            return true;
        }
        if (autres && categorie.value.ressources_humaines && autres.includes('ressources_humaines')) {
            return true;
        }
        if (autres && categorie.value.direction_generale && autres.includes('direction_generale')) {
            return true;
        }

    }
    )
})



const validerAjoutTiers = async () => {

    if (!showAjoutPerso.value) {
        if (!resident.value) {
            alert('Merci de sélectionner un résident')
            return;
        }

        if ((!lot.value || lot.value.length === 0) && !mode_fournisseur.value) {
            alert('Merci de sélectionner un lot')
            return;
        }
        if (!objet.value) {
            alert('Merci de sélectionner un objet')
            return;
        }
        if (!message.value) {
            alert('Merci de saisir un message')
            return;
        }
        console.log(resident.value)

        let collList = [];
        collabActives.value.forEach(collab => {
            collList.push(collab.id);
        })

        await store.dispatch('call/createcall', {
            declarant_id: resident.value.perso_mini ? resident.value.perso_mini.id : resident.value.id,
            immeuble_id: immeuble.value.id,
            lots: lot.value,
            objet: objet.value,
            message: message.value,
            categorie: categorie.value,
            metier: metier.value,
            collaborateurs: collList
        })

        console.log('validerAjoutTiers',
            resident.value.perso_mini, lot.value, objet.value, message.value, categorie.value, metier.value, collabActives.value
        )


        // notify and reset all values
        objet.value = '';
        message.value = '';
        lotsValue.value = [];
        lot.value = [];
        resident.value = '';
        showAjoutPerso.value = false;
        showModalDeclarant.value = true;
        resident.value = '';
        cops.value = [];
        immeubles.value = [];
        immeuble.value = '';
        immeuble_full.value = {};
        etages.value = [];
        batiments.value = [];
        escaliers.value = [];
        portes.value = [];
        clefs.value = [];
        mandataires.value = [];
        search_declarant.value = '';
        all_persos.value = [];

        categorie.value = {
            gestionnaire: false,
            negociateur: false,
            assistante: false,
            comptable: false,
            imm_entreprise: false,
            directeur: false,
            direction_generale: false,
            informatique: false,
            ressources_humaines: false,

        }
        metier.value = 'copropriete';

        Swal.fire({
            title: 'Appel enregistré',
            text: 'L\'appel a bien été enregistré',
            icon: 'success',
            confirmButtonText: 'OK',
            timer: 1500


        })
        console.log('refresh_ask')
        emit('refresh')
        // emit refresh_ask






        return;
    }


    // verifier que la civilité est remplie
    if (new_declarant.value.civilite === 'Civilité') {
        alert('La civilité est obligatoire')

        return;
    }
    // verifier que les champs sont remplis
    if (new_declarant.value.nom.length < 2) {
        alert('Le nom est obligatoire')
        return;
    }
    if (new_declarant.value.prenom.length < 2) {
        alert('Le prénom est obligatoire')
        return;
    }


    // verifier que le mail ou le téléphone est rempli et valide
    if (new_declarant.value.mail.length < 2 || !new_declarant.value.mail.includes('@')) {
        if (!new_declarant.value.mail_oppose) {
            alert('Le mail est obligatoire')
            return;
        }
    }
    // telephone avec 10 chiffres (espaces, points et tirets possibles)
    if (new_declarant.value.phone.length < 2 || new_declarant.value.phone.match(/^[0-9 .-]+$/) === null) {
        if (!new_declarant.value.phone_oppose) {
            alert('Le téléphone est obligatoire')
            return;
        }
    }

    // si locataire et connait son propriétaire, vérifier que le résident et le lot sont rempli
    if (new_declarant.value.connaissance_localisation === 'connait_son_proprietaire') {
        if (!new_declarant.value.proprietaire) {
            alert('Le propriétaire est obligatoire')
            return;
        }
        if (!new_declarant.value.lot) {
            alert('Le lot est obligatoire')
            return;
        }
    }

    // si locataire et connait son gérant, vérifier que le mandataire est rempli et le lot
    if (new_declarant.value.connaissance_localisation === 'connait_son_mandataire') {
        if (!new_declarant.value.mandataire) {
            alert('Le gérant est obligatoire')
            return;
        }

        if (!new_declarant.value.lot) {
            alert('Le lot est obligatoire')
            return;
        }
    }

    // si locataire et sans contacts, étage obligatoire
    if (new_declarant.value.connaissance_localisation === 'sans_contacts') {
        if (!new_declarant.value.etage) {
            alert('L\'étage est obligatoire')
            return;
        }
    }

    // si n'est pas résident, vérifier que l'adresse est remplie
    if (new_declarant.value.connaissance_localisation === 'non_resident') {
        if (!new_declarant.value.qualite) {
            alert('Merci d\'indiquer une qualité')
            return;
        }


        if (new_declarant.value.adresse.length < 2) {
            alert('Merci d\'indiquer une adresse')
            return;
        }
        if (new_declarant.value.code_postal.length < 5) {
            alert('Merci d\'indiquer un code postal')
            return;
        }
        if (new_declarant.value.ville.length < 2) {
            alert('Merci d\'indiquer une ville')
            return;
        }

    }


    // save with store
    store.dispatch('perso/addPerso', new_declarant.value)


    onResidentChange(new_declarant.value, 1)
    showAjoutPerso.value = false;
}

</script>
<template>
    <div style="text-align: center;">








        <teleport to="body">
            <transition name="fade">
                <div v-if="showModalAddEntreprise" class=" position-fixed top-0 start-0 h-100 w-100"
                    style="background-color: rgba(0, 0, 0, 0.25);z-index: 10;">
                    <div id="backdrop" @click="backdropClickAddFournisseur"
                        class="modal1-dialog h-100 overflow-auto text-center">
                        <div class="w-70 pt-5 " style="margin-left: 15%;">
                            <div class="bg-white card" style="overflow-y: scroll;">
                                <button class="btn btn-xs btn-danger" style="
position: absolute;
z-index: 15;
margin: 10px;
" @click="showModalAddEntreprise = false">Annuler</button>

                                <AjoutFournisseur @add-fournisseur="onAddFournisseur" />
                            </div>

                        </div>

                    </div>
                </div>
            </transition>
        </teleport>
        <!--
        <ModalNoBorder v-model="showModalAddEntreprise" closeable @close="showModalAddEntreprise = false"
            header="Ajouter un fournisseur">
        </ModalNoBorder>-->
        <div class="card card-sm p-2 mt-1">
            <!--<h1 class="h4">Nouvel appel</h1>-->

            <div class="w-100 pt-1 ml-2 mr-2 mb-2" v-if="showModalDeclarant">
                <Transition name="slide-up" mode="out-in">
                    <div class="card px-0 w-100 h-80 p-2 bg-yellow" v-if="showAjoutPerso == false">

                        <div class="p-2 ">
                            <!--<div class="d-flex">
                                <div style="flex:1"></div>
                                <div class="text-bold">Appelant</div>
                                <div style="flex:1"></div>
                            </div>-->

                            <div class="d-flex">
                                <!--   <MazPhoneNumberInput
      v-model="search_declarant"
      country-locale="fr-FR"
      :translations="{
        countrySelector: {
          placeholder: 'Pays',
          error: 'Choose country',
          searchPlaceholder: 'Chercher un pays',
        },
        phoneInput: {
          placeholder: 'Numéro de téléphone',
          example: 'Exemple :',
        },
      }"
      @update="result = $event"
    />-->
                                <input type="search" class="form-control"
                                    placeholder="Rechercher par nom, prénom, mаil ou téléphone"
                                    v-model="search_declarant" />

                                <button class="btn btn-white mb-0" @click="ajoutPersoMode"><i
                                        class="fa fa-plus"></i>Ajouter une nouvelle
                                    personnalité</button>

                            </div>

                            <div class="bg-white rounded text-xs text-dark text-start p-2 m-2" v-if="all_persos.length">

                                <table class="w-100">
                                    <tr class="border-bottom" v-for="perso in all_persos" :key="perso.id">
                                        <td class="text-xs">{{ perso.nom }} {{ perso.prenom }}</td>
                                        <td class="">{{ perso.mails[0]?.mail.substr(0, 30) }}<br>
                                            {{ perso.phones[0]?.phone }}
                                        </td>
                                        <td style="flex:1">
                                            <a class="badge badge-xs bg-info" v-if="perso.qualite.includes('C')">COP</a>
                                            <a class="badge badge-xs bg-danger"
                                                v-if="perso.qualite.includes('P')">PROP</a>
                                            <a class="badge badge-xs bg-purple"
                                                v-if="perso.qualite.includes('F')">FOUR</a>
                                            <a class="badge badge-xs bg-info" v-if="perso.qualite.includes('L')">LOC</a>
                                        </td>
                                        <td style="flex:1">
                                            <a v-for="i in perso.imm_aff" :key="i" class="badge badge-xs bg-danger">{{
                    i.nom }}</a>
                                        </td>
                                        <td class="text-end"><button class="btn btn-xs btn-white bg-yellow mb-0"
                                                @click="onResidentChange(perso, true);">Sélectionner </button>

                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div v-if="all_persos_loading" class="text-center">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>
                            <div v-if="!all_persos_loading && all_persos.length == 0 && search_declarant.length > 3">
                                <br>
                                Aucune personne trouvée
                                <br>
                            </div>


                        </div>
                    </div>
                    <div class="card px-0 w-100 h-80 p-2 bg-yellow" v-else>

                        <div class="p-2 ">
                            <div class="d-flex">
                                <button class="btn btn-xs btn-white" @click="showAjoutPerso = false">Annuler</button>
                                <div style="flex:1"></div>
                                <div class="text-bold">Créer un déclarant</div>


                            </div>
                            <div class="bg-white text-xs text-dark text-start p-2 m-2" style="border-radius: 1rem;">
                                <div class="row">
                                    <div class="col-md-2">
                                        <!--Civilite-->
                                        <select class="form-control" v-model="new_declarant.civilite"
                                            placeholder="Civilité">
                                            <option selected disabled value="Civilité">Civilité</option>
                                            <option value="M">M</option>
                                            <option value="Mme">Mme</option>
                                        </select>
                                    </div>

                                    <div class="col-md-5">
                                        <input class="form-control" placeholder="Nom" v-model="new_declarant.nom">

                                    </div>
                                    <div class="col-md-5">
                                        <input class="form-control" placeholder="Prénom" v-model="new_declarant.prenom">

                                    </div>
                                    <div class="col-md-6">
                                        <div class="input-group">

                                            <input class="form-control" type="mail" placeholder="Email"
                                                :autocomplete="false" v-model="new_declarant.mail"
                                                :disabled="new_declarant.mail_oppose">
                                            <div class="input-group-append">
                                                <button class="btn btn-xs mb-0 h-100"
                                                    @click="new_declarant.mail_oppose = !new_declarant.mail_oppose"
                                                    :class="new_declarant.mail_oppose ? 'btn-danger' : 'btn-outline-danger'"><i
                                                        class="fa fa-times"></i>Opposé
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-md-6">
                                        <div class="input-group">

                                            <input class="form-control" type="phone" placeholder="Téléphone"
                                                v-model="new_declarant.phone" :disabled="new_declarant.phone_oppose">
                                            <div class="input-group-append">
                                                <button class="btn btn-xs mb-0 h-100"
                                                    @click="new_declarant.phone_oppose = !new_declarant.phone_oppose"
                                                    :class="new_declarant.phone_oppose ? 'btn-danger' : 'btn-outline-danger'"><i
                                                        class="fa fa-times"></i>Opposé
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>




                        </div>
                    </div>


                </Transition>
            </div>
            <div v-else class="bg-yellow border-radius-lg p-2 mb-2">
                <div style="display: flex;">
                    <div class="bg-yellow border-radius-lg pl-2 text-dark text-xs pr-3" style="padding-top: 8px;">
                        Déclarant</div>
                    <div class="bg-yellow border-radius-lg  text-bold text-dark w-100" style=" font-weight: 700;">

                        <vSelect style="color:white" :options="cops" label="title" :clearable="false"
                            placeholder="Choisir un résident" v-model="resident" @update:modelValue="onResidentChange"
                            class="style-chooser-yellow">
                            <template v-slot:no-options>Aucun résident trouvé.</template>
                            <template #option="{ title, locataire, lots }">
                                <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">{{ title }}
                                    <a v-if="locataire && lots[0]" style="font-size:8pt">PROP : {{
                    lots[0].perso_mini.nom }}</a>
                                </div>
                            </template>
                        </vSelect>
                    </div>

                    <el-tooltip content="Choisir un autre déclarant" placement="left">
                        <button class="btn btn-xs btn-white mb-0" @click="showModalDeclarant = true"><i
                                class="fa fa-pen"></i></button>
                    </el-tooltip>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="bg-red border-radius-lg p-2 mb-2" style="display: flex;">
                            <div class="bg-red border-radius-lg pl-2 text-white text-xs pr-3" style="padding-top: 8px;">
                                Immeuble
                            </div>
                            <div class="bg-red border-radius-lg text-bold text-white w-100" style=" font-weight: 700;">

                                <vSelect style="color:white" :options="immeubles" label="title" :clearable="false"
                                    placeholder="Choisir un résident" v-model="immeuble" @search="getImmeubles"
                                    :filterable="false" class="style-chooser-red">
                                    <template v-slot:no-options>Aucun immeuble trouvé.</template>
                                    <template #option="{ nom, adresse1, adresse2 }">
                                        <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">
                                            {{ nom }}
                                            <a style="font-size:8pt">{{ adresse1 }} {{ adresse2 }}</a>
                                        </div>
                                    </template>
                                </vSelect>


                            </div>
                            <el-tooltip content="Filtrer les immeubles" placement="left">
                                <button v-if="!showFilterImmeuble" class="btn btn-xs btn-white mb-0"
                                    @click="showFilterImmeuble = true"><i class="fa fa-filter"></i>
                                    <a v-if="immeubles_types != 'Tous'">{{ immeubles_types }}</a>
                                </button>
                                <select v-else class="form-control" v-model="immeubles_types">
                                    <option value="Tous">Tous les immeubles actifs</option>
                                    <option value="Syndic">Syndic actifs</option>
                                    <option value="Gérance">Gérance actifs</option>
                                    <option value="Inactifs">Inactifs</option>
                                </select>
                            </el-tooltip>
                        </div>


                    </div>
                    <div class="col-md-6" v-if="mode_fournisseur && lotsValue.length == 0">
                        <div class="bg-gray border-radius-lg p-2 w-100">
                                    <div style="display: flex;">
                                        <div class="bg-gray border-radius-lg pl-2 text-white text-xs pr-3"
                                            style="padding-top: 8px;">
                                            Résident(s)
                                        </div>
                                        <div class="bg-gray border-radius-lg text-bold text-white w-100"
                                            style=" font-weight: 700;">

                                            <vSelect style="color:white" :options="cops" label="title"
                                                :clearable="false" multiple placeholder="Choisir un résident" v-model="residents_fournisseur"
                                                 class="style-chooser-gray">
                                            <template #option="{ title, locataire, lots }">
                                                <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">
                                                    {{ title }}
                                                    <a v-if="locataire && lots[0]" style="font-size:8pt">
                                                        PROP : {{ lots[0].perso_mini.nom }}
                                                    </a>
                                                </div>
                                            </template>
                                                <template v-slot:no-options>Aucun résident trouvé.</template>
                                            </vSelect>
                                        </div>

                                    </div>

                                </div>
                    </div>
                    <div class="col-md-6" v-else-if="mode_fournisseur">

                        <div class="bg-light border-radius-lg p-2 ">
                            <div class="btn-group">
                                <button class="btn btn-xs btn-light"
                                    :style="mode_fournisseur == 1 ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                    @click="mode_fournisseur = 1">
                                    Comme résident
                                </button>
                                <button class="btn btn-xs btn-light"
                                    :style="mode_fournisseur == 2 ? 'color: white; background-color: rgb(111, 66, 193);' : ''"
                                    @click="mode_fournisseur = 2">
                                    Comme fournisseur
                                </button>
                            </div>
                            <div style="display: flex;" v-if="mode_fournisseur == 1">

                                <div class="bg-gray border-radius-lg p-2 w-100">
                                    <div style="display: flex;">
                                        <div class="bg-gray border-radius-lg pl-2 text-white text-xs pr-3"
                                            style="padding-top: 8px;">
                                            Lot(s)
                                        </div>
                                        <div class="bg-gray border-radius-lg text-bold text-white w-100"
                                            style=" font-weight: 700;">

                                            <vSelect style="color:white" :options="lotsValue" label="title"
                                                :clearable="false" multiple placeholder="Choisir un lot" v-model="lot"
                                                @update:modelValue="onLotChange" class="style-chooser-gray">
                                                <template v-slot:no-options>Aucun lot trouvé.</template>
                                            </vSelect>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div style="display: flex;" v-if="mode_fournisseur == 2">

                                <div class="bg-gray border-radius-lg p-2 w-100">
                                    <div style="display: flex;">
                                        <div class="bg-gray border-radius-lg pl-2 text-white text-xs pr-3"
                                            style="padding-top: 8px;">
                                            Résident(s)
                                        </div>
                                        <div class="bg-gray border-radius-lg text-bold text-white w-100"
                                            style=" font-weight: 700;">

                                            <vSelect style="color:white" :options="cops" label="title"
                                                :clearable="false" multiple placeholder="Choisir un résident" v-model="residents_fournisseur"
                                                 class="style-chooser-gray">
                                            <template #option="{ title, locataire, lots }">
                                                <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">
                                                    {{ title }}
                                                    <a v-if="locataire && lots[0]" style="font-size:8pt">
                                                        PROP : {{ lots[0].perso_mini.nom }}
                                                    </a>
                                                </div>
                                            </template>
                                                <template v-slot:no-options>Aucun résident trouvé.</template>
                                            </vSelect>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
                    <div class="col-md-6" v-else-if="lotsValue.length > 0">
                        <div class="bg-gray border-radius-lg p-2 ">
                            <div style="display: flex;">
                                <div class="bg-gray border-radius-lg pl-2 text-white text-xs pr-3"
                                    style="padding-top: 8px;">Lot(s)
                                </div>
                                <div class="bg-gray border-radius-lg text-bold text-white w-100"
                                    style=" font-weight: 700;">

                                    <vSelect style="color:white" :options="lotsValue" label="title" :clearable="false"
                                        multiple placeholder="Choisir un lot" v-model="lot"
                                        @update:modelValue="onLotChange" class="style-chooser-gray">
                                        <template v-slot:no-options>Aucun lot trouvé.</template>
                                    </vSelect>
                                </div>

                            </div>

                        </div>

                    </div>


                </div>


            </div>

            <div class="btn-group">
                <button class="btn btn-light"
                    :style="metier == 'copropriete' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                    @click="metier = 'copropriete'">
                    Copropriétés
                </button>
                <button class="btn btn-light"
                    :style="metier == 'gerance' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                    @click="metier = 'gerance'">
                    Gérance locative
                </button>
                <button class="btn btn-light"
                    :style="metier == 'transaction' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                    @click="metier = 'transaction'">
                    Transaction
                </button>
                <button class="btn btn-light"
                    :style="metier == 'imm_entreprise' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                    @click="metier = 'imm_entreprise'">
                    Immobilier Entreprise
                </button>
                <button class="btn btn-light"
                    :style="metier == 'generaux' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                    @click="metier = 'generaux'">
                    Directions
                </button>
            </div>


            <div class="row">

                <div class="p-4" :class="showAjoutPerso ? 'col-md-6' : 'col-md-12'">


                    <div v-if="metier != 'generaux'">
                        <!-- Cases à cocher-->
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-check" style="display: flex;" v-if="metier != 'transaction'">
                                    <input class="form-check-input" type="checkbox" v-model="categorie.gestionnaire"
                                        name="gestionnaire">
                                    <label class="form-check-label" for="gestionnaire">
                                        Gestionnaire
                                    </label>
                                </div>
                                <div class="form-check" style="display: flex;" v-else>
                                    <input class="form-check-input" type="checkbox" v-model="categorie.negociateur"
                                        id="negociateur">
                                    <label class="form-check-label" for="negociateur">
                                        Négociateur
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check" style="display: flex;">
                                    <input class="form-check-input" type="checkbox" v-model="categorie.assistante"
                                        id="assistante">
                                    <label class="form-check-label" for="assistante">
                                        Assistant(e)
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3" v-if="metier != 'transaction'">
                                <div class="form-check" style="display: flex;">
                                    <input class="form-check-input" type="checkbox" v-model="categorie.comptable"
                                        id="comptable">
                                    <label class="form-check-label" for="mandataire">
                                        Comptable
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check" style="display: flex;">
                                    <input class="form-check-input" type="checkbox" v-model="categorie.directeur"
                                        id="directeur">
                                    <label class="form-check-label" for="directeur">
                                        Directeur
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!--
                <button class="btn btn-xs btn-light w-100"
                    :style="categorie == 'generale' ? 'color: white; background-color: rgb(119, 119, 119);' : ''"
                    @click="categorie = 'generale'">
                    <i class="fa fa-user"></i> Gestionnaire
                </button>
                <button class="btn btn-xs btn-light w-100"
                    :style="categorie == 'compta' ? 'color: white; background-color: rgb(119, 119, 119);' : ''"
                    @click="categorie = 'compta'">
                    <i class="fa fa-pen"></i> Assistant(e)
                </button>
                <button class="btn btn-xs btn-light w-100"
                    :style="categorie == 'informatique' ? 'color: white; background-color: rgb(119, 119, 119);' : ''"
                    @click="categorie = 'informatique'">
                    <i class="fa fa-euro"></i> Comptable
                </button>
                <button class="btn btn-xs btn-light w-100"
                    :style="categorie == 'informatique' ? 'color: white; background-color: rgb(119, 119, 119);' : ''"
                    @click="categorie = 'informatique'">
                    Directeur
                </button>-->
                    </div>
                    <div class="row" v-else>
                        <div class="col-md-3">
                            <div class="form-check" style="display: flex;">
                                <input class="form-check-input" type="checkbox" v-model="categorie.direction_generale"
                                    id="direction_generale">
                                <label class="form-check-label" for="direction_generale">
                                    Direction Générale
                                </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check" style="display: flex;">
                                <input class="form-check-input" type="checkbox" v-model="categorie.comptable"
                                    id="comptabilite">
                                <label class="form-check-label" for="comptabilite">
                                    Comptabilité
                                </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check" style="display: flex;">
                                <input class="form-check-input" type="checkbox" v-model="categorie.informatique"
                                    id="informatique">
                                <label class="form-check-label" for="informatique">
                                    Informatique
                                </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-check" style="display: flex;">
                                <input class="form-check-input" type="checkbox" v-model="categorie.ressources_humaines"
                                    id="ressources_humaines">
                                <label class="form-check-label" for="ressources_humaines">
                                    Ressources humaines
                                </label>
                            </div>
                        </div>
                    </div>
                    <a v-for="c in collabActives" :key="c.id">{{ c.user.given_name }} {{ c.user.family_name }} &nbsp;
                    </a>



                    <input class="form-control" placeholder="Objet de l'appel" v-model="objet" />
                    <br />
                    <textarea class="form-control" placeholder="Message de l'appel" v-model="message"
                        style="height: 210px;" lines="10"></textarea>
                </div>

                <div class="col-md-6" v-if="showAjoutPerso">

                    <div class=" p-2 border-radius-lg m-2 mt-4" style="background-color: color(srgb 0.935 0.935 0.935)">
                        <div class="btn-group" style="margin-top:-25px">
                            <button class="btn btn-light"
                                :style="new_declarant.resident ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                @click="new_declarant.resident = true; new_declarant.connaissance_localisation = 'connait_son_proprietaire'">
                                <i class="fa fa-building"></i> Résident
                            </button>
                            <button class="btn btn-light"
                                :style="new_declarant.resident == false ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                @click="new_declarant.resident = false; new_declarant.connaissance_localisation = 'en_lien_immeuble'"><i
                                    class="fa fa-close"></i> N'est pas résident</button>

                        </div>
                        <br />
                        <div class="btn-group" v-if="new_declarant.resident">
                            <button class="btn btn-xs btn-light"
                                :style="new_declarant.connaissance_localisation == 'connait_son_proprietaire' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                @click="new_declarant.connaissance_localisation = 'connait_son_proprietaire'">
                                <div class="text-xs">Locataire et</div>
                                <i class="fa fa-building"></i> Connait son propriétaire
                            </button>
                            <button class="btn btn-xs btn-light"
                                :style="new_declarant.connaissance_localisation == 'connait_son_mandataire' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                @click="new_declarant.connaissance_localisation = 'connait_son_mandataire'">
                                <div class="text-xs">Locataire et</div>
                                <i class="fa fa-smile"></i> Connait son gérant
                            </button>
                            <button class="btn btn-xs btn-light"
                                :style="new_declarant.connaissance_localisation == 'sans_contacts' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                @click="new_declarant.connaissance_localisation = 'sans_contacts'">
                                <div class="text-xs">Locataire et</div>
                                <i class="fa fa-question"></i> Sans contacts
                            </button>
                        </div>
                        <div class="btn-group" v-else>
                            <button class="btn btn-xs btn-light"
                                :style="new_declarant.connaissance_localisation == 'en_lien_immeuble' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                @click="new_declarant.connaissance_localisation = 'en_lien_immeuble'">
                                <i class="fa fa-building"></i> Objet <b class="text-bold">EN</b> lien
                                <div class="text-xs"> avec un immeuble géré</div>
                            </button>
                            <button class="btn btn-xs btn-light"
                                :style="new_declarant.connaissance_localisation == 'sans_lien_immeuble' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                @click="new_declarant.connaissance_localisation = 'sans_lien_immeuble'">
                                <i class="fa fa-close"></i> Objet <b class="text-bold">SANS</b> lien
                                <div class="text-xs">avec un immeuble géré</div>
                            </button>
                        </div>


                        <div v-if="new_declarant.connaissance_localisation == 'connait_son_proprietaire'"
                            class="bg-white text-xs text-dark text-start p-2 m-2" style="border-radius: 1rem;">

                            <div class=" bg-red border-radius-lg p-2 mb-2" style="display: flex;">
                                <div class="bg-red border-radius-lg pl-2 text-white text-xs pr-3"
                                    style="padding-top: 8px;">Immeuble
                                </div>
                                <div class="bg-red border-radius-lg p-1 text-bold text-white w-100"
                                    style="font-weight: 700;">

                                    <vSelect style="color:white" :options="immeubles" label="title" :clearable="false"
                                        placeholder="Choisir un résident" v-model="immeuble" @search="getImmeubles"
                                        :filterable="false" class="style-chooser-red">
                                        <template v-slot:no-options>Aucun immeuble trouvé.</template>
                                        <template #option="{ nom, adresse1, adresse2 }">
                                            <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">
                                                {{ nom }}
                                                <a style="font-size:8pt">{{
                    adresse1 }} {{ adresse2 }}</a>
                                            </div>
                                        </template>
                                    </vSelect>


                                </div>
                                <el-tooltip content="Filtrer les immeubles" placement="left">
                                    <button v-if="!showFilterImmeuble" class="btn btn-xs btn-white mb-0"
                                        @click="showFilterImmeuble = true"><i class="fa fa-filter"></i>
                                        <a v-if="immeubles_types != 'Tous'">{{ immeubles_types }}</a>
                                    </button>
                                    <select v-else class="form-control" v-model="immeubles_types">
                                        <option value="Tous">Tous les immeubles actifs</option>
                                        <option value="Syndic">Syndic actifs</option>
                                        <option value="Gérance">Gérance actifs</option>
                                        <option value="Inactifs">Inactifs</option>
                                    </select>
                                </el-tooltip>
                            </div>


                            <div class=" bg-yellow mb-2 p-1 border-radius-lg">

                                <div style="display: flex;">
                                    <div class="bg-yellow border-radius-lg pl-2 text-dark text-xs pr-3"
                                        style="padding-top: 8px;">
                                        Propriétaire</div>
                                    <div class="bg-yellow border-radius-lg p-1 text-bold text-white w-100"
                                        style="margin-top: -2px; font-weight: 700;">
                                        <vSelect style="color:white" :options="cops" label="title" :clearable="false"
                                            placeholder="Choisir un résident" v-model="new_declarant.proprietaire"
                                            @update:modelValue="onDeclarantProprietaireChange"
                                            class="style-chooser-yellow">
                                            <template v-slot:no-options>Aucun résident
                                                trouvé.</template>
                                            <template #option="{ title, locataire, lots }">
                                                <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">
                                                    {{ title }}
                                                    <a v-if="locataire && lots[0]" style="font-size:8pt">PROP :
                                                        {{
                    lots[0].perso_mini.nom }}</a>
                                                </div>
                                            </template>
                                        </vSelect>
                                    </div>

                                </div>
                            </div>

                            <div class=" bg-gray p-1 border-radius-lg">
                                <div style="display: flex;">
                                    <div class="bg-gray border-radius-lg pl-2 text-white text-xs pr-3"
                                        style="padding-top: 8px;">
                                        Lot(s)</div>
                                    <div class="bg-gray border-radius-lg p-1 text-bold text-white w-100"
                                        style="margin-top: -2px; font-weight: 700;">

                                        <vSelect style="color:white" :options="lotsValueProp" label="title"
                                            :clearable="false" multiple placeholder="Choisir un lot"
                                            v-model="new_declarant.lot" class="style-chooser-gray">
                                            <template v-slot:no-options>Aucun lot trouvé.</template>
                                        </vSelect>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div v-else-if="new_declarant.connaissance_localisation == 'connait_son_mandataire'"
                            class="bg-white text-xs text-dark text-start p-2 m-2" style="border-radius: 1rem;">

                            <div class="bg-red border-radius-lg p-2 mb-2" style="display: flex;">
                                <div class="bg-red border-radius-lg pl-2 text-white text-xs pr-3"
                                    style="padding-top: 8px;">Immeuble
                                </div>
                                <div class="bg-red border-radius-lg p-1 text-bold text-white w-100"
                                    style=" font-weight: 700;">

                                    <vSelect style="color:white" :options="immeubles" label="title" :clearable="false"
                                        placeholder="Choisir un résident" v-model="immeuble" @search="getImmeubles"
                                        :filterable="false" class="style-chooser-red">
                                        <template v-slot:no-options>Aucun immeuble trouvé.</template>
                                        <template #option="{ nom, adresse1, adresse2 }">
                                            <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">
                                                {{ nom }}
                                                <a style="font-size:8pt">{{
                    adresse1 }} {{ adresse2 }}</a>
                                            </div>
                                        </template>
                                    </vSelect>


                                </div>
                                <el-tooltip content="Filtrer les immeubles" placement="left">
                                    <button v-if="!showFilterImmeuble" class="btn btn-xs btn-white mb-0"
                                        @click="showFilterImmeuble = true"><i class="fa fa-filter"></i>
                                        <a v-if="immeubles_types != 'Tous'">{{ immeubles_types }}</a>
                                    </button>
                                    <select v-else class="form-control" v-model="immeubles_types">
                                        <option value="Tous">Tous les immeubles actifs</option>
                                        <option value="Syndic">Syndic actifs</option>
                                        <option value="Gérance">Gérance actifs</option>
                                        <option value="Inactifs">Inactifs</option>
                                    </select>
                                </el-tooltip>
                            </div>
                            <div class="bg-yellow mb-2 p-1 border-radius-lg">

                                <div style="display: flex;">
                                    <div class="bg-yellow border-radius-lg pl-2 text-dark text-xs pr-3"
                                        style="padding-top: 8px;">
                                        Mandataire</div>
                                    <div class="bg-yellow border-radius-lg p-1 text-bold text-white w-100"
                                        style="margin-top: -2px; font-weight: 700;">
                                        <vSelect style="color:white" :options="mandataires" label="title"
                                            :clearable="false" placeholder="Choisir un résident"
                                            v-model="new_declarant.mandataire"
                                            @update:modelValue="onDeclarantProprietaireChange"
                                            class="style-chooser-yellow">
                                            <template v-slot:no-options>Aucun mandataire
                                                trouvé.</template>
                                            <template #option="{ nom }">
                                                {{ nom }}
                                            </template>
                                        </vSelect>
                                    </div>

                                </div>
                            </div>
                            <div class="bg-gray p-1 border-radius-lg">
                                <div style="display: flex;">
                                    <div class="bg-gray border-radius-lg pl-2 text-white text-xs pr-3"
                                        style="padding-top: 8px;">
                                        Lot</div>
                                    <div class="bg-gray border-radius-lg p-1 text-bold text-white w-100"
                                        style="margin-top: -2px; font-weight: 700;">

                                        <vSelect style="color:white" :options="lotsValueProp" label="title"
                                            :clearable="false" multiple placeholder="Choisir un lot"
                                            v-model="new_declarant.lot" class="style-chooser-gray">
                                            <template v-slot:no-options>Aucun lot trouvé.</template>
                                            <template #option="{ title, perso_mini }">
                                                <div>
                                                    {{ title }}
                                                    <a style="font-size:8pt">PROP : {{ perso_mini.nom }}</a>
                                                </div>
                                            </template>
                                        </vSelect>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div v-else-if="new_declarant.connaissance_localisation == 'sans_contacts'"
                            class="bg-white text-xs text-dark text-start p-2 m-2" style="border-radius: 1rem;">


                            <div class="bg-red border-radius-lg p-2 mb-2" style="display: flex;">
                                <div class="bg-red border-radius-lg pl-2 text-white text-xs pr-3"
                                    style="padding-top: 8px;">Immeuble
                                </div>
                                <div class="bg-red border-radius-lg p-1 text-bold text-white w-100"
                                    style="font-weight: 700;">

                                    <vSelect style="color:white" :options="immeubles" label="title" :clearable="false"
                                        placeholder="Choisir un résident" v-model="immeuble" @search="getImmeubles"
                                        :filterable="false" class="style-chooser-red">
                                        <template v-slot:no-options>Aucun immeuble trouvé.</template>
                                        <template #option="{ nom, adresse1, adresse2 }">
                                            <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">
                                                {{ nom }}
                                                <a style="font-size:8pt">{{ adresse1 }} {{ adresse2 }}</a>
                                            </div>
                                        </template>
                                    </vSelect>


                                </div>
                                <el-tooltip content="Filtrer les immeubles" placement="left">
                                    <button v-if="!showFilterImmeuble" class="btn btn-xs btn-white mb-0"
                                        @click="showFilterImmeuble = true"><i class="fa fa-filter"></i>
                                        <a v-if="immeubles_types != 'Tous'">{{ immeubles_types }}</a>
                                    </button>
                                    <select v-else class="form-control" v-model="immeubles_types">
                                        <option value="Tous">Tous les immeubles actifs</option>
                                        <option value="Syndic">Syndic actifs</option>
                                        <option value="Gérance">Gérance actifs</option>
                                        <option value="Inactifs">Inactifs</option>
                                    </select>
                                </el-tooltip>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <input class="form-control" list="batiments" placeholder="Batiment ?"
                                        v-model="new_declarant.batiment">
                                    <datalist id="batiments">
                                        <option v-for="bat in batiments" :value="bat" :key="bat">
                                        </option>
                                    </datalist>
                                </div>
                                <div class="col-md-6">
                                    <input class="form-control" list="escaliers" placeholder="Escalier ?"
                                        v-model="new_declarant.escalier">
                                    <datalist id="escaliers">
                                        <option v-for="esc in escaliers" :value="esc" :key="esc">
                                        </option>
                                    </datalist>
                                </div>
                                <div class="col-md-6">
                                    <input class="form-control" list="etages" placeholder="Étage"
                                        v-model="new_declarant.etage">
                                    <datalist id="etages">
                                        <option v-for="et in etages" :value="et" :key="et"></option>
                                    </datalist>
                                </div>
                                <div class="col-md-6">
                                    <input class="form-control" list="portes" placeholder="Porte"
                                        v-model="new_declarant.porte">
                                    <datalist id="portes">
                                        <option v-for="porte in portes" :value="porte" :key="porte">
                                        </option>
                                    </datalist>
                                </div>
                            </div>

                        </div>
                        <div v-else-if="new_declarant.connaissance_localisation == 'en_lien_immeuble'"
                            class="bg-white text-xs text-dark text-start p-2 m-2" style="border-radius: 1rem;">

                            <div class="bg-red border-radius-lg p-2 mb-2" style="display: flex;">
                                <div class="bg-red border-radius-lg pl-2 text-white text-xs pr-3"
                                    style="padding-top: 8px;">Immeuble
                                </div>
                                <div class="bg-red border-radius-lg p-1 text-bold text-white w-100"
                                    style=" font-weight: 700;">

                                    <vSelect style="color:white" :options="immeubles" label="title" :clearable="false"
                                        placeholder="Choisir un résident" v-model="immeuble" @search="getImmeubles"
                                        :filterable="false" class="style-chooser-red">
                                        <template v-slot:no-options>Aucun immeuble trouvé.</template>
                                        <template #option="{ nom, adresse1, adresse2 }">
                                            <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">
                                                {{ nom }}
                                                <a style="font-size:8pt">{{ adresse1 }} {{ adresse2 }}</a>
                                            </div>
                                        </template>
                                    </vSelect>


                                </div>
                                <el-tooltip content="Filtrer les immeubles" placement="left">
                                    <button v-if="!showFilterImmeuble" class="btn btn-xs btn-white mb-0"
                                        @click="showFilterImmeuble = true"><i class="fa fa-filter"></i>
                                        <a v-if="immeubles_types != 'Tous'">{{ immeubles_types }}</a>
                                    </button>
                                    <select v-else class="form-control" v-model="immeubles_types">
                                        <option value="Tous">Tous les immeubles actifs</option>
                                        <option value="Syndic">Syndic actifs</option>
                                        <option value="Gérance">Gérance actifs</option>
                                        <option value="Inactifs">Inactifs</option>
                                    </select>
                                </el-tooltip>
                            </div>
                            <div class="mb-3">
                                <input class="form-control" list="qualites_tiers" placeholder="Qualité ?"
                                    v-model="new_declarant.qualite">
                                <datalist id="qualites_tiers">
                                    <option value="Voisin"></option>
                                    <option value="Prospect"></option>
                                    <option value="Gestionnaire immobilier"></option>
                                    <option value="Prestataire de services"></option>
                                    <option value="Agent de sécurité"></option>
                                    <option value="Service d'urgence"></option>
                                </datalist>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <input class="form-control" placeholder="Adresse" v-model="new_declarant.adresse"
                                        @keyup="searchAddress">

                                    <div v-if="searchResults.length > 0" class="card">
                                        <i>Suggestions :</i>
                                        <ul class="search-results">
                                            <li v-for="result in searchResults" :key="result.properties.id"
                                                @click="selectAddress(new_declarant, result)">
                                                {{ result.properties.label }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <input class="form-control" placeholder="Code Postal"
                                        v-model="new_declarant.code_postal" @keyup="getAdresse">

                                </div>
                                <div class="col-md-8">
                                    <input class="form-control" type="mail" placeholder="Ville"
                                        v-model="new_declarant.ville">

                                </div>
                            </div>

                        </div>
                        <div v-else class="bg-white text-xs text-dark text-start p-2 m-2" style="border-radius: 1rem;">

                            <div class="mb-3">
                                <input class="form-control" list="qualites_tiers" placeholder="Qualité ?"
                                    v-model="new_declarant.qualite">
                                <datalist id="qualites_tiers">
                                    <option value="Voisin"></option>
                                    <option value="Prospect"></option>
                                    <option value="Gestionnaire immobilier"></option>
                                    <option value="Prestataire de services"></option>
                                    <option value="Agent de sécurité"></option>
                                    <option value="Service d'urgence"></option>
                                </datalist>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <input class="form-control" placeholder="Adresse" v-model="new_declarant.adresse"
                                        @keyup="searchAddress">

                                    <div v-if="searchResults.length > 0" class="card">
                                        <i>Suggestions :</i>
                                        <ul class="search-results">
                                            <li v-for="result in searchResults" :key="result.properties.id"
                                                @click="selectAddress(new_declarant, result)">
                                                {{ result.properties.label }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <input class="form-control" placeholder="Code Postal"
                                        v-model="new_declarant.code_postal" @keyup="getAdresse">

                                </div>
                                <div class="col-md-8">
                                    <input class="form-control" type="mail" placeholder="Ville"
                                        v-model="new_declarant.ville">

                                </div>
                            </div>

                        </div>



                    </div>

                </div>
            </div>



            <div class="d-flex">
                <div style="flex:1"></div>
                <button class="btn btn-sm btn-success" @click="validerAjoutTiers">Valider</button>



            </div>





        </div>
    </div>

</template>
<style>
@import "vue-select/dist/vue-select.css";
</style>
<style>
.style-chooser-gray .vs__search::placeholder,
.style-chooser-gray .vs__dropdown-toggle,
.style-chooser-gray .vs__dropdown-menu {
    font-size: 10pt;
    background: #6c757d;
    border: none;
    color: #ffffff;
}

.style-chooser-gray .vs__clear,
.style-chooser-gray .vs__open-indicator {
    fill: whitesmoke;


}

.style-chooser-gray .vs__clear,
.style-chooser-gray .vs__selected {
    fill: whitesmoke;
    color: white;
    background-color: var(--el-text-color-secondary);

}

.bg-light-purple {
    background-color: #ae99d5;
}

.style-chooser-yellow .vs__search::placeholder,
.style-chooser-yellow .vs__dropdown-toggle,
.style-chooser-yellow .vs__dropdown-menu {
    background: #FBD38D !important;
    border: none;
    color: #343434;
}

.style-chooser-yellow .vs__clear,
.style-chooser-yellow .vs__open-indicator {
    fill: rgb(45, 45, 45);


}

.style-chooser-yellow .vs__clear,
.style-chooser-yellow .vs__selected {
    fill: rgb(42, 41, 41);
    color: rgb(1, 1, 1);
    background: #FBD38D !important;

}




.style-chooser-orange .vs__search::placeholder,
.style-chooser-orange .vs__dropdown-toggle,
.style-chooser-orange .vs__dropdown-menu {
    background: rgb(253, 126, 20) !important;
    border: none;
    color: #ffffff;
}

.style-chooser-orange .vs__clear,
.style-chooser-orange .vs__open-indicator {
    fill: rgb(204, 203, 203);


}

.style-chooser-orange .vs__clear,
.style-chooser-orange .vs__selected {
    fill: rgb(42, 41, 41);
    color: rgb(255, 255, 255);
    background-color: rgb(204, 99, 14);

}


.style-chooser-purple .vs__search::placeholder,
.style-chooser-purple .vs__dropdown-toggle,
.style-chooser-purple .vs__dropdown-menu {
    background: #6f42c1 !important;
    border: none;
    color: #ffffff;
    z-index: 5;
}

.style-chooser-purple .vs__clear,
.style-chooser-purple .vs__open-indicator {
    fill: rgb(204, 203, 203);


}

.style-chooser-purple .vs__clear,
.style-chooser-purple .vs__selected {
    fill: rgb(42, 41, 41);
    color: rgb(255, 255, 255);
    background-color: #11cdef;

}


.style-chooser-light-purple .vs__search::placeholder,
.style-chooser-light-purple .vs__dropdown-toggle,
.style-chooser-light-purple .vs__dropdown-menu {
    background: #ae99d5 !important;
    border: none;
    color: #ffffff;
}

.style-chooser-light-purple .vs__clear,
.style-chooser-light-purple .vs__open-indicator {
    fill: rgb(204, 203, 203);


}

.style-chooser-light-purple .vs__clear,
.style-chooser-light-purple .vs__selected {
    fill: rgb(42, 41, 41);
    color: rgb(255, 255, 255);
    background-color: #ae99d5;

}


.style-chooser-light-blue .vs__search::placeholder,
.style-chooser-light-blue .vs__dropdown-toggle,
.style-chooser-light-blue .vs__dropdown-menu {
    background: #11cdef !important;
    border: none;
    color: rgb(0, 0, 0);
}

.style-chooser-light-blue .vs__clear,
.style-chooser-light-blue .vs__open-indicator {
    fill: rgb(204, 203, 203);


}

.style-chooser-light-blue .vs__clear,
.style-chooser-light-blue .vs__selected {
    fill: rgb(0, 0, 0);
    color: rgb(28, 28, 28);
    background-color: #11cdef;

}


.style-chooser-dark-turquoise .vs__search::placeholder,
.style-chooser-dark-turquoise .vs__dropdown-toggle,
.style-chooser-dark-turquoise .vs__dropdown-menu {
    background: #1a9593 !important;
    border: none;
    color: rgb(255, 255, 255);
}

.style-chooser-dark-turquoise .vs__clear,
.style-chooser-dark-turquoise .vs__open-indicator {
    fill: #1a9593
}

.style-chooser-dark-turquoise .vs__clear,
.style-chooser-dark-turquoise .vs__selected {
    fill: #1a9593;
    color: rgb(255, 255, 255);
    background-color: #1a9593;

}

.bg-dark-turquoise {
    background-color: #1a9593;
}


.style-chooser-red .vs__search::placeholder,
.style-chooser-red .vs__dropdown-toggle,
.style-chooser-red .vs__dropdown-menu {
    background: #F56565 !important;
    border: none;
    color: rgb(255, 255, 255);
}

.style-chooser-red .vs__clear,
.style-chooser-red .vs__open-indicator {
    fill: #F56565
}

.style-chooser-red .vs__clear,
.style-chooser-red .vs__selected {
    fill: #F56565;
    color: rgb(255, 255, 255);
    background-color: #F56565;

}



.style-chooser-blue .vs__search::placeholder,
.style-chooser-blue .vs__dropdown-toggle,
.style-chooser-blue .vs__dropdown-menu {
    background: #5e72e4 !important;
    border: none;
    color: rgb(255, 255, 255);
}

.style-chooser-blue .vs__clear,
.style-chooser-blue .vs__open-indicator {
    fill: rgb(204, 203, 203);


}

.style-chooser-blue .vs__clear,
.style-chooser-blue .vs__selected {
    fill: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    background-color: #5e72e4;

}

.fade-enter-active .card,
.fade-leave-active .card {
    transition: all 0.25s ease-out;
}




.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.25s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}


.fade-enter-from .card {
    opacity: 0;
    transform: translateY(30px);
}

.fade-leave-to .card {
    opacity: 0;
    transform: translateY(-30px);
}

.ellipsis {
    position: relative;
}

.ellipsis:before {
    content: '&nbsp;';
    visibility: hidden;
}

.ellipsis span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}


.search-results {
    left: 0;
    right: 0;
    background: white;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    max-height: 200px;
    overflow-y: auto;
}

.search-results li {
    padding: 8px 12px;
    cursor: pointer;
}

.search-results li:hover {
    background-color: #f0f0f0;
}

.vs__search {
    font-size: 10pt !important;
}

.vs__selected-options {
    font-size: 10pt !important;
    height: 25px;
    overflow: hidden;
}

.form-check:not(.form-switch) .form-check-input[type=checkbox]:after {
    content: unset !important;
}
</style>
