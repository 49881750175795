
<script setup >
import { ref, watch } from 'vue'
import webexImg from '@/assets/img/logos/webex.webp';
//import AudioMotionAnalyzer from 'audiomotion-analyzer';
import {  defineEmits,onBeforeUnmount } from 'vue'
import phone_sound from '@/assets/sounds/phone_ring.wav'

const line_cst = ref(null)
const calling_client_cst = ref(null)
const registrationStatusElm = ref(null)
const correlationId = ref(null)
const broadworksCorrelationInfo = ref(null)
const callDetailsElm = ref(null)
const call = ref(null)
const is_ringing = ref(false)
//const callListener = new EventTarget()
const localAudioStream = ref(null)
const callinfo = ref(null)
const destination = ref('')
const player = ref(null)
const isPlaying = ref(false)
let audioContext = null
let analyser = null
const ring_tone = ref()
let ring_interval = null


// emit call info change

const emit = defineEmits(['callinfo'])

watch(() => callinfo.value, (newVal) => {
    // send to activity bar

    let l = localStorage.getItem("activity_bar")
    let lf = JSON.parse(l)
    if(lf){
        lf.push({titre: newVal.num, description: newVal.status, event_type:'call'})
    } else {
        lf = [{titre: newVal.num, description: newVal.status, event_type:'call'}]
    }
    localStorage.setItem("activity_bar", JSON.stringify(lf))

   // window.opener.postMessage("activity_bar-localstorage-changed", process.env.VUE_APP_BASE_URL + "/s/calls");
    emit('callinfo', newVal)
})

watch(is_ringing, (newVal) => {
    if(newVal){
        console.log("ringing-here")
        ring_tone.value.play()
        ring_interval = setInterval(() => {
            console.log("ringing")
            ring_tone.value.play()
        }, 2000)
    } else {
        clearInterval(ring_interval)
        console.log("stop ringing")
        ring_tone.value.pause()
    }
})
/*
function getCookie(cookie_name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${cookie_name}=`);
      if (parts.length === 2) {
        return parts.pop().split(";").shift();
      }
}*/

async function getMediaStreams() {
  localAudioStream.value  = await window.Calling.createMicrophoneStream({audio: true});

}






const callNotifyEvent = new CustomEvent('line:incoming_call', {
  detail: {
    callObject: call.value,
  },
});

function createCall(){
    console.log("create call")
    call.value = line_cst.value.makeCall({
      type: 'uri',
      address: destination.value,
    });

    callinfo.value = call.value.getCallerInfo()
    callinfo.value.num = destination.value
    call.value.on('established', (correlationId) => {
      console.log(` Call is Established: ${correlationId}`);
      callinfo.value.status = "Etabli"
      is_ringing.value = false
    });
    call.value.on('disconnect', () => {
      console.log(` Call is Disconnected: ${correlationId.value}`);
        callinfo.value.status = "Terminé"
        callinfo.value.num = null
        is_ringing.value = false
    });

  call.value.on('caller_id', (CallerIdEmitter) => {
    callDetailsElm.value = `Name: ${CallerIdEmitter.callerId.name}, Number: ${CallerIdEmitter.callerId.num}, Avatar: ${CallerIdEmitter.callerId.avatarSrc} , UserId: ${CallerIdEmitter.callerId.id}`;
    console.log(
      `callerId : Name: ${CallerIdEmitter.callerId.name}, Number: ${CallerIdEmitter.callerId.num}, Avatar: ${CallerIdEmitter.callerId.avatarSrc}, UserId: ${CallerIdEmitter.callerId.id}`
    );
  });

  call.value.on('progress', (correlationId) => {
    callDetailsElm.value = `${correlationId}: Call Progress`;
  });
  call.value.on('connect', (correlationId) => {
    callDetailsElm.value = `${correlationId}: Call Connect`;
  });
  call.value.on('established', (correlationId) => {
    callDetailsElm.value = `${correlationId}: Call Established`;
    is_ringing.value = false
  });
  call.value.on('disconnect', (correlationId) => {
    callDetailsElm.value = `${correlationId}: Call Disconnected`;
    is_ringing.value = false

  });

  call.value.on('remote_media', (track) => {
    document.getElementById('remote-audio').srcObject = new MediaStream([track]);
  });

  localAudioStream.value.on('system-mute-state-change', (systemMuted) => {
    call.value.mute(localAudioStream.value, 'system_mute',systemMuted);
  });
/*
  localAudioStream.value.on('user-mute-state-change', (userMuted) => {

  });*/

  call.value.dial(localAudioStream.value);

}




function answer() {


  if (call.value) {
    call.value.on('established', (correlationId) => {
      console.log(` Call is Established: ${correlationId}`);
      callinfo.value.status = "Etabli"
      is_ringing.value = false

    });
    call.value.on('disconnect', () => {
      console.log(` Call is Disconnected: ${correlationId.value}`);
      callinfo.value.status = "Terminé"
      is_ringing.value = false
    });


    call.value.on('remote_media', (track) => {
      document.getElementById('remote-audio').srcObject = new MediaStream([track]);
      //getass(track)
    });

    localAudioStream.value.on('system-mute-state-change', (muted) => {
      console.log('system mute received', muted);
    });

    localAudioStream.value.on('user-mute-state-change', (muted) => {
      console.log('user mute received', muted);
    });

    call.value.answer(localAudioStream.value);
  }

}

function endCall() {
    call.value.end();
    is_ringing.value = false
    callinfo.value = {
        num: null,
        status: null
    }
}

const setupAudioAnalysis = () => {
      audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaElementSource(player.value);
      analyser = audioContext.createAnalyser();
      source.connect(analyser);
      analyser.connect(audioContext.destination);

      analyser.fftSize = 256;
      const dataArray = new Uint8Array(analyser.frequencyBinCount);

      const checkAudio = () => {
        analyser.getByteFrequencyData(dataArray);
        //console.log(dataArray);
        isPlaying.value = dataArray.some(value => value > 20); // Détecte le son actif

        requestAnimationFrame(checkAudio);
      };
      checkAudio();
    };

    function killWebex() {
        line_cst.value.deregister();


    }

    // when the component is destroyed, kill the webex
    onBeforeUnmount(() => {
        killWebex();

    });



async function webexTest() {
      // Create the Webex configuration

      const webexConfig = {
        config: {
          logger: {
            level: "debug", // Set the desired log level
          },
          meetings: {
            reconnection: {
              enabled: true,
            },
            enableRtx: true,
          },
          encryption: {
            kmsInitialTimeout: 8000,
            kmsMaxTimeout: 40000,
            batcherMaxCalls: 30,
            caroots: null,
          },
          dss: {},
        },
        credentials: {
          access_token: localStorage.getItem("webex_access_token"),
        },
      };

      // Create the calling configuration
      const callingConfig = {
        clientConfig: {
          calling: true,
          contact: true,
          callHistory: true,
          callSettings: true,
          voicemail: true,
        },
        callingClientConfig: {
          logger: {
            level: "info",
          },
        },
        logger: {
          level: "info",
        },
      };

      console.log("etape 0");


      // Create the Calling object
      const calling = await window.Calling.init({ webexConfig, callingConfig });
      console.log("etape 1");
      //let callingClient;
      calling.on("ready", () => {
        // remove previous registered listeners

        calling.register()
        .then(() => {

        calling_client_cst.value = calling.callingClient;

        console.log("etape 2, calling client", calling_client_cst.value);



        /*if (window.callHistory === undefined) {
            this.callHistory = calling.callHistoryClient.getCallHistoryData();
        }*/


        let line = Object.values(calling.callingClient.getLines())[0];
        console.log(calling.callingClient)
        console.log(line)
        getMediaStreams()
        try{

        line.register();
        } catch(e){
            console.error(e)
        }
        line.on('registered', (deviceInfo) => {
            console.log("registered success");
            registrationStatusElm.value =
            calling.webex.internal.device.url !== ''
                ? `Registered, deviceId: ${deviceInfo.mobiusDeviceId}`
                : 'Not Registered';




        });
        line_cst.value = line
        setupAudioAnalysis();

        line.on('error', (error) => {
            // Try to re-connect and/or show error to end user
            console.error(error)
        })

        line.on('line:incoming_call', (callObj) => {
            call.value = callObj;
            callinfo.value = callObj.getCallerInfo()

            callObj.on('disconnect', () => {
            callDetailsElm.value = `${correlationId.value}: Call Disconnected`;
            is_ringing.value = false
            });

            console.log('Incoming Call');
            // start ring tone
            is_ringing.value = true


            console.log(callObj);
            console.log(callinfo.value);

            callNotifyEvent.detail.callObject = callObj;
            correlationId.value = callObj.getCorrelationId();
            console.log(`APP.JS::  Incoming Call with correlationId: ${correlationId.value}`);
            broadworksCorrelationInfo.value = callObj.getBroadworksCorrelationInfo();
            if (broadworksCorrelationInfo.value !== undefined) {
            console.log(
                `APP.JS::  Incoming Call with broadworksCorrelationInfo: ${broadworksCorrelationInfo.value}`
            );
            }
            //callListener.dispatchEvent(callNotifyEvent);
            console.log('Waiting for User to answer the call...');
        });

        line.on('outgoing_call', (callObj) => {
            call.value = callObj;
            callinfo.value = callObj.getCallerInfo()

            callObj.on('disconnect', () => {
            callDetailsElm.value = `${correlationId.value}: Call Disconnected`;
            is_ringing.value = false
            });

            console.log('Outgoing Call');

            console.log(callObj);
            console.log(callinfo.value);

            callNotifyEvent.detail.callObject = callObj;
            correlationId.value = callObj.getCorrelationId();
            console.log(`APP.JS::  Outgoing Call with correlationId: ${correlationId.value}`);
            broadworksCorrelationInfo.value = callObj.getBroadworksCorrelationInfo();
            if (broadworksCorrelationInfo.value !== undefined) {
            console.log(
                `APP.JS::  Outgoing Call with broadworksCorrelationInfo: ${broadworksCorrelationInfo.value}`
            );
            }
            //callListener.dispatchEvent(callNotifyEvent);
            console.log('Waiting for User to answer the call...');
        });
        /*line.on('incoming_call', (call) => {

            call.on('caller_id', (CallerIdEmitter) => {
                calling_num_cst.value = CallerIdEmitter.callerId

            //callDetailsElm.innerText = `Name: ${CallerIdEmitter.callerId.name}, Number: ${CallerIdEmitter.callerId.num}, Avatar: ${CallerIdEmitter.callerId.avatarSrc}, UserId: ${CallerIdEmitter.callerId.id}`;
            console.log(
                `callerId : Name: ${CallerIdEmitter.callerId.name}, Number: ${CallerIdEmitter.callerId.name}, Avatar: ${CallerIdEmitter.callerId.avatarSrc}, UserId: ${CallerIdEmitter.callerId.id}`
            );
            });

          console.log("INCOMING CALL INSIDE")
            console.log(call)
            //this.incomingCalls = {id:call.getCallId()}
            //  Call object received. Store this object and listen for events on the call
        });*/




        });
      });


    }
</script>
<template>
    <div>
            <div class="card p-2 m-2 bg-dark text-white " style="">
                <div v-if="isPlaying" class="sound-wave"></div>
                <img :src="webexImg" alt="logo" style="height: 20px;width: 80px;margin-right:10px">

                <div v-if="registrationStatusElm" style="flex-direction: row;display: flex;width: 100%;">
                <!--<canvas id="audio-motion-container" style=""></canvas>-->
                    <div style="width: 100%;">Téléphone connecté

                            <div v-if="callinfo">
                                <div  v-if="callinfo.num" >{{callinfo.num}}</div>
                                <div  v-if="callinfo.status" >{{callinfo.status}}</div>
                            </div>

                            <div v-if="callinfo && callinfo.num">
                                <div v-if="callinfo.status != 'Etabli' && callinfo.status != 'Terminé'">
                                    <button @click="answer" class="btn btn-xs btn-success mb-0"><i class="fa fa-phone"></i> Répondre</button>
                                </div>
                                <div v-if="callinfo.status == 'Etabli'">
                                    <button @click="endCall" class="btn btn-xs btn-danger mb-0"><i class="fa fa-phone" style="transform: rotate(135deg);"></i>&nbsp;  Terminer l'appel</button>
                                </div>
                                <div v-else-if="callinfo.status != 'Terminé'">
                                    <button @click="endCall" class="btn btn-xs btn-danger mb-0"><i class="fa fa-phone" style="transform: rotate(135deg);"></i> &nbsp; Ne pas répondre</button>
                                </div>
                            </div>
                    </div>

                    <div >
                        <input type="text" class="form-control form-control-sm" style="" v-model="destination" placeholder="Numéro à appeler">
                        <div class="pave_numero">
                            <div style="flex-direction: row;display: flex;">

                                <div class="btn btn-white btn-xs btn-round text-center pave_numero_btn w-100 m-1" @click="destination +='1'">1</div>
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='2'">2</div>
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='3'">3</div>
                            </div>
                            <div style="flex-direction: row;display: flex;">
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='4'">4</div>
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='5'">5</div>
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='6'">6</div>
                            </div>
                            <div style="flex-direction: row;display: flex;">
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='7'">7</div>
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='8'">8</div>
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='9'">9</div>
                            </div>
                            <div style="flex-direction: row;display: flex;">
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='*'">*</div>
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='0'">0</div>
                                <div class="btn btn-white btn-xs btn-round text-center  pave_numero_btn w-100 m-1" @click="destination +='#'">#</div>

                            </div>

                        </div>

                        <button @click="createCall" class="btn btn-xs btn-success mb-0"><i class="fa fa-phone"></i></button>
                    </div>


                </div>
                <div v-else>
                    <button @click="webexTest" class="btn btn-xs btn-success mb-0">Activer l'expérience Webex</button>

                </div>
            </div>

        <audio id="remote-audio" autoplay ref="player"></audio>
        <audio id="ring-tone" :src="phone_sound" ref="ring_tone"></audio>




    </div>
</template>
<style>
.sound-wave {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

</style>
