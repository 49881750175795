import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListEvents(params){
  const response =  await axios.get(`${API_URL}/events`, {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getEvent(EventId){
  const response =  await axios.get(`${API_URL}/tickets/${EventId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}


async function getListEventsR(params){
  const response =  await axios.get(`${API_URL}/r/events`, {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getListEventsF(params){
  const response =  await axios.get(`${API_URL}/f/events`, {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getEventF(EventId){
  const response =  await axios.get(`${API_URL}/f/events/${EventId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function editEvent(Event){

  const payload = jsona.serialize({
    stuff: Event,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/events/${Event.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createTicket(item){

    const formData = new FormData();

    Object.entries(item).forEach(([key, value]) => {
      if(key !== "files" && key !== "image" && key !== "fichiers"){

        formData.append(key, JSON.stringify(value));
      }

    });
    if(item.files){

        item.files.forEach((file, i) => {
          formData.append("files[]", file, file.name || "filename" + i + ".jpg");
      });

    }
    if(item.image){

        item.image.forEach((file, i) => {
          formData.append("files[]", file, file.name || "filename" + i + ".jpg");
      });

    }
    if(item.fichiers){
        console.log(item.fichiers)
        item.fichiers.forEach((file, i) => {
          formData.append("files[]", file, file.name || "filename" + i + ".jpg");
      });

    }

    const options = {
      headers: {
      ...authHeader(),
        Accept: "application/vnd.api+json",
        "Content-Type": "multipart/form-data"
      }
    };
    const response =  await axios.post(`${API_URL}/tickets`, formData, options);
    return response.data;
  }

  async function updateTicket(item){

    const formData = new FormData();

    Object.entries(item).forEach(([key, value]) => {
      if(key !== "files" && key !== "image" && key !== "fichiers"){

        formData.append(key, JSON.stringify(value));
      }

    });
    if(item.files){

        item.files.forEach((file, i) => {
          formData.append("files[]", file, file.name || "filename" + i + ".jpg");
      });

    }
    if(item.image){

        item.image.forEach((file, i) => {
          formData.append("files[]", file, file.name || "filename" + i + ".jpg");
      });

    }
    if(item.fichiers){
        console.log(item.fichiers)
        item.fichiers.forEach((file, i) => {
          formData.append("files[]", file, file.name || "filename" + i + ".jpg");
      });

    }

    const options = {
      headers: {
      ...authHeader(),
        Accept: "application/vnd.api+json",
        "Content-Type": "multipart/form-data"
      }
    };
    const response =  await axios.post(`${API_URL}/tickets/${item.id}?_method=PATCH`, formData, options);
    return response.data;
  }
  async function updateSubTicket(item){

    const formData = new FormData();

    Object.entries(item).forEach(([key, value]) => {
      if(key !== "files" && key !== "image" && key !== "fichiers"){

        formData.append(key, JSON.stringify(value));
      }

    });
    if(item.files){

        item.files.forEach((file, i) => {
          formData.append("files[]", file, file.name || "filename" + i + ".jpg");
      });

    }
    if(item.image){

        item.image.forEach((file, i) => {
          formData.append("files[]", file, file.name || "filename" + i + ".jpg");
      });

    }
    if(item.fichiers){
        console.log(item.fichiers)
        item.fichiers.forEach((file, i) => {
          formData.append("files[]", file, file.name || "filename" + i + ".jpg");
      });

    }

    const options = {
      headers: {
      ...authHeader(),
        Accept: "application/vnd.api+json",
        "Content-Type": "multipart/form-data"
      }
    };
    const response =  await axios.post(`${API_URL}/tickets/${item.id}/events/${item.subid}?_method=PATCH`, formData, options);
    return response.data;
  }

async function createEvent(item){

  const formData = new FormData();

  Object.entries(item).forEach(([key, value]) => {
    if(key !== "files" && key !== "image" && key !== "fichiers"){

      formData.append(key, JSON.stringify(value));
    }

  });
  if(item.files){

      item.files.forEach((file, i) => {
        formData.append("files[]", file, file.name || "filename" + i + ".jpg");
    });

  }
  if(item.image){

      item.image.forEach((file, i) => {
        formData.append("files[]", file, file.name || "filename" + i + ".jpg");
    });

  }
  if(item.fichiers){
      console.log(item.fichiers)
      item.fichiers.forEach((file, i) => {
        formData.append("files[]", file, file.name || "filename" + i + ".jpg");
    });

  }

  const options = {
    headers: {
    ...authHeader(),
      Accept: "application/vnd.api+json",
      "Content-Type": "multipart/form-data"
    }
  };
  const response =  await axios.post(`${API_URL}/events?include=category,tags`, formData, options);
  return response.data;
}

async function ia_task(item) {

  console.log(item)
  return await axios.post(`${API_URL}/events/ai`,item, {headers: authHeader()}).then(response => {

    return  response.data
  });
}

async function deleteEvent(EventId){
  await axios.delete(`${API_URL}/events/${EventId}`, {headers: authHeader()});
}

async function uploadImageEvent(Event) {
    const payload = new FormData();
    payload.append("attachment", Event.image);

    const response = await axios.post(`${API_URL}/uploads/events/${Event.id}/image`, payload, { headers: authHeader() });
    return response.data.url;

  }


async function getFile(params){
  console.log(params)
  const response =  await axios.get(`${API_URL}/events/${params.event_id}/files/${params.id}`, {headers: authHeader()});
  return response.data;
}


export default {
  getListEvents,getListEventsF,getListEventsR,createTicket,
    getEvent,getEventF,getFile,
    editEvent,
    createEvent,
    updateTicket,updateSubTicket,
    deleteEvent,
    uploadImageEvent,
    ia_task
};
