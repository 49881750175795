<script setup>
import { ref, defineProps, watch, onMounted, computed } from 'vue';
import store from '@/store'
import axios from 'axios';


import vSelect from "vue-select";
import Modal from '../../../components/Modal.vue';
import AjoutFournisseur from '../fournisseurs/AjoutFournisseur.vue';
//import ModalNoBorder from '../../../components/ModalNoBorder.vue';
//import { useNotification } from "@kyvg/vue3-notification";
import Editor from '@tinymce/tinymce-vue';
import PDFView from '../../../components/PDFView.vue';
import Swal from "sweetalert2";
import DropZone from '@/blocks/Controls/DropZone.vue';


// use mixin showSwal



//const { notify }  = useNotification()
const page = ref(1)
const mode = ref('intervention');
const submode = ref('demande_devis')
const lot = ref('')
const contrats = ref([])
const contrat = ref('')
const entreprise = ref('')
const entreprises = ref([])
const metiers = ref([])
const metier = ref('')
const clefs = ref([])
const clef = ref('')
const moyen_libre_os = ref('')
const mandataires = ref([])
const immeuble = ref({
    nom: '',
    id: 0

})
const etages = ref([])
const batiments = ref([])
const escaliers = ref([])
const portes = ref([])

const lotsValueProp = ref([])
const new_declarant = ref({
    nom: '',
    prenom: '',
    civilite: 'Civilité',
    mail: '',
    phone: '',
    adresse: '',
    code_postal: '',
    ville: '',
    pays: '',
    imm_aff: [],
    lot: '',
    mandataire: '',
    connaissance_localisation: 'connait_son_proprietaire'
})

const all_persos_loading = ref(false)
const showModalDeclarant = ref(false)
const search_declarant = ref('')
const showModalCodes = ref(false)
const gardienVal = ref('')
const gardiens = ref([])
const metier_block = ref(true)
const etablissements = ref([])
const etablissement = ref('')
const showModalContrat = ref(false)
const showModalAddEntreprise = ref(false)
const showAjoutPerso = ref(false)
const mode_moyen_acces = ref('clef_codes')
const acces_resident = ref('')
const mode_action_sur_site = ref('parties_privatives')

const mode_contrat = ref('avec')
const lotsValue = ref([])
const resident = ref('')
const cops = ref([])
const all_persos = ref([])
const onLotChange = (value) => {
    console.log(value)
    //  getImmeubleCoproprietaires()
}
const fichiers = ref([])

// get v-model from parent
const props = defineProps(['mail', 'immeubleprop'])

onMounted(() => {
    const imm = props.immeubleprop;
    immeuble.value = JSON.parse(JSON.stringify(imm))
    console.log("immeuble.value", immeuble.value)
    getImmeubleCoproprietaires();
    getMetiers()
    getImmeubleFull();

    getModelCollab(submode.value)
})


const getPotentialDeclarant = () => {
    console.log('MAILSDECL', props.mail)
    try {
        if (props.mail.from_perso[2] === null) {
            return;
        }
    } catch (e) {
        return;
    }
    const id_to_find = props.mail.from_perso[2]
    console.log('id_to_find', id_to_find)
    console.log('r_try', cops.value)
    const r_found = cops.value.find(el => el.perso_mini.id === id_to_find);
    resident.value = r_found
    if (r_found) {
        onResidentChange(r_found)
    }
}



const getImmeubleContrats = async () => {
    await store.dispatch('immeuble/getImmeubleContrats', immeuble.value.id)
    const imm = store.getters['immeuble/oneImmeubleContrats']
    console.log(imm)
    contrats.value = [];
    // TO DO : to put in syndic config
    const possible_gardien = ["GAR", "PER", "EMP"]

    gardienVal.value = ''
    gardiens.value = []
    mode_moyen_acces.value = 'clef_codes'


    imm.contrats.forEach(element => {
        if (contrats.value.find(el => el.ext_fournisseur.nom == element.ext_fournisseur.nom) === undefined) {
            if (element.date_fin_contrat < new Date()) {
                element.title = element.ext_fournisseur.nom;

                element.naf = element.ext_fournisseur?.dl_fournisseur?.naf;
                element.siret = element.ext_fournisseur?.perso?.siret;
                contrats.value.push(element);

                if (possible_gardien.includes(element.ext_fournisseur_type)) {
                    mode_moyen_acces.value = 'gardien'
                    gardiens.value.push(element)
                    gardienVal.value = element

                }



            }

        }


    })




    contrats.value = contrats.value.sort((a, b) => a.title.localeCompare(b.title));


}


const getImmeubleFull = async () => {
    await store.dispatch('immeuble/getImmeuble', immeuble.value.id)
    const imm = store.getters['immeuble/oneImmeuble']
    immeuble.value = imm;
    console.log(imm)
    //immeuble.value = imm;

    clefs.value = [];
    imm.attributes.forEach(element => {
        if (element.attr_type === 'codes') {
            // json decode value
            element.value = JSON.parse(element.value);
            element.value.forEach(el => {
                el.title = el.code + ' - ' + el.localisation;
                clefs.value.push(el);
            });
        }
    });
    mandataires.value = [];

    imm.lots.forEach(element => {
        if (element.perso_mini && element.perso_mini.id) {
            if (element.perso_mini.mandataire && element.perso_mini.mandataire.id) {

                if (mandataires.value.findIndex(el => el.id === element.perso_mini.mandataire.id) === -1) {
                    element.perso_mini.mandataire.lots = JSON.parse(JSON.stringify([element]));
                    element.perso_mini.mandataire.title = element.perso_mini.mandataire.nom;
                    mandataires.value.push(element.perso_mini.mandataire);
                } else {
                    mandataires.value[mandataires.value.findIndex(el => el.id === element.perso_mini.mandataire.id)].lots.push(element);
                }
            }
            //mandataires.value.push(element);
        }

        // batiments
        if (batiments.value.findIndex(el => el === element.bat) === -1) {
            batiments.value.push(element.bat);
        }
        // etages
        if (etages.value.findIndex(el => el === element.etage) === -1) {
            etages.value.push(element.etage);
        }
        // escaliers
        if (escaliers.value.findIndex(el => el === element.escalier) === -1) {
            escaliers.value.push(element.escalier);
        }
        // portes
        if (portes.value.findIndex(el => el === element.porte) === -1) {
            portes.value.push(element.porte);
        }


    });



}

const getAllPerso = async () => {
    console.log(search_declarant.value)
    if (search_declarant.value.match(/^[0-9 ]+$/) != null) {
        search_declarant.value = search_declarant.value.replace(/(\d)(\d)(?=\d)/g, '$1$2 ');

    }


    if (search_declarant.value.length < 4) {
        return;
    }
    all_persos_loading.value = true;
    await store.dispatch('perso/persosList', {
        search: search_declarant.value,
        page: 1,
        perpage: 20
    })
    var persostmp = store.getters['perso/persosList']?.data

    persostmp = persostmp.map(personn => {
        const imm_aff = personn.imm_links.reduce((acc, imm) => {
            if (imm.immeuble_mini && !acc.includes(imm.immeuble_mini)) {
                if (imm.actif > 0 || (imm.bail_actif && imm.bail_actif.date_fin_bail_effective === null)) {
                    if (acc.findIndex((i) => i.id === imm.immeuble_mini.id) === -1) {
                        acc.push(imm.immeuble_mini);
                    }

                }
            }
            return acc;
        }, []);
        return { ...personn, imm_aff };
    });
    console.log(persostmp)
    all_persos_loading.value = false;
    all_persos.value = persostmp;
}
watch(() => search_declarant.value, () => {
    getAllPerso()
})

const getImmeubleCoproprietaires = async () => {
    await store.dispatch('immeuble/getImmeubleResidents', immeuble.value.id)
    const imm = store.getters['immeuble/oneImmeubleResidents']
    console.log(imm)
    cops.value = [];

    imm.lots.forEach(element => {
        let s = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === element.EXTAPI_PERSO_ID);
        if (s === -1) {
            let elnew = { bail: null, EXTAPI_IMM_ID: element.EXTAPI_IMM_ID, perso_mini: element.perso_mini, lots: [element], EXTAPI_PERSO_ID: element.EXTAPI_PERSO_ID,

                nom:element.perso_mini.nom,prenom:element.perso_mini.prenom,
                adresse:element.perso_mini.adresse1+' '+element.perso_mini.adresse2+' '+element.perso_mini.cp+' '+element.perso_mini.ville,
                phone:'',mail:'',title:'',locations:[]

             };
             if(element.perso_mini.phones && element.perso_mini.phones[0]){
                elnew.phone = element.perso_mini.phones[0].phone;
             }
                if(element.perso_mini.mails && element.perso_mini.mails[0]){
                    elnew.mail = element.perso_mini.mails[0].mail;
                }
            elnew.title = element.perso_mini.nom + ' ' + element.perso_mini.prenom;
            elnew.locations = [];
            if (element.locataires.length > 0) {
                element.locataires.forEach(locataire => {
                    if (locataire.bail_actif) {
                        locataire.bail_actif.ext_locataire.forEach(loca => {
                            if (elnew.locations.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID) === -1) {

                                elnew.locations.push(loca)
                            }
                        });
                    }
                });
            }

            cops.value.push(elnew);
        } else {

            if (element.locataires.length > 0) {
                element.locataires.forEach(locataire => {
                    if (locataire.bail_actif) {
                        locataire.bail_actif.ext_locataire.forEach(loca => {
                            if (cops.value[s].locations.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID) === -1) {
                                cops.value[s].locations.push(loca)
                            }
                        });
                    }
                });
            }
            cops.value[s].lots.push(element);
        }

        element.locataires.forEach(locataire => {
            if (locataire.bail_actif) {
                locataire.bail_actif.ext_locataire.forEach(loca => {
                    loca.title = loca.perso_mini.nom + ' ' + loca.perso_mini.prenom;
                    let s2 = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID);
                    if (s2 === -1) {
                        let lt = { ...loca, bail: { lots: [{ ...element, perso_mini: element.perso_mini }] }, lots: [{ ...element, perso_mini: element.perso_mini }], locataire: true };
                        cops.value.push(lt);
                    }
                });


            }
        }
        )
    });
    /*
        imm.coplots_actifs.forEach(element => {
            let s = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === element.EXTAPI_PERSO_ID);

            if (s === -1) {
                if (element.date_vente === null) {
                    if (element.lots === undefined) {
                        element.lots = [];
                    }
                    element.title = element.perso_mini.nom + ' ' + element.perso_mini.prenom;
                    cops.value.push(element);
                }
            } else {
                if (element.date_vente === null) {
                    cops.value[s].lots.push(element.lots[0]);
                }
            }
            element.lots.forEach(lot => {
                lot.baux_actifs.forEach(tbail => {
                    tbail.ext_locataire.forEach(loca => {
                        loca.title = loca.perso_mini.nom + ' ' + loca.perso_mini.prenom;
                        let s2 = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID);
                        if (s2 === -1) {
                            let lt = { ...loca, lots: [{ ...lot, perso_mini: element.perso_mini }], locataire: true };
                            cops.value.push(lt);
                        }
                    });
                });
            });
        })
    */
    cops.value = cops.value.sort((a, b) => a.title.localeCompare(b.title));

    ;
    getPotentialDeclarant();
    getImmeubleContrats();
}

watch(props.immeubleprop, (value) => {
    console.log(value)
    const imm = JSON.parse(JSON.stringify(value));
    immeuble.value = imm;
    console.log('immeuble.value', immeuble.value)
    getImmeubleFull();
    getImmeubleContrats();
    getImmeubleCoproprietaires()
})

const isSuivantPossible = computed(() => {
    if(page.value === 3){
        return true;
    }
    if(page.value ==2){
        if(titre.value.length>0 && message.value.length>0){
            return true;
        } else {

            return false;
        }
    }

    if (mode.value === 'intervention' && submode.value === 'demande_devis'
        && mode_contrat.value === 'sans' && entreprise.value && metier.value && etablissement.value

    ) {
        return true;
    }
    if (mode.value === 'intervention' && submode.value === 'demande_intervention' && mode_contrat.value === 'sans' && entreprise.value && metier.value && etablissement.value) {
        return true;
    }
    return false;
})

// watch change on declarant
const onResidentChange = (value, tiers = 0) => {
    // set
    if (tiers) {
        value.title = value.nom + ' ' + value.prenom;
        value.tiers = tiers;
        showModalDeclarant.value = false
        resident.value = value;
    } else {


        console.log(value)
        lotsValue.value = [];
        lot.value = '';
        value.lots.forEach(element => {
            lotsValue.value.push({ title: element.EXTAPI_LOT_ID + ' - ' + element.typedescr.texte + ' - Bat ' + element.bat + ' - Etage ' + element.etage, value: element.id });
            if (element.typedescr.texte == 'APPARTEMENT') {
                lot.value = { title: element.EXTAPI_LOT_ID + ' - ' + element.typedescr.texte + ' - Etage ' + element.etage, value: element.id }
            }
        });
        acces_resident.value = value;


    }

}


const getEntreprises = async (search_tmp, loading) => {
    const search = search_tmp
    console.log(search)
    loading(true)
    if (metier.value && metier_block.value) {
        // get metier by code and add dot xx.xxx
        const naf = metier.value.code;

        await store.dispatch('fournisseur/fournisseursList', { search: search, naf: naf })
    } else {
        await store.dispatch('fournisseur/fournisseursList', { search: search })
    }
    const imm = store.getters['fournisseur/fournisseursList'].data
    console.log(imm)
    imm.forEach(element => {
        if (element.denominationUniteLegale === null) {
            element.denominationUniteLegale = '';
        }
        if (element.nomUniteLegale === null) {
            element.nomUniteLegale = '';
        }
        element.title = element.denominationUniteLegale + ' ' + element.nomUniteLegale;
    });
    entreprises.value = imm;
    loading(false)

}


const distanceImmeuble = (imm) => {
    const m = Math.sqrt(Math.pow(imm.latitude - immeuble.value.lat, 2) + Math.pow(imm.longitude - immeuble.value.lng, 2));

    return m
}

const onEntrepriseChange = async (value) => {
    console.log(value)
    entreprise.value = value;
    // appliquer le code métier dans le champ
    // remove dot
    const search = value.activitePrincipaleUniteLegale


    metier.value = metiers.value.find(a => a.code === search);

    var etablissements_tmp = value.fournisseurs_limited;
    if(etablissements_tmp.length === 5){
        // fetch all etablissements for this siret
        await store.dispatch('fournisseur/getFournisseur', value.siren)
        const imm = store.getters['fournisseur/oneFournisseur']
        etablissements_tmp = imm.fournisseurs

    }
    // order etablissements by distance from immeuble
    etablissements_tmp = etablissements_tmp.sort((a, b) => distanceImmeuble(a) - distanceImmeuble(b));
    etablissements_tmp = etablissements_tmp.filter(a => a.etatAdministratifEtablissement === 'A');

    metier_block.value = false;
    etablissements_tmp.forEach(
        element => {
            element.title = element.typeVoieEtablissement + ' ' + element.libelleVoieEtablissement + ' ' + element.codePostalEtablissement + ' ' + element.libelleCommuneEtablissement;
        }
    )

    if (etablissements_tmp.length === 1) {
        etablissement.value = etablissements_tmp[0];
    } else {
        etablissement.value = '';
    }

    etablissements.value = etablissements_tmp;

}


const getMetiers = async () => {
    /*const search = search_tmp
    console.log(search)*/
    //loading(true)

    await store.dispatch('fournisseur/metiersList', {})


    const imm = store.getters['fournisseur/metiersList']
    console.log(imm)
    metiers.value = imm;
    /*

                <button class="btn-metiers btn-cat btn btn-xs"  @click="changeCat('nettoyage')" id="cat_nettoyage"><i class="fa fa-broom"></i> Nettoyage</button>
                <button class="btn-metiers btn btn-xs " @click="changeCat('elec')" id="cat_elec"><i class="fa fa-bolt"></i> Électriciens</button>
                <button class="btn-metiers btn btn-xs"  @click="changeCat('plomb')" id="cat_plomb"><i class="fa fa-wrench"></i> Plombiers</button>
                <button class="btn-metiers btn btn-xs "  @click="changeCat('peintre')" id="cat_peintre"><i class="fa fa-brush"></i> Peintres</button>
                <button class="btn-metiers btn btn-xs "  @click="changeCat('macon')" id="cat_macon"><i class="fa fa-hammer"></i> Maçons</button>
                <button class="btn-metiers btn btn-xs "  @click="changeCat('paysage')" id="cat_paysage"><i class="fa fa-leaf"></i> Paysagistes</button>
                <button class="btn-metiers btn btn-xs "  @click="changeCat('menui')" id="cat_menui"><i class="fa fa-screwdriver"></i> Menuisiers</button>
                <button class="btn-metiers btn btn-xs"  @click="changeCat('asc')" id="cat_asc"><i class="fa fa-elevator"></i> Ascensoristes</button>
                <button class="btn-metiers btn btn-xs" @click="changeCat('jur')" id="cat_jur" ><i class="fa fa-balance-scale"></i> Juridique</button>
        case 'nettoyage':
            naf.value = '81.21Z'
            break
        case 'elec':
            naf.value = '43.21A'
            break
        case 'plomb':
            naf.value = '43.22A'
            break
        case 'peintre':
            naf.value = '43.34Z'
            break
        case 'macon':
            naf.value = '41.20A'
            break
        case 'paysage':
            naf.value = '81.30Z'
            break
        case 'menui':
            naf.value = '43.32A'
            break
        case 'asc':
            naf.value = '43.29B'
            break
        case 'jur':
            naf.value = '69.10Z'
            break
                */

    // Prioriser les métiers ci-dessus dans imm (les afficher en premier)
    const imm_prio = metiers.value.filter(a => a.code === '81.21Z' || a.code === '43.21A' || a.code === '43.22A' || a.code === '43.34Z' || a.code === '41.20A' || a.code === '81.30Z' || a.code === '43.32A' || a.code === '43.29B' || a.code === '69.10Z');
    // Associer en libellé les métiers ci-dessus (afficher le nom commun avant le libellé officiel puis le code)
    imm_prio.forEach(element => {
        switch(element.code){
            case '81.21Z':
                element.mot_clef = 'Nettoyage';
                break
            case '43.21A':
                element.mot_clef = 'Électriciens';
                break
            case '43.22A':
                element.mot_clef = 'Plombiers';
                break
            case '43.34Z':
                element.mot_clef = 'Peintres';
                break
            case '41.20A':
                element.mot_clef = 'Maçons';
                break
            case '81.30Z':
                element.mot_clef = 'Paysagistes';
                break
            case '43.32A':
                element.mot_clef = 'Menuisiers';
                break
            case '43.29B':
                element.mot_clef = 'Ascensoristes';
                break
            case '69.10Z':
                element.mot_clef = 'Juridique';
                break
        }
        element.libelle = element.mot_clef + ' - ' + element.libelle + ' (' + element.code + ')';
    });

    const imm_other = metiers.value.filter(a => a.code !== '81.21Z' && a.code !== '43.21A' && a.code !== '43.22A' && a.code !== '43.34Z' && a.code !== '41.20A' && a.code !== '81.30Z' && a.code !== '43.32A' && a.code !== '43.29B' && a.code !== '69.10Z');
    metiers.value = imm_prio.concat(imm_other);






    if (imm.length === 1) {
        metier.value = imm[0];
    }

    // IndexedDB version

    //loading(false)

}
const onMetierChange = (value) => {
    console.log(value)
    metier.value = value;
    // appliquer le code métier dans le champ
    // remove dot
    // const search = value.code.trim().replace(/\./g, '');

    metier_block.value = true;
    getEntreprises("", () => { })


}

const onContratChange = (value) => {
    console.log(value)
    // open modal if contrat is not valid
    // if siret
    if (value.ext_fournisseur?.syndic_fournisseur?.fournisseur) {
        console.log('syndic_fournisseur', value.ext_fournisseur.syndic_fournisseur.fournisseur)
    } else {
        showModalContrat.value = true;
        console.log('no syndic_fournisseur')
    }


    if (value.siret === null) {
        showModalContrat.value = true;
    }
}

const filterMetier = (option, search) => {
    console.log("option", option)
    console.log("search", search)
    return option.filter((option) => {
        if (option.libelle === null) {
            option.libelle = '';
        }
        if (option.mot_clef === null) {
            option.mot_clef = '';
        }
        return option.libelle.toLowerCase().includes(search.toLowerCase()) || option.mot_clef.toLowerCase().includes(search.toLowerCase());
    });
}



// listen to global addfournisseur emitted event this.$emit('addfournisseur', re);


const onAddFournisseur = async (re) => {
    console.log(re)
    if (re.denominationUniteLegale === null) {
        re.denominationUniteLegale = '';
    }
    if (re.nomUniteLegale === null) {
        re.nomUniteLegale = '';
    }
    re.title = re.denominationUniteLegale + ' ' + re.nomUniteLegale;
    entreprises.value.push(re);
    entreprise.value = re;


    await getMetiers()
    onEntrepriseChange(re);

    showModalAddEntreprise.value = false;


}

const onAddFournisseurContrat = async (re) => {
    // reload contrats
    await getImmeubleContrats();
    console.log(re)

    showModalContrat.value = false;


}

const backdropClickDeclarant = (event) => {
    if (event.target.id == 'backdrop') {
        showModalDeclarant.value = false
    }
}

const backdropClickAddFournisseur = (event) => {
    if (event.target.id == 'backdrop') {
        showModalAddEntreprise.value = false
    }
}

const ajoutPersoMode = () => {
    new_declarant.value = {
        nom: '',
        prenom: '',
        civilite: 'Civilité',
        mail: '',
        phone: '',
        adresse: '',
        code_postal: '',
        ville: '',
        pays: '',
        imm_aff: [],
        mandataire: '',
        connaissance_localisation: 'connait_son_proprietaire'
    }
    // get search value and set it to new_declarant
    if (search_declarant.value.length > 3) {
        // if @ is in search_declarant, it's a mail
        if (search_declarant.value.includes('@')) {
            new_declarant.value.mail = search_declarant.value;
        } else {
            // if it's a phone number
            if (search_declarant.value.match(/^[0-9] +$/) != null) {
                new_declarant.value.phone = search_declarant.value;
            } else {
                // if there is a space, it's a name and firstname
                if (search_declarant.value.includes(' ')) {
                    const name = search_declarant.value.split(' ');
                    new_declarant.value.nom = name[0];
                    new_declarant.value.prenom = name[1];
                } else {
                    new_declarant.value.nom = search_declarant.value;
                }
            }
        }
    }
    showAjoutPerso.value = true;
}


// utiliser la base adresse nationale pour compléter l'adresse
const getAdresse = async () => {
    console.log(new_declarant.value)
    if (new_declarant.value.code_postal.length < 5) {
        return;
    }
    if (new_declarant.value.code_postal.length > 5) {
        new_declarant.value.code_postal = new_declarant.value.code_postal.substr(0, 5);
    }
    fetch('https://api-adresse.data.gouv.fr/search/?q=' + new_declarant.value.code_postal)
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.features.length > 0) {
                new_declarant.value.ville = data.features[0].properties.city;
            }
        });
}

const onDeclarantProprietaireChange = (value) => {
    console.log(value)
    lotsValueProp.value = [];
    new_declarant.value.lot = '';
    value.lots.forEach(element => {
        lotsValueProp.value.push({ title: element.EXTAPI_LOT_ID + ' - ' + element.typedescr.texte + ' - Bat ' + element.bat + ' - Etage ' + element.etage, value: element.id,perso_mini:element.perso_mini });
        if (element.typedescr.texte.includes('APPARTEMENT')) {
            //    new_declarant.value.lot = { title: element.EXTAPI_LOT_ID + ' - ' + element.typedescr.texte + ' - Etage ' + element.etage, value: element.id }
        }
    });
}

const validerAjoutTiers = () => {

    // verifier que la civilité est remplie
    if (new_declarant.value.civilite === 'Civilité') {
        alert('La civilité est obligatoire')

        return;
    }
    // verifier que les champs sont remplis
    if (new_declarant.value.nom.length < 2) {
        alert('Le nom est obligatoire')
        return;
    }
    if (new_declarant.value.prenom.length < 2) {
        alert('Le prénom est obligatoire')
        return;
    }


    // verifier que le mail ou le téléphone est rempli et valide
    if (new_declarant.value.mail.length < 2 || !new_declarant.value.mail.includes('@')) {
        if (!new_declarant.value.mail_oppose) {
            alert('Le mail est obligatoire')
            return;
        }
    }
    // telephone avec 10 chiffres (espaces, points et tirets possibles)
    if (new_declarant.value.phone.length < 2 || new_declarant.value.phone.match(/^[0-9 .-]+$/) === null) {
        if (!new_declarant.value.phone_oppose) {
            alert('Le téléphone est obligatoire')
            return;
        }
    }

    // si locataire et connait son propriétaire, vérifier que le résident et le lot sont rempli
    if (new_declarant.value.connaissance_localisation === 'connait_son_proprietaire') {
        if (!new_declarant.value.proprietaire) {
            alert('Le propriétaire est obligatoire')
            return;
        }
        if (!new_declarant.value.lot) {
            alert('Le lot est obligatoire')
            return;
        }
    }

    // si locataire et connait son gérant, vérifier que le mandataire est rempli et le lot
    if (new_declarant.value.connaissance_localisation === 'connait_son_mandataire') {
        if (!new_declarant.value.mandataire) {
            alert('Le gérant est obligatoire')
            return;
        }

        if (!new_declarant.value.lot) {
            alert('Le lot est obligatoire')
            return;
        }
    }

    // si locataire et sans contacts, étage obligatoire
    if (new_declarant.value.connaissance_localisation === 'sans_contacts') {
        if (!new_declarant.value.etage) {
            alert('L\'étage est obligatoire')
            return;
        }
    }

    // si n'est pas résident, vérifier que l'adresse est remplie
    if (new_declarant.value.connaissance_localisation === 'non_resident') {
        if (!new_declarant.value.qualite) {
            alert('Merci d\'indiquer une qualité')
            return;
        }


        if (new_declarant.value.adresse.length < 2) {
            alert('Merci d\'indiquer une adresse')
            return;
        }
        if (new_declarant.value.code_postal.length < 5) {
            alert('Merci d\'indiquer un code postal')
            return;
        }
        if (new_declarant.value.ville.length < 2) {
            alert('Merci d\'indiquer une ville')
            return;
        }

    }


    // save with store
    try{
    store.dispatch('perso/addPerso', new_declarant.value)

    new Swal({
                toast: true,
                position: "top-right",
                iconColor: "white",
                popup:  "colored-toast-success",
                showConfirmButton: false,
                showCloseButton: true,
                timer: 2000,
                timerProgressBar: true,
          text: "Déclarant enregistré",
        });
    } catch(e){
        console.log(e)
    new Swal({
                toast: true,
                position: "top-right",
                iconColor: "white",
                popup:  "colored-toast-error",
                showConfirmButton: false,
                showCloseButton: true,
                timer: 2000,
                timerProgressBar: true,
          text: "Erreur lors de l'enregistrement du déclarant",
        });
    }


    onResidentChange(new_declarant.value, 1)


    showAjoutPerso.value = false;
}

const searchResults = ref([]);

const searchAddress = async () => {
    if (new_declarant.value.adresse < 3) return;

    try {
        const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(new_declarant.value.adresse)}&limit=5`);
        searchResults.value = response.data.features;
    } catch (error) {
        console.error('Erreur lors de la recherche d\'adresse:', error);
    }
};

const selectAddress = (object, adresse) => {

    object.adresse = adresse.properties.name;
    object.code_postal = adresse.properties.postcode;
    object.ville = adresse.properties.city;

    // Effacer les résultats de recherche
    searchResults.value = [];
};

const validerModifCodes = () => {
    console.log(clefs.value)
    const codes = clefs.value.map(el => {
        return { code: el.code, type: el.type, localisation: el.localisation, title: el.localisation + ' - ' + el.code }
    })
    console.log(codes)

    try{
        store.dispatch('immeuble/addAttributeImmeuble', {
            id: immeuble.value.id,
            type: 'codes',
            value: codes,
            name: 'Codes et clefs'
        })
        // notify

        new Swal({
                toast: true,
                position: "top-right",
                iconColor: "white",
                popup:  "colored-toast-success",
                showConfirmButton: false,
                showCloseButton: true,
                timer: 2000,
                timerProgressBar: true,
          text: "Les codes et clefs ont été enregistrés avec succès",
        });

    } catch (e) {

        console.log(e)
        new Swal({
                toast: true,
                position: "top-right",
                iconColor: "white",
                popup:  "colored-toast-error",
                showConfirmButton: false,
                showCloseButton: true,
                timer: 2000,
                timerProgressBar: true,
          text: "Une erreur est survenue lors de l'enregistrement des codes et clefs",
        });
    }
        clefs.value = codes
        showModalCodes.value = false;
}

const titre= ref('')
const message= ref('')


const pdf_options = {
    title: 'PDF',
    filename: 'test.pdf',
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }

}


const modeles = ref([])
const modele = ref('')

watch(submode, (value) => {
    getModelCollab(value)

})
const getModelCollab = async (value)=> {

    var type;
    console.log(value)
    switch(value){
        case 'demande_devis':
            type = 'devis'
            break;
        case 'demande_intervention':
            type = 'os'
            break;
    }
    // if model is already loaded, don't reload
    if(modeles.value.find(el => el.heritages[0].case === type)){
        modele.value = JSON.parse(modeles.value.find(el => el.case === type).data[0]?.data)
        console.log("MODELE",modele.value)
        return;
    }
    await store.dispatch('modele/modelesListGest',{case:type})
    let modelestmp = store.getters['modele/modelesList'].data
    modeles.value = [...modeles.value, ...modelestmp]
    modele.value = JSON.parse(modelestmp[0].data)


    console.log("MODELES",modeles.value)
    console.log("MODELE",modele.value)

}

const pdf = computed(()=>{
    /*
    replace all {{}} by values (ex {{immeuble->nom}} becomes immeuble.nom)
    */
    let pdf = modele.value
    let keys = pdf.match(/{{(.*?)}}/g)
    if(keys){
        var moyen_acces_calc = ''
        console.log(mode_moyen_acces.value)

        if(mode_moyen_acces.value == 'gardien'){
            console.log(gardienVal.value)
            moyen_acces_calc = gardienVal.value.nom + ' ' + gardienVal.value.prenom
        } else if(mode_moyen_acces.value == 'libre'){
            moyen_acces_calc = moyen_libre_os.value
        } else if(mode_moyen_acces.value == 'resident'){
            console.log(resident.value)
            moyen_acces_calc = resident.value.nom + ' ' + resident.value.prenom
        } else if(mode_moyen_acces.value == 'clef_codes'){
            console.log("laclef",clef.value)
            moyen_acces_calc = clef.value.map(el => el.title).join('\n')
        }

        keys.forEach(key => {
            let keyname = key.replace('{{','').replace('}}','')
            // split ->
            let keysplit = keyname.split('-&gt;')
            let value = keysplit.reduce((acc, val) => {
                if(acc[val]){
                    return acc[val]
                } else {
                    return '[NOT FOUND]'
                }
            }, {immeuble: immeuble.value, resident: resident.value, entreprise: entreprise.value, metier: metier.value,
                etablissement: etablissement.value,
                contrat: contrat.value, gardien: gardienVal.value, clefs: clefs.value, mandataires: mandataires.value, new_declarant: new_declarant.value, cops: cops.value, lot: lot.value, etage: etages.value, batiment: batiments.value, escalier: escaliers.value, porte: portes.value,
                acces_resident: acces_resident.value,
                message: message.value,
                titre: titre.value,
                moyen_acces:moyen_acces_calc,
                num_os:"#"+ticket_id.value,
                qrcode: 'https://web.mycopro.fr/tickets/'+ticket_id.value,
                author: JSON.parse(sessionStorage.getItem('fullme'))

            })
            pdf = pdf.replace(key, value)
        })
    }
    return pdf
})

const ticket_id = ref(0)
const ticket_event_id = ref(0)

watch(page, async (value) => {

    //store.dispatch('perso/addPerso', new_declarant.value)
    console.log(value)
    new Swal({
                toast: true,
                position: "top-right",
                iconColor: "white",
                popup:  "colored-toast-success",
                showConfirmButton: false,
                //showCloseButton: true,
                timer: 2000,
                timerProgressBar: true,
          text: "Brouillon enregistré",
        });

    if(value == 2 && ticket_id.value == 0){
        // generate pdf
        //html2pdf().from(pdf.value).set(pdf_options).save()
        // sender
        console.log(resident.value)
        console.log(new_declarant.value)
        await store.dispatch('event/addTicket', {
            description:props.mail?.body,//
            title:props.mail?.subject,
            way_id:props.mail?.id,
            way_type:"mail",
            metier_createur_ticket:"syndic",
            declarant_type:resident.value ? 'resident' : 'tiers',
            declarant_id:resident.value.perso_mini ? resident.value.perso_mini.id : new_declarant.value.id,
            //title:titre.value,
            objet:titre.value,
            moyen_acces:mode_moyen_acces.value,
            lieu_action:"immeuble",
            plage_action:"immediat",
            interlocuteur_id:1,
            type_demande:submode.value,

            message:message.value, pdf:pdf.value,
            immeuble_id:immeuble.value.id, resident:resident.value,
            entreprise:entreprise.value, metier:metier.value, etablissement:etablissement.value,
            contrat:contrat.value, gardien:gardienVal.value, clefs:clefs.value, mandataires:mandataires.value,
            new_declarant:new_declarant.value, cops:cops.value, lot:lot.value, etage:etages.value, batiment:batiments.value, escalier:escaliers.value, porte:portes.value, acces_resident:acces_resident.value
        })
        // get value returned by store
        let v = store.getters['event/oneEvent']
        ticket_id.value = v[0];
        ticket_event_id.value = v[1];

        console.log(v)

    } else {

        await store.dispatch('event/updateTicket', {
            id:ticket_id.value,
            status: 'brouillon',
            title:titre.value != undefined ? titre.value : '',
            metier_createur_ticket:"syndic",


            /*description:props.mail?.body,//
            title:props.mail?.subject,
            way_id:props.mail?.id,
            way_type:"mail",
            metier_createur_ticket:"syndic",
            declarant_type:resident.value ? 'resident' : 'tiers',
            declarant_id:resident.value.perso_mini ? resident.value.perso_mini.id : new_declarant.value.id,
            //title:titre.value,
            message:message.value, pdf:pdf.value,
            immeuble_id:immeuble.value.id, resident:resident.value,
            entreprise:entreprise.value, metier:metier.value, etablissement:etablissement.value,
            contrat:contrat.value, gardien:gardienVal.value, clefs:clefs.value, mandataires:mandataires.value,
            new_declarant:new_declarant.value, cops:cops.value, lot:lot.value, etage:etages.value, batiment:batiments.value, escalier:escaliers.value, porte:portes.value, acces_resident:acces_resident.value*/
        })
        await store.dispatch('event/updateSubTicket',{
            id:ticket_id.value,
            subid:ticket_event_id.value,

            ticket_demande:true,// ne s'occuper que des sous-tickets de demande
            draft:true,
            description:props.mail?.body,//
            //title:props.mail?.subject,
            way_id:props.mail?.id,
            way_type:"mail",
            metier_createur_ticket:"syndic",
            declarant_type:resident.value ? 'resident' : 'tiers',
            declarant_id:resident.value.perso_mini ? resident.value.perso_mini.id : new_declarant.value.id,
            title:titre.value,
            objet:titre.value,
            moyen_acces:mode_moyen_acces.value,
            lieu_action:"immeuble",
            plage_action:"immediat",
            interlocuteur_id:1,
            type_demande:submode.value,

            message:message.value, pdf:pdf.value,
            immeuble_id:immeuble.value.id, resident:resident.value,
            entreprise:entreprise.value, metier:metier.value, etablissement:etablissement.value,
            contrat:contrat.value, gardien:gardienVal.value, clefs:clefs.value, mandataires:mandataires.value,
            new_declarant:new_declarant.value, cops:cops.value, lot:lot.value, etage:etages.value, batiment:batiments.value, escalier:escaliers.value, porte:portes.value, acces_resident:acces_resident.value


        })

    }
})

</script>
<template>
    <div style="text-align: center;">


        <Modal v-model="showModalCodes" header="Codes et clefs pour l'immeuble" @close="showModalCodes = false">
            <div v-if="clefs.length > 0">

                <table class="table">
                    <thead>
                        <th>Type</th>
                        <th>Localisation</th>
                        <th>Valeur</th>
                    </thead>
                    <tbody>
                        <tr v-for="clef in clefs" :key="clef.id">
                            <td>
                                <select class="form-control" v-model="clef.type">
                                    <option value="code">Code</option>
                                    <option value="clef">Clef</option>
                                    <option value="vigik">Vigik</option>
                                </select>
                            </td>
                            <td><input type="text" class="form-control" v-model="clef.localisation"
                                    placeholder="Localisation" /></td>
                            <td>
                                <input v-if="clef.type == 'code'" type="text" class="form-control" v-model="clef.code"
                                    placeholder="Valeur" />
                                <input v-else type="text" class="form-control" v-model="clef.code" placeholder="Valeur"
                                    list="clefs_valeurs" />
                            </td>
                        </tr>

                    </tbody>
                    <datalist id="clefs_valeurs">
                        <option value="Disponible à l'agence"></option>
                        <option value="Boite à clefs"></option>
                    </datalist>
                </table>
                <button @click="clefs.push({})" class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Ajouter des
                    clefs ou des codes</button>
                <br> <br>
                <div class="d-flex">
                    <button class="btn" @click="showModalCodes = false">Annuler</button>
                    <div style="flex:1"></div>
                    <button class="btn btn-success" @click="validerModifCodes">
                        <i class="fa fa-check"></i>
                        Valider</button>
                </div>
            </div>

            <div v-else class="text-center">

                <br>
                <p>Il n'y a pas de clefs ou de codes enregistrés pour cette copropriété.</p><br />

                <button @click="clefs.push({})" class="btn btn-lg btn-success"><i class="fa fa-plus"></i> Ajouter des
                    clefs ou des codes à cette copropriété</button>
                <br>
                <br>
                <button class="btn" @click="showModalCodes = false">Annuler</button>
            </div>


        </Modal>


        <teleport to="body">
            <transition name="fade">
                <div v-if="showModalDeclarant" class=" position-fixed top-0 start-0 h-100 w-100"
                    style="background-color: rgba(0, 0, 0, 0.25);z-index: 10;">
                    <div id="backdrop" @click="backdropClickDeclarant"
                        class="modal1-dialog h-100 overflow-auto text-center">
                        <div class="w-50 pt-5 " style="margin-left: 25%;">
                            <Transition name="slide-up" mode="out-in">
                                <div class="card px-0 w-100 h-80 p-2 bg-yellow" v-if="showAjoutPerso == false"
                                    style="box-shadow: 0 0 1rem 6px rgba(8, 7, 7, 0.38);">

                                    <div class="p-2 ">
                                        <div class="d-flex">
                                            <button class="btn btn-xs btn-white"
                                                @click="showModalDeclarant = false">Annuler</button>
                                            <div style="flex:1"></div>
                                            <div class="text-bold">Choisir un autre déclarant</div>
                                            <div style="flex:1"></div>
                                        </div>

                                        <input type="text" class="form-control"
                                            placeholder="Rechercher un déclarant parmi les personnalités existantes"
                                            v-model="search_declarant" />

                                        <div class="bg-white rounded text-xs text-dark text-start p-2 m-2"
                                            v-if="all_persos.length">

                                            <table>
                                                <tr class="border-bottom" v-for="perso in all_persos" :key="perso.id">
                                                    <td class="text-xs">{{ perso.nom }} {{ perso.prenom }}</td>
                                                    <td class="">{{ perso.mails[0]?.mail.substr(0, 30) }}<br>
                                                        {{ perso.phones[0]?.phone }}
                                                    </td>
                                                    <td style="flex:1">
                                                        <a v-for="i in perso.imm_aff" :key="i"
                                                            class="badge badge-xs bg-danger">{{ i.nom }}</a>
                                                    </td>
                                                    <td class="text-end"><button
                                                            class="btn btn-xs btn-white bg-yellow mb-0"
                                                            @click="onResidentChange(perso, true);">Sélectionner</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div v-if="all_persos_loading" class="text-center">
                                            <i class="fa fa-spinner fa-spin"></i>
                                        </div>
                                        <div
                                            v-if="!all_persos_loading && all_persos.length == 0 && search_declarant.length > 3">
                                            <br>
                                            Aucune personne trouvée
                                        </div>
                                        <br>

                                        <button class="btn btn-white" @click="ajoutPersoMode"><i
                                                class="fa fa-plus"></i>Ajouter une nouvelle
                                            personnalité</button>

                                    </div>
                                </div>
                                <div class="card px-0 w-100 h-80 p-2 bg-yellow" v-else>

                                    <div class="p-2 ">
                                        <div class="d-flex">
                                            <button class="btn btn-xs btn-white"
                                                @click="showAjoutPerso = false">Annuler</button>
                                            <div style="flex:1"></div>
                                            <div class="text-bold">Créer un déclarant</div>


                                        </div>
                                        <div class="bg-white text-xs text-dark text-start p-2 m-2"
                                            style="border-radius: 1rem;">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <!--Civilite-->
                                                    <select class="form-control" v-model="new_declarant.civilite"
                                                        placeholder="Civilité">
                                                        <option selected disabled value="Civilité">Civilité</option>
                                                        <option value="M">M</option>
                                                        <option value="Mme">Mme</option>
                                                    </select>
                                                </div>

                                                <div class="col-md-5">
                                                    <input class="form-control" placeholder="Nom"
                                                        v-model="new_declarant.nom">

                                                </div>
                                                <div class="col-md-5">
                                                    <input class="form-control" placeholder="Prénom"
                                                        v-model="new_declarant.prenom">

                                                </div>
                                                <div class="col-md-10">
                                                    <input class="form-control" type="mail" placeholder="Email"
                                                        v-model="new_declarant.mail"
                                                        :disabled="new_declarant.mail_oppose">


                                                </div>
                                                <div class="col-md-2 text-start">
                                                    <button class="btn btn-xs mb-0 h-100"
                                                        @click="new_declarant.mail_oppose = !new_declarant.mail_oppose"
                                                        :class="new_declarant.mail_oppose ? 'btn-danger' : 'btn-outline-danger'"><i
                                                            class="fa fa-times"></i>Opposé
                                                    </button>
                                                </div>
                                                <div class="col-md-10">
                                                    <input class="form-control" placeholder="Téléphone"
                                                        v-model="new_declarant.phone"
                                                        :disabled="new_declarant.phone_oppose">
                                                </div>
                                                <div class="col-md-2 text-start">
                                                    <button class="btn btn-xs mb-0 h-100"
                                                        @click="new_declarant.phone_oppose = !new_declarant.phone_oppose"
                                                        :class="new_declarant.phone_oppose ? 'btn-danger' : 'btn-outline-danger'"><i
                                                            class="fa fa-times"></i>Opposé
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                        <div>
                                            <!---->
                                        </div>

                                        <div class=" p-2 border-radius-lg m-2"
                                            style="background-color: color(srgb 0.875 0.5987 0.4112);">
                                            <div class="btn-group">
                                                <button class="btn btn-light"
                                                    :style="new_declarant.connaissance_localisation == 'connait_son_proprietaire' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                                    @click="new_declarant.connaissance_localisation = 'connait_son_proprietaire'">
                                                    <div class="text-xs">Locataire et</div>
                                                    <i class="fa fa-building"></i> Connait son propriétaire
                                                </button>
                                                <button class="btn btn-light"
                                                    :style="new_declarant.connaissance_localisation == 'connait_son_mandataire' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                                    @click="new_declarant.connaissance_localisation = 'connait_son_mandataire'">
                                                    <div class="text-xs">Locataire et</div>
                                                    <i class="fa fa-smile"></i> Connait son gérant
                                                </button>
                                                <button class="btn btn-light"
                                                    :style="new_declarant.connaissance_localisation == 'sans_contacts' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                                    @click="new_declarant.connaissance_localisation = 'sans_contacts'">
                                                    <div class="text-xs">Locataire et</div>
                                                    <i class="fa fa-question"></i> Sans contacts
                                                </button>
                                                <button class="btn btn-light"
                                                    :style="new_declarant.connaissance_localisation == 'non_resident' ? 'color: white; background-color: rgb(228, 98, 94);' : ''"
                                                    @click="new_declarant.connaissance_localisation = 'non_resident'"><i
                                                        class="fa fa-close"></i> N'est pas résident</button>

                                            </div>


                                            <div v-if="new_declarant.connaissance_localisation == 'connait_son_proprietaire'"
                                                class="bg-white text-xs text-dark text-start p-2 m-2"
                                                style="border-radius: 1rem;">

                                                <div class="bg-yellow mb-2 p-1 border-radius-lg">

                                                    <div style="display: flex;">
                                                        <div
                                                            class="bg-yellow border-radius-lg p-2 text-dark text-sm pr-3">
                                                            Propriétaire</div>
                                                        <div class="bg-yellow border-radius-lg p-1 text-bold text-white w-100"
                                                            style="margin-top: -2px; font-weight: 700;">
                                                            <vSelect style="color:white" :options="cops" label="title"
                                                                :clearable="false" placeholder="Choisir un résident"
                                                                v-model="new_declarant.proprietaire"
                                                                @update:modelValue="onDeclarantProprietaireChange"
                                                                class="style-chooser-yellow">
                                                                <template v-slot:no-options>Aucun résident
                                                                    trouvé.</template>
                                                                <template #option="{ title, locataire, lots }">
                                                                    <div
                                                                        :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">
                                                                        {{ title }}
                                                                        <a v-if="locataire && lots[0]"
                                                                            style="font-size:8pt">PROP : {{
            lots[0].perso_mini.nom }}</a>
                                                                    </div>
                                                                </template>
                                                            </vSelect>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="bg-gray p-1 border-radius-lg">
                                                    <div style="display: flex;">
                                                        <div
                                                            class="bg-gray border-radius-lg p-2 text-white text-sm pr-3">
                                                            Lot</div>
                                                        <div class="bg-gray border-radius-lg p-1 text-bold text-white w-100"
                                                            style="margin-top: -2px; font-weight: 700;">

                                                            <vSelect style="color:white" :options="lotsValueProp"
                                                                label="title" :clearable="false" multiple
                                                                placeholder="Choisir un lot" v-model="new_declarant.lot"
                                                                class="style-chooser-gray">
                                                                <template v-slot:no-options>Aucun lot trouvé.</template>
                                                            </vSelect>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            <div v-else-if="new_declarant.connaissance_localisation == 'connait_son_mandataire'"
                                                class="bg-white text-xs text-dark text-start p-2 m-2"
                                                style="border-radius: 1rem;">

                                                <div class="bg-yellow mb-2 p-1 border-radius-lg">

                                                    <div style="display: flex;">
                                                        <div
                                                            class="bg-yellow border-radius-lg p-2 text-dark text-sm pr-3">
                                                            Mandataire</div>
                                                        <div class="bg-yellow border-radius-lg p-1 text-bold text-white w-100"
                                                            style="margin-top: -2px; font-weight: 700;">
                                                            <vSelect style="color:white" :options="mandataires"
                                                                label="title" :clearable="false"
                                                                placeholder="Choisir un résident"
                                                                v-model="new_declarant.mandataire"
                                                                @update:modelValue="onDeclarantProprietaireChange"
                                                                class="style-chooser-yellow">
                                                                <template v-slot:no-options>Aucun mandataire
                                                                    trouvé.</template>
                                                                <template #option="{ nom }">
                                                                    {{ nom }}
                                                                </template>
                                                            </vSelect>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="bg-gray p-1 border-radius-lg">
                                                    <div style="display: flex;">
                                                        <div
                                                            class="bg-gray border-radius-lg p-2 text-white text-sm pr-3">
                                                            Lot</div>
                                                        <div class="bg-gray border-radius-lg p-1 text-bold text-white w-100"
                                                            style="margin-top: -2px; font-weight: 700;">

                                                            <vSelect style="color:white" :options="lotsValueProp"
                                                                label="title" :clearable="false" multiple
                                                                placeholder="Choisir un lot" v-model="new_declarant.lot"
                                                                class="style-chooser-gray">
                                                                <template v-slot:no-options>Aucun lot trouvé.</template>
                                                                <template #option="{ title, perso_mini  }">
                                                                    <div>
                                                                        {{ title }}
                                                                        <a
                                                                            style="font-size:8pt">PROP : {{perso_mini.nom }}</a>
                                                                    </div>
                                                                </template>
                                                            </vSelect>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            <div v-else-if="new_declarant.connaissance_localisation == 'sans_contacts'"
                                                class="bg-white text-xs text-dark text-start p-2 m-2"
                                                style="border-radius: 1rem;">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <input class="form-control" list="batiments"
                                                            placeholder="Batiment ?" v-model="new_declarant.batiment">
                                                        <datalist id="batiments">
                                                            <option v-for="bat in batiments" :value="bat" :key="bat">
                                                            </option>
                                                        </datalist>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input class="form-control" list="escaliers"
                                                            placeholder="Escalier ?" v-model="new_declarant.escalier">
                                                        <datalist id="escaliers">
                                                            <option v-for="esc in escaliers" :value="esc" :key="esc">
                                                            </option>
                                                        </datalist>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input class="form-control" list="etages" placeholder="Étage"
                                                            v-model="new_declarant.etage">
                                                        <datalist id="etages">
                                                            <option v-for="et in etages" :value="et" :key="et"></option>
                                                        </datalist>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input class="form-control" list="portes" placeholder="Porte"
                                                            v-model="new_declarant.porte">
                                                        <datalist id="portes">
                                                            <option v-for="porte in portes" :value="porte" :key="porte">
                                                            </option>
                                                        </datalist>
                                                    </div>
                                                </div>

                                            </div>
                                            <div v-else class="bg-white text-xs text-dark text-start p-2 m-2"
                                                style="border-radius: 1rem;">

                                                <div class="mb-3">
                                                    <input class="form-control" list="qualites_tiers"
                                                        placeholder="Qualité ?" v-model="new_declarant.qualite">
                                                    <datalist id="qualites_tiers">
                                                        <option value="Voisin"></option>
                                                        <option value="Gestionnaire immobilier"></option>
                                                        <option value="Prestataire de services"></option>
                                                        <option value="Agent de sécurité"></option>
                                                        <option value="Service d'urgence"></option>
                                                    </datalist>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <input class="form-control" placeholder="Adresse"
                                                            v-model="new_declarant.adresse" @keyup="searchAddress">

                                                        <div v-if="searchResults.length > 0" class="card">
                                                            <i>Suggestions :</i>
                                                            <ul class="search-results">
                                                                <li v-for="result in searchResults"
                                                                    :key="result.properties.id"
                                                                    @click="selectAddress(new_declarant, result)">
                                                                    {{ result.properties.label }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input class="form-control" placeholder="Code Postal"
                                                            v-model="new_declarant.code_postal" @keyup="getAdresse">

                                                    </div>
                                                    <div class="col-md-8">
                                                        <input class="form-control" type="mail" placeholder="Ville"
                                                            v-model="new_declarant.ville">

                                                    </div>
                                                </div>

                                            </div>



                                        </div>

                                        <div class="d-flex">
                                            <div style="flex:1"></div>
                                            <button class="btn btn-sm btn-success"
                                                @click="validerAjoutTiers">Valider</button>



                                        </div>

                                    </div>
                                </div>


                            </Transition>
                        </div>

                    </div>
                </div>
            </transition>
        </teleport>



        <teleport to="body">
            <transition name="fade">
                <div v-if="showModalContrat" class=" position-fixed top-0 start-0 h-100 w-100"
                    style="background-color: rgba(0, 0, 0, 0.25);z-index: 10;">
                    <div id="backdrop" @click="backdropClickContrat"
                        class="modal1-dialog h-100 overflow-auto text-center">
                        <div class="w-70 pt-5 " style="margin-left: 15%;">
                            <div class="bg-white card" style="overflow-y: scroll;">
                                <button class="btn btn-xs btn-danger" style="
position: absolute;
z-index: 15;
margin: 10px;
" @click="showModalContrat = false; contrat = ''">Annuler</button>

                                <AjoutFournisseur @add-fournisseur="onAddFournisseurContrat"
                                    :defaultcompany="contrat?.ext_fournisseur" />
                            </div>

                        </div>

                    </div>
                </div>
            </transition>
        </teleport>



        <teleport to="body">
            <transition name="fade">
                <div v-if="showModalAddEntreprise" class=" position-fixed top-0 start-0 h-100 w-100"
                    style="background-color: rgba(0, 0, 0, 0.25);z-index: 10;">
                    <div id="backdrop" @click="backdropClickAddFournisseur"
                        class="modal1-dialog h-100 overflow-auto text-center">
                        <div class="w-70 pt-5 " style="margin-left: 15%;">
                            <div class="bg-white card" style="overflow-y: scroll;">
                                <button class="btn btn-xs btn-danger" style="position: absolute;z-index: 15;margin: 10px;"
                                @click="showModalAddEntreprise = false">Annuler</button>

                                <AjoutFournisseur @add-fournisseur="onAddFournisseur" />
                            </div>

                        </div>

                    </div>
                </div>
            </transition>
        </teleport>
        <!--
        <ModalNoBorder v-model="showModalAddEntreprise" closeable @close="showModalAddEntreprise = false"
            header="Ajouter un fournisseur">
        </ModalNoBorder>-->
        <Transition name="slide-up" mode="out-in">
        <div class="card card-sm p-2" v-if="page ==1">



            <div class="btn-group">
                <button class="btn btn-light w-100"
                    :style="mode === 'consigner' ? 'color: white; background-color: rgb(94, 114, 228);' : ''"
                    @click="mode = 'consigner'">
                    <i class="fa fa-edit"></i> Consigner
                </button>
                <button class="btn btn-light w-100"
                    :style="mode === 'intervention' ? 'color: white; background-color: rgb(255, 121, 15);' : ''"
                    @click="mode = 'intervention'"><i class="fa fa-wrench"></i> Intervenir</button>
                <button class="btn btn-light w-100"
                    :style="mode === 'communiquer' ? 'color: white; background-color: rgb(16, 184, 95);' : ''"
                    @click="mode = 'communiquer'">
                    <i class="fa fa-bullhorn"></i> Communiquer
                </button>

            </div>


            <div class=" w-100">
                <div class="btn-group w-100 " v-if="mode === 'intervention'">
                    <button class="btn btn-xs btn-light w-100"
                        :style="submode === 'intervention' ? 'color: white; background-color: black;' : ''"
                        @click="submode = 'intervention'">
                        <i class="fa fa-fire"></i> Sinistre
                    </button>
                    <button class="btn btn-xs btn-light w-100"
                        :style="submode === 'demande_devis' ? 'color: white; background-color: black;' : ''"
                        @click="submode = 'demande_devis'">
                        <i class="fa fa-sign-hanging"></i> Demande de devis
                    </button>
                    <button class="btn btn-xs btn-light w-100"
                        :style="submode === 'demande_intervention' ? 'color: white; background-color: black;' : ''"
                        @click="submode = 'demande_intervention'">
                        <i class="fa fa-wrench"></i> Demande d'intervention
                    </button>

                </div>






                <div v-if="mode === 'communiquer'">
                    <div class="btn-group">
                        <button class="btn btn-light"
                            :style="submode === 'intervention' ? 'color: white; background-color: rgb(16, 184, 95);' : ''"
                            @click="submode = 'intervention'">
                            <i class="fa-solid fa-print"></i> Écrire un courrier
                        </button>
                        <button class="btn btn-light"
                            :style="submode === 'demande_devis' ? 'color: white; background-color: rgb(16, 184, 95);' : ''"
                            @click="submode = 'demande_devis'">
                            <i class="fa fa-at"></i> Écrire un mail
                        </button>
                        <button class="btn btn-light"
                            :style="submode === 'phone' ? 'color: white; background-color: rgb(16, 184, 95);' : ''"
                            @click="submode = 'phone'">
                            <i class="fa fa-comment-sms"></i> Afficher le téléphone
                        </button>

                    </div>
                </div>
            </div>


            <div class="card card-sm p-2">

                <div class="bg-red border-radius-lg p-2 mb-2" style="display: flex;">
                    <div class="bg-red border-radius-lg p-2 text-white text-xs pr-3">Immeuble</div>
                    <div class="bg-red border-radius-lg p-1 text-bold text-white w-100"
                        style="margin-top: 2px; font-weight: 700;">{{ immeuble.nom }}</div>
                    <!-- <button class="btn btn-xs btn-white mb-0"><i class="fa-solid fa-arrow-up-right-from-square"></i></button>-->
                </div>
                <div class="bg-yellow border-radius-lg p-2 mb-2">
                    <div style="display: flex;">
                        <div class="bg-yellow border-radius-lg p-2 text-dark text-xs pr-3">Déclarant</div>
                        <div class="bg-yellow border-radius-lg p-1 text-bold text-dark w-100"
                            style="margin-top: -2px; font-weight: 700;">

                            <vSelect style="color:white" :options="cops" label="title" :clearable="false"
                                placeholder="Choisir un résident" v-model="resident"
                                @update:modelValue="onResidentChange" class="style-chooser-yellow">
                                <template v-slot:no-options>Aucun résident trouvé.</template>
                                <template #option="{ title, locataire, lots }">
                                    <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">{{ title }}
                                        <a v-if="locataire && lots[0]" style="font-size:8pt">PROP : {{lots[0].perso_mini.nom }}</a>
                                    </div>
                                </template>
                            </vSelect>
                        </div>

                        <el-tooltip content="Choisir un autre déclarant" placement="left">
                            <button class="btn btn-xs btn-white mb-0" @click="showModalDeclarant = true"><i
                                    class="fa fa-users"></i></button>
                        </el-tooltip>
                    </div>
                    <div class="bg-gray border-radius-lg p-2 ">
                        <div style="display: flex;">
                            <div class="bg-gray border-radius-lg p-2 text-white text-sm pr-3">Lot</div>
                            <div class="bg-gray border-radius-lg p-1 text-bold text-white w-100"
                                style="margin-top: -2px; font-weight: 700;">

                                <vSelect style="color:white" :options="lotsValue" label="title" :clearable="false"
                                    multiple placeholder="Choisir un lot" v-model="lot" @update:modelValue="onLotChange"
                                    class="style-chooser-gray">
                                    <template v-slot:no-options>Aucun lot trouvé.</template>
                                </vSelect>
                            </div>

                        </div>

                    </div>


                </div>

                <div class="bg-light-purple border-radius-lg p-2 mb-2"
                    v-if="mode == 'intervention' && (submode == 'demande_devis' || submode == 'demande_intervention')">

                    <div class="btn-group">
                        <button class="btn btn-light btn-xs"
                            :style="mode_contrat === 'avec' ? 'color: white; background-color: rgb(94, 114, 228);' : ''"
                            @click="mode_contrat = 'avec'">
                            <i class="fa fa-edit"></i> Avec contrat
                        </button>
                        <button class="btn btn-light btn-xs"
                            :style="mode_contrat === 'sans' ? 'color: white; background-color: rgb(94, 114, 228);' : ''"
                            @click="mode_contrat = 'sans'"><i class="fa fa-close"></i> Sans contrat</button>

                    </div>


                    <div style="display: flex;" v-if="mode_contrat === 'sans'">
                        <div class="bg-light-purple border-radius-lg p-2 text-white text-sm pr-3">Métier</div>
                        <div class="bg-light-purple border-radius-lg p-1 text-bold text-white w-100"
                            style="margin-top: -2px; font-weight: 700;">

                            <vSelect style="color:black" :options="metiers" label="libelle" :clearable="false"
                                placeholder="Choisir un métier" v-model="metier" :filter="filterMetier"
                                @update:modelValue="onMetierChange" class="style-chooser-light-purple">
                                <template v-slot:no-options>Aucun métier trouvé.</template>
                                <template #option="{ libelle, code }">
                                    <div>{{ libelle }} - {{ code }}
                                    </div>
                                </template>
                            </vSelect>
                        </div>
                        <!--<button class="btn btn-xs btn-white mb-0"><i
                            class="fa-solid fa-arrow-up-right-from-square"></i></button>-->
                    </div>

                    <div class="bg-purple border-radius-lg p-2 " v-if="mode_contrat === 'sans'">
                        <div style="display: flex;">
                            <div class="bg-purple border-radius-lg p-2 text-white text-sm pr-3">
                                Entreprise</div>
                            <div class="bg-purple border-radius-lg p-1 text-bold text-white w-100"
                                style="margin-top: -2px; font-weight: 700;">

                                <vSelect style="color:white" label="title" @search="getEntreprises"
                                    :options="entreprises" :filterable="false" :clearable="false"
                                    placeholder="Choisir une entreprise" v-model="entreprise"
                                    @update:modelValue="onEntrepriseChange" class="style-chooser-purple">
                                    <template v-slot:no-options>Aucune entreprise trouvée.
                                        <button class="btn btn-xs btn-white mb-0"
                                            @click="showModalAddEntreprise = true"><i class="fa fa-plus"></i>Rechercher
                                            sur l'INSEE</button>

                                    </template>
                                    <template #option="{ denominationUniteLegale, nomUniteLegale, naf, siret }">
                                        <div>{{ denominationUniteLegale }} {{ nomUniteLegale }}
                                            <a v-if="naf?.libelle" style="font-size:8pt"> {{ naf.libelle }}</a>
                                            <a v-if="siret" style="font-size:8pt"> SIRET = {{ siret }}</a>
                                        </div>
                                    </template>
                                </vSelect>
                            </div>
                            <el-tooltip content="Rechercher sur l'INSEE" placement="left">
                            <button class="btn btn-xs btn-white mb-0"  @click="showModalAddEntreprise = true"><i
                                    class="fa-solid fa-arrow-up-right-from-square"></i></button>
                            </el-tooltip>

                        </div>
                        <div class="bg-primary border-radius-lg p-2 " v-if="etablissements.length > 1">
                            <div style="display: flex;">
                                <div class="bg-primary border-radius-lg p-2 text-white text-xs pr-3">
                                    Établissement</div>
                                <div class="bg-primary border-radius-lg p-1 text-bold text-white text-sm w-100"
                                    style="margin-top: -2px; font-weight: 500;">

                                    <vSelect style="color:white" label="title" :options="etablissements"
                                        :clearable="false" placeholder="Choisir un établissement"
                                        v-model="etablissement" class="style-chooser-blue">
                                        <template v-slot:no-options>Aucune entreprise trouvée.</template>
                                        <template
                                            #option="{ typeVoieEtablissement, libelleVoieEtablissement, codePostalEtablissement, libelleCommuneEtablissement }">
                                            <div>{{ typeVoieEtablissement }} {{ libelleVoieEtablissement }} {{codePostalEtablissement }} {{ libelleCommuneEtablissement }}
                                            </div>
                                        </template>
                                    </vSelect>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bg-purple border-radius-lg p-2 " v-if="mode_contrat === 'avec'">
                        <div style="display: flex;">
                            <div class="bg-purple border-radius-lg p-2 text-white text-sm pr-3">
                                Contrat</div>
                            <div class="bg-purple border-radius-lg p-1 text-bold text-white w-100"
                                style="margin-top: -2px; font-weight: 700;">

                                <vSelect style="color:white" :options="contrats" label="title" :clearable="false"
                                    placeholder="Choisir un contrat" v-model="contrat"
                                    @update:modelValue="onContratChange" class="style-chooser-purple">
                                    <template v-slot:no-options>Aucune entreprise trouvée.</template>
                                    <template #option="{ title, naf, ext_fournisseur }">
                                        <div>{{ title }}
                                            <a v-if="naf" style="font-size:8pt"> {{ naf }}</a>
                                            <a v-if="ext_fournisseur?.syndic_fournisseur" style="font-size:8pt"> SIRET =
                                                {{ ext_fournisseur?.syndic_fournisseur.fournisseur.siret }}</a>
                                        </div>
                                    </template>
                                </vSelect>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="bg-green border-radius-lg p-2 mb-2">
                    <div style="display: flex;" class="mb-2">
                        <div class="bg-green border-radius-lg p-2 text-dark text-sm pr-3">Moyen d'accès</div>

                        <div class="btn-group">
                            <button class="btn btn-light btn-xs mb-0"
                                :style="mode_moyen_acces === 'resident' ? 'color: white; background-color: rgb(94, 114, 228);' : ''"
                                @click="mode_moyen_acces = 'resident'">
                                Résident ou tiers
                            </button>
                            <button class="btn btn-light btn-xs mb-0" v-if="gardiens.length > 0"
                                :style="mode_moyen_acces === 'gardien' ? 'color: white; background-color: rgb(94, 114, 228);' : ''"
                                @click="mode_moyen_acces = 'gardien'"> Gardien</button>
                            <button class="btn btn-light btn-xs mb-0"
                                :style="mode_moyen_acces === 'clef_codes' ? 'color: white; background-color: rgb(94, 114, 228);' : ''"
                                @click="mode_moyen_acces = 'clef_codes'">
                                Clefs & Codes</button>
                            <button class="btn btn-light btn-xs mb-0"
                                :style="mode_moyen_acces === 'libre' ? 'color: white; background-color: rgb(94, 114, 228);' : ''"
                                @click="mode_moyen_acces = 'libre'">
                                Texte Libre</button>

                        </div>

                    </div>

                    <div class="bg-dark-turquoise border-radius-lg p-2 ">
                        <div style="display: flex;" v-if="mode_moyen_acces == 'resident'">
                            <div class="bg-dark-turquoise border-radius-lg p-2 text-white text-sm pr-3">Résident</div>

                            <div class="bg-dark-turquoise border-radius-lg p-1 text-bold text-dark w-100"
                                style="margin-top: -2px; font-weight: 700;">

                                <vSelect style="color:white" :options="cops" label="title" :clearable="false"
                                    placeholder="Choisir un résident" v-model="acces_resident"
                                    class="style-chooser-dark-turquoise">
                                    <template v-slot:no-options>Aucun résident trouvé.</template>
                                    <template #option="{ title, locataire, lots }">
                                        <div :style="locataire ? 'background-color:rgb(255, 135, 0)' : ''">{{ title }}
                                            <a v-if="locataire && lots[0]" style="font-size:8pt">PROP : {{lots[0].perso_mini.nom }}</a>
                                        </div>
                                    </template>
                                </vSelect>
                            </div>

                            <el-tooltip content="Choisir un autre résident ou un tiers" placement="left">
                                <button class="btn btn-xs btn-white mb-0" @click="showModalDeclarant = true"><i
                                        class="fa fa-users"></i></button>
                            </el-tooltip>
                        </div>
                        <div style="display: flex;" v-if="mode_moyen_acces == 'libre'">
                            <textarea class="form-control" v-model="moyen_libre_os" placeholder="Texte libre pour cet OS"></textarea>
                        </div>
                        <div v-if="mode_moyen_acces == 'gardien'">
                            <vSelect style="color:white" :options="gardiens" label="title" :clearable="false"
                                placeholder="Choisir un contrat" v-model="gardienVal"
                                @update:modelValue="onGardienChange" class="style-chooser-dark-turquoise">
                                <template v-slot:no-options>Aucun gardien trouvé.</template>
                                <template #option="{ title, naf, siret }">
                                    <div>{{ title }}
                                        <a v-if="naf" style="font-size:8pt"> {{ naf }}</a>
                                        <a v-if="siret" style="font-size:8pt"> SIRET = {{ siret }}</a>
                                    </div>
                                </template>
                            </vSelect>
                        </div>

                        <div v-if="mode_moyen_acces == 'clef_codes'"
                            :style="clefs.length == 0 ? 'flex-direction: column-reverse;display: flex;' : ''">


                            <div v-if="clefs.length == 0" class="text-center">
                                <button class="btn btn-xs btn-white mb-0" @click="showModalCodes = true"><i
                                        class="fa fa-plus"></i> Ajouter des codes ou des clefs</button>

                            </div>
                            <div v-else style="display:flex">

                                <div class="bg-dark-turquoise border-radius-lg p-2 text-white text-sm pr-3">Clef/Codes
                                </div>

                                <div class="bg-dark-turquoise border-radius-lg p-1 text-bold text-dark w-100"
                                    style="margin-top: -2px; font-weight: 700;">

                                    <vSelect style="color:white" :options="clefs" label="title" :clearable="false"
                                        multiple placeholder="Choisir un moyen d'accès" v-model="clef"
                                        class="style-chooser-dark-turquoise">
                                        <template v-slot:no-options>Aucun résident trouvé.</template>
                                        <template #option="{ title }">{{ title }}
                                        </template>
                                    </vSelect>
                                </div>

                                <el-tooltip content="Ajouter d'autres moyens" placement="left">
                                    <button class="btn btn-xs btn-white mb-0" @click="showModalCodes = true"><i
                                            class="fa fa-key"></i></button>
                                </el-tooltip>
                            </div>

                        </div>

                    </div>



                </div>

            </div>

        </div>
        <div class="card card-sm p-2" v-else-if="page ==2">
            <div class="d-flex mb-1">

            <span class="badge bg-warning border-radius-lg" v-if="mode=='consigner'">Consigner</span>
            <span class="badge bg-warning border-radius-lg" v-else-if="mode=='intervention'">Intervenir
                <span class="badge bg-dark border-radius-lg" v-if="submode=='demande_devis'"> Demande de devis </span>
                <span class="badge bg-dark border-radius-lg" v-else-if="submode=='demande_intervention'"> Demande d'intervention </span>
                <span class="badge bg-dark border-radius-lg" v-else-if="submode=='sinistre'"> Sinistre </span>
            </span>
            <span class="badge bg-warning" v-else-if="mode=='communication'">Communiquer</span>

            &nbsp;
            <span class="badge bg-danger border-radius-lg">{{ immeuble.nom }}
                <span class="badge bg-yellow border-radius-lg text-dark" >{{ resident.title }}</span>
            </span>
            </div>

            <div class="bg-light border-radius-lg p-2 mb-2">
                    <div style="display: flex;" class="mb-2">
                        <div class="bg-light border-radius-lg p-2 text-dark text-sm pr-3">Sur site, vous agirez</div>

                        <div class="btn-group">
                            <button class="btn btn-light btn-xs mb-0"
                                :style="mode_action_sur_site === 'parties_privatives' ? 'color: white; background-color: rgb(94, 114, 228);' : ''"
                                @click="mode_action_sur_site = 'parties_privatives'">
                                Parties Privatives</button>
                            <button class="btn btn-light btn-xs mb-0"
                                :style="mode_action_sur_site === 'parties_communes' ? 'color: white; background-color: rgb(94, 114, 228);' : ''"
                                @click="mode_action_sur_site = 'parties_communes'">
                                Parties Communes
                            </button>
                            <button class="btn btn-light btn-xs mb-0"
                                :style="mode_action_sur_site === 'les_deux' ? 'color: white; background-color: rgb(94, 114, 228);' : ''"
                                @click="mode_action_sur_site = 'les_deux'">
                                Parties Communes & Privatives</button>

                        </div>

                    </div>
                </div>

            <div class="bg-light border-radius-lg p-2 mb-2">
                <div class="">
                    <div class="bg-light border-radius-lg p-2 text-dark text-sm pr-3">Message pour le fournisseur</div>

            <input class="form-control mb-2" placeholder="Titre" v-model="titre" />
                    <Editor v-model="message" :init="{
                        plugins: 'lists link image table code help wordcount',
                        promotion:false,
                        branding: false,
                        language:'fr_FR',
                        height: 'calc(100vh - 500px)',
                        menubar: false,

                    }"  />
                <DropZone
                    :modelValue="fichiers"
                    :options="{prop:'files'}"
                    name="Fichiers"
                    multiple="control.isMultiple"
                    >
                </DropZone>
                </div>
            </div>


        </div>
        <div class="card card-sm p-2" v-else-if="page ==3">
            <h4>Interlocuteurs </h4>



        </div>
        <div class="card card-sm p-2" v-else-if="page =4">
            <PDFView v-model="pdf" :options="pdf_options" />
        </div>



        <div class="card card-sm p-2" v-else-if="page ==5">
            <h2>Et maintenant ?</h2>
            <button class="btn btn-success" @click="valider">Demander un devis à un autre fournisseur</button>

        </div>

    </Transition>

        <div>

            <div class="p-2 d-flex">
                <button class="btn"
                @click="page =page-1"
v-if="page !=1"
                :class="isSuivantPossible?'btn-success':''">Retour <i
                        class="fa fa-arrow-left"></i></button>
                <div style="flex:100%"></div>
                <button class="btn"
                @click="page =page+1"

                :class="isSuivantPossible?'btn-success':''">Suivant <i
                        class="fa fa-arrow-right"></i></button>
            </div>

        </div>
    </div>

</template>
<style>
@import "vue-select/dist/vue-select.css";
</style>
<style>
.style-chooser-gray .vs__search::placeholder,
.style-chooser-gray .vs__dropdown-toggle,
.style-chooser-gray .vs__dropdown-menu {
    font-size: 12pt;
    background: #6c757d;
    border: none;
    color: #ffffff;
}

.style-chooser-gray .vs__clear,
.style-chooser-gray .vs__open-indicator {
    fill: whitesmoke;


}

.style-chooser-gray .vs__clear,
.style-chooser-gray .vs__selected {
    fill: whitesmoke;
    color: white;
    background-color: var(--el-text-color-secondary);

}

.bg-light-purple {
    background-color: #ae99d5;
}

.style-chooser-yellow .vs__search::placeholder,
.style-chooser-yellow .vs__dropdown-toggle,
.style-chooser-yellow .vs__dropdown-menu {
    background: #FBD38D !important;
    border: none;
    color: #343434;
}

.style-chooser-yellow .vs__clear,
.style-chooser-yellow .vs__open-indicator {
    fill: rgb(45, 45, 45);


}

.style-chooser-yellow .vs__clear,
.style-chooser-yellow .vs__selected {
    fill: rgb(42, 41, 41);
    color: rgb(1, 1, 1);
    background: #FBD38D !important;

}




.style-chooser-orange .vs__search::placeholder,
.style-chooser-orange .vs__dropdown-toggle,
.style-chooser-orange .vs__dropdown-menu {
    background: rgb(253, 126, 20) !important;
    border: none;
    color: #ffffff;
}

.style-chooser-orange .vs__clear,
.style-chooser-orange .vs__open-indicator {
    fill: rgb(204, 203, 203);


}

.style-chooser-orange .vs__clear,
.style-chooser-orange .vs__selected {
    fill: rgb(42, 41, 41);
    color: rgb(255, 255, 255);
    background-color: #6f42c1;

}


.style-chooser-purple .vs__search::placeholder,
.style-chooser-purple .vs__dropdown-toggle,
.style-chooser-purple .vs__dropdown-menu {
    background: #6f42c1 !important;
    border: none;
    color: #ffffff;
    z-index: 5;
}

.style-chooser-purple .vs__clear,
.style-chooser-purple .vs__open-indicator {
    fill: rgb(204, 203, 203);


}

.style-chooser-purple .vs__clear,
.style-chooser-purple .vs__selected {
    fill: rgb(42, 41, 41);
    color: rgb(255, 255, 255);
    background-color: #11cdef;

}


.style-chooser-light-purple .vs__search::placeholder,
.style-chooser-light-purple .vs__dropdown-toggle,
.style-chooser-light-purple .vs__dropdown-menu {
    background: #ae99d5 !important;
    border: none;
    color: #ffffff;
}

.style-chooser-light-purple .vs__clear,
.style-chooser-light-purple .vs__open-indicator {
    fill: rgb(204, 203, 203);


}

.style-chooser-light-purple .vs__clear,
.style-chooser-light-purple .vs__selected {
    fill: rgb(42, 41, 41);
    color: rgb(255, 255, 255);
    background-color: #ae99d5;

}


.style-chooser-light-blue .vs__search::placeholder,
.style-chooser-light-blue .vs__dropdown-toggle,
.style-chooser-light-blue .vs__dropdown-menu {
    background: #11cdef !important;
    border: none;
    color: rgb(0, 0, 0);
}

.style-chooser-light-blue .vs__clear,
.style-chooser-light-blue .vs__open-indicator {
    fill: rgb(204, 203, 203);


}

.style-chooser-light-blue .vs__clear,
.style-chooser-light-blue .vs__selected {
    fill: rgb(0, 0, 0);
    color: rgb(28, 28, 28);
    background-color: #11cdef;

}


.style-chooser-dark-turquoise .vs__search::placeholder,
.style-chooser-dark-turquoise .vs__dropdown-toggle,
.style-chooser-dark-turquoise .vs__dropdown-menu {
    background: #1a9593 !important;
    border: none;
    color: rgb(255, 255, 255);
}

.style-chooser-dark-turquoise .vs__clear,
.style-chooser-dark-turquoise .vs__open-indicator {
    fill: #1a9593
}

.style-chooser-dark-turquoise .vs__clear,
.style-chooser-dark-turquoise .vs__selected {
    fill: #1a9593;
    color: rgb(255, 255, 255);
    background-color: #1a9593;

}

.bg-dark-turquoise {
    background-color: #1a9593;
}




.style-chooser-blue .vs__search::placeholder,
.style-chooser-blue .vs__dropdown-toggle,
.style-chooser-blue .vs__dropdown-menu {
    background: #5e72e4 !important;
    border: none;
    color: rgb(255, 255, 255);
}

.style-chooser-blue .vs__clear,
.style-chooser-blue .vs__open-indicator {
    fill: rgb(204, 203, 203);


}

.style-chooser-blue .vs__clear,
.style-chooser-blue .vs__selected {
    fill: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    background-color: #5e72e4;

}

.fade-enter-active .card,
.fade-leave-active .card {
    transition: all 0.25s ease-out;
}




.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.25s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}


.fade-enter-from .card {
    opacity: 0;
    transform: translateY(30px);
}

.fade-leave-to .card {
    opacity: 0;
    transform: translateY(-30px);
}

.ellipsis {
    position: relative;
}

.ellipsis:before {
    content: '&nbsp;';
    visibility: hidden;
}

.ellipsis span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}


.search-results {
    left: 0;
    right: 0;
    background: white;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    max-height: 200px;
    overflow-y: auto;
}

.search-results li {
    padding: 8px 12px;
    cursor: pointer;
}

.search-results li:hover {
    background-color: #f0f0f0;
}
</style>
