<template>
    <div class="" @mouseenter="showAllSuppliers = true" @mouseleave="showAllSuppliers = false">
      <mini-statistics-card
        @click="openAdresse()"
        class="bg-white mb-0"
        :title="{ text: 'Adresse', color: 'opacity-7 text-dark' }"
        :value="{ text: formatAddress(sortedSuppliers[0]), color: 'text-dark' }"
        :icon="{
          component: 'fas fa-location-arrow text-white',
          background: 'bg-success',
          shape: 'rounded-circle',
        }"
      />

      <div v-if="showAllSuppliers && sortedSuppliers.length > 1" class="supplier-list p-3 bg-white card border-radius-md" style="position:absolute;z-index: 100;margin-top: -25px;width:25%">
            <p
              class="mb-0 text-sm text-uppercase font-weight-bold"
            >
              Adresses
            </p>
        <div class="suppliers-scroll">
          <div v-for="(fournisseur, index) in sortedSuppliers.slice(1)" :key="index" class="mt-2 text-sm">
            {{ formatAddress(fournisseur) }}
            <span class="text-xs text-muted" @click="openAdresse(fournisseur)">{{ formatDistance(fournisseur.distance) }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import MiniStatisticsCard from '@/components/Cards/MiniStatisticsCard.vue'

  export default {
    name: 'SupplierAddressCard',
    components: {
      MiniStatisticsCard
    },
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        showAllSuppliers: false,
        agenceCoords: null
      }
    },
    computed: {
      sortedSuppliers() {
        if (!this.agenceCoords) return this.item.fournisseurs.filter(el => el.etatAdministratifEtablissement == "A")

        return [...this.item.fournisseurs].filter(el => el.etatAdministratifEtablissement == "A").sort((a, b) => {
          const distA = this.calculateDistance(
            this.agenceCoords.lat,
            this.agenceCoords.lng,
            a.latitude,
            a.longitude
          )
          const distB = this.calculateDistance(
            this.agenceCoords.lat,
            this.agenceCoords.lng,
            b.latitude,
            b.longitude
          )
          a.distance = distA
          b.distance = distB
          return distA - distB
        })
      }
    },
    created() {
      const agenceData = localStorage.getItem('agence')
      if (agenceData) {
        this.agenceCoords = JSON.parse(agenceData)
      }
    },
    methods: {
      calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371 // Rayon de la Terre en km
        const dLat = this.deg2rad(lat2 - lat1)
        const dLon = this.deg2rad(lon2 - lon1)
        const a =
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon/2) * Math.sin(dLon/2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
        return R * c
      },
      deg2rad(deg) {
        return deg * (Math.PI/180)
      },
    formatAddress(fournisseur) {

        if (!fournisseur) {
    return '' // ou une valeur par défaut
  }
      const parts = [
        fournisseur.numeroVoieEtablissement,
        fournisseur.indiceRepetitionEtablissement,
        fournisseur.typeVoieEtablissement,
        fournisseur.libelleVoieEtablissement,
        fournisseur.codePostalEtablissement,
        fournisseur.libelleCommuneEtablissement
      ]
      return parts.filter(part => part).join(' ').trim()
    },
      formatDistance(distance) {
        return `(${distance.toFixed(1)} km)`
      },
      openAdresse() {
        this.$emit('open-address')
      }
    }
  }
  </script>

  <style scoped>
  .supplier-list {
    border-top: 1px solid #eee;
  }
  .suppliers-scroll {
    max-height: 50vh;
    overflow-y: auto;
    scrollbar-width: thin;
  }
  .suppliers-scroll::-webkit-scrollbar {
    width: 6px;
  }
  .suppliers-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .suppliers-scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  </style>
