<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ArgonInput from '../../../components/ArgonInput.vue';
import MiniStatisticsCard from '../../../components/Cards/MiniStatisticsCard.vue';
import BaseHeader from "../../../components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import Modal from '@/components/Modal.vue';
import Loading from '@/components/Loading.vue';
import AjoutAttributImmeuble from "./AjoutAttributImmeuble.vue";
import EditionAttributImmeuble from "./EditionAttributImmeuble.vue";
import AjoutEvenementImmeuble from "./AjoutEvenementImmeuble.vue";
import { GoogleMap } from 'vue3-google-map';


const exportToSpreadsheet = (data, fileName) => {
    console.log('EXPORT', data, fileName);

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
  const workSheet = XLSX.utils.aoa_to_sheet(data);
  const workBook = {
    Sheets: { data: workSheet, cols: [] },
    SheetNames: ["data"],
  };
  const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  const fileData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(fileData, fileName + fileExtension);
};






const store = useStore();
const router = useRouter();
const route = useRoute();

const nb_etages = ref(0);
const lotTypes = ref([]);
const selectedLotType = ref('all');
const floors = ref([]);
const selectedFloor = ref('all');
const batiments = ref([]);
const selectedBatiment = ref('all');

const tantiemes = ref([]);
const selectedTantieme = ref('all');

const cops = ref([]);
const historique_cops = ref([]);
const affichageLots = ref(false);
const mapRef = ref(null);
const mainLoading = ref(true);
const contratsLoading = ref(true);
const residentsLoading = ref(true);
const moreOnBuildingLoading = ref(true);
const rights = ref([]);
const type_historique = ref('ventes');
const modals = ref({
    historique: {
        show: false,
        item: {}
    },
    near_building: {
        show: false,
        loading: false,
        item: {},
        item2: {}
    },
    near_building_detail: {
        show: false,
        loading: false,
        item: {},
        item2: {}
    },
    equipe: {
        show: false,
        item: {}
    },
    contrat: {
        show: false,
        item: {}
    },
    contrats: {
        show: false,
        item: {}
    },
    residents: {
        show: false,
        f: []
    },
    locataires: {
        show: false,
        f: []
    },
    edit_attributs: {
        show: false,
        id: route.params.id,
        f: [],
        name: '',
        value: '',
        fichiers: '',
        attr_type: '',
        codes: []
    },
    attributs: {
        show: false,
        id: route.params.id,
        f: [],
        codes: []
    },
    new_event: {
        show: false,
        item: {}
    },
});

const searchcop = ref('');
const residents = ref('all');
const resident_search_placeholder = ref('Rechercher un résident');
const evenements_type = ref("actuels");
const cs_or_mandats = ref("cs");
const attributs_or_contrats = ref('attributs');
const searchcontrat = ref('');
const item = ref({
    attributes: []
});
/*
const options = ref({
  sort: "created_at",
  query: "",
  nr: "1",
  perpage: "5"
});*/

const filterContrat = computed(() => {
    return item.value.contrats.filter(
        (data) => data.date_fin_contrat === null && (
            !searchcontrat.value ||
            data.ext_fournisseur.nom.toLowerCase().includes(searchcontrat.value.toLowerCase())
        )
     );
});
const filterCops = computed(() => {
    return cops.value.map(cop => {
        // Filter lots based on selected criteria
        const filteredLots = (cop.lots || []).filter(lot =>
            (selectedLotType.value === 'all' || lot.typedescr?.texte === selectedLotType.value) &&
            (selectedFloor.value === 'all' || lot.etage === selectedFloor.value) &&
            (selectedBatiment.value === 'all' || lot.bat === selectedBatiment.value) &&
            (selectedTantieme.value === 'all' || lot[selectedTantieme.value] > 0)
        );

        // Calculate sum of tant_1 for filtered lots
        const sumTantiemes = filteredLots.reduce((sum, lot) => sum + (parseFloat(lot.tant_1) || 0), 0);
        const sumTantiemesSelect = filteredLots.reduce((sum, lot) => sum + (parseFloat(lot[selectedTantieme.value]) || 0), 0);

        // If it's a locataire, handle differently
        if (cop.bail) {
            const bailFilteredLots = cop.bail.lots.filter(lot =>
                (selectedLotType.value === 'all' || lot.typedescr?.texte === selectedLotType.value) &&
                (selectedFloor.value === 'all' || lot.etage === selectedFloor.value) &&
                (selectedBatiment.value === 'all' || lot.bat === selectedBatiment.value)
            );

            if (bailFilteredLots.length === 0) return null;

            return {
                ...cop,
                lots: bailFilteredLots,
                sumTantiemes: 0,// Locataires don't have tantiemes
                sumTantiemesSelect: 0
            };
        }

        // If no lots match the filter, return null
        if (filteredLots.length === 0) return null;

        return {
            ...cop,
            lots: filteredLots,
            sumTantiemes,
            sumTantiemesSelect
        };
    }).filter(cop =>
        cop !== null &&
        ((!searchcop.value && residents.value === 'all') ||
        (!searchcop.value && residents.value === 'cop' && cop.qualite === undefined) ||
        (!searchcop.value && residents.value === 'loc' && cop.qualite) ||
        (cop.perso_mini.nom.toLowerCase().includes(searchcop.value.toLowerCase()) && residents.value === 'all') ||
        (cop.perso_mini.nom.toLowerCase().includes(searchcop.value.toLowerCase()) && cop.qualite === undefined && residents.value === 'cop') ||
        (cop.perso_mini.nom.toLowerCase().includes(searchcop.value.toLowerCase()) && cop.qualite !== undefined && residents.value === 'loc'))
    ).sort((a, b) => a.perso_mini.nom.localeCompare(b.perso_mini.nom));
});



const filterHistoriqueCops = computed(() => {
    return historique_cops.value.map(cop => {
        // Filter lots based on selected criteria
        const filteredLots = (cop.lots || []).filter(lot =>
            (selectedLotType.value === 'all' || lot.typedescr?.texte === selectedLotType.value) &&
            (selectedFloor.value === 'all' || lot.etage === selectedFloor.value) &&
            (selectedBatiment.value === 'all' || lot.bat === selectedBatiment.value)
        );


        // If it's a locataire, handle differently
        if (cop.bail) {
            const bailFilteredLots = cop.bail.lots.filter(lot =>
                (selectedLotType.value === 'all' || lot.typedescr?.texte === selectedLotType.value) &&
                (selectedFloor.value === 'all' || lot.etage === selectedFloor.value) &&
                (selectedBatiment.value === 'all' || lot.bat === selectedBatiment.value)
            );

            if (bailFilteredLots.length === 0) return null;

            return {
                ...cop,
                lots: bailFilteredLots
            };
        }

        // If no lots match the filter, return null
        if (filteredLots.length === 0) return null;

        return {
            ...cop,
            lots: filteredLots
        };
    }).filter(cop =>
        cop !== null &&
        (

        (!searchcop.value && type_historique.value === 'ventes' && cop.bail === undefined) ||
        (!searchcop.value && type_historique.value === 'baux' && cop.bail) ||
        (cop.perso_mini.nom.toLowerCase().includes(searchcop.value.toLowerCase()) && cop.bail === undefined && type_historique.value === 'ventes') ||
        (cop.perso_mini.nom.toLowerCase().includes(searchcop.value.toLowerCase()) && cop.bail !== undefined && type_historique.value === 'baux'))
    ).sort(
        (a, b) => {
            if(type_historique.value === 'ventes'){
                return new Date(b.date_vente) - new Date(a.date_vente)
            } else {
                return new Date(b.bailinfo.date_fin_bail_effective) - new Date(a.bailinfo.date_fin_bail_effective)
            }

        }
    );
});



const populateLotTypesAndFloors = () => {
    const types = new Set();
    const floorSet = new Set();
    const batimentSet = new Set();
    const tantiemesSet = new Set();

    // item.tantiemes
    /*batiment: 0

batiment_texte: ""

id: 182

immeuble_id: 1184

syndic_id: 1

tant_1: "CHARGES COMMUNES GENERAL\u0000"

tant_2: "CHARGES BAT A : 11-13-15\u0000"
*/
const tantieme = item.value.tantiemes
        for (const key in tantieme) {
            if (key.startsWith('tant_')) {
                tantiemesSet.add({id:key,val:tantieme[key]});
            }
        }

        console.log('tantiemesSet',tantiemesSet)
    console.log(cops.value)
    cops.value.forEach(cop => {
        if (cop.lots === undefined) return;
        cop.lots.forEach(lot => {
            types.add(lot.typedescr.texte);
            floorSet.add(lot.etage);
            batimentSet.add(lot.bat);
        });
    });

    lotTypes.value = Array.from(types);
    floors.value = Array.from(floorSet).sort((a, b) => a - b);
    batiments.value = Array.from(batimentSet).sort((a, b) => a - b);
    tantiemes.value = Array.from(tantiemesSet);

    // get max floor number (get only numbered floors)
    const maxFloor = Math.max(...floors.value.filter(f => !isNaN(f)));
    nb_etages.value = maxFloor;

};

watch(() => mapRef.value?.ready, (ready) => {
    if (!ready) return;
    console.log('MAPREADY', mapRef.value);
});
// watch if the map is ready, the data is ready and the popup is opened
watch([() => mapRef.value?.ready, () => item.value.more_rnc, () => modals.value.near_building.show], ([ready, data, show]) => {
    if (!ready || !data || !show) return;
    console.log('MAPREADY_FULL', mapRef.value);
    // create a new polygon
    const p = JSON.parse(data?.parcelle?.geo_parcelle);
    const b = JSON.parse(data?.batiment_groupe?.geo_groupe);
    const fp = {
        "type": "FeatureCollection",
        "features": [
            {
                "type": "Feature",
                "properties": {
                    "letter": "G",
                    "color": "blue",
                    "rank": "7",
                    "ascii": "71",
                    "fillColor": "#ff2600"
                },
                "geometry": p
            },
            {
                "type": "Feature",

                "properties": {
                    "letter": "G",
                    "color": "red",
                    "rank": "7",
                    "ascii": "71",
                    "fillColor": "#555555"
                },
                "geometry": b
            }
        ]
    };

    console.log('HERE', fp);
    mapRef.value.map.data.addGeoJson(fp);

    mapRef.value.map.data.setStyle(function (feature) {
        var color = feature.getProperty('color');
        return {
            fillColor: color,
            strokeWeight: 1
        }
    });
    /*
            const p2 = new mapRef.value.api.
            p2.setMap(mapRef.value.map);*/


});

onMounted(async () => {
    setNavPills();

    let val = await store.getters["profile/me"];
    rights.value = val.roles.map((r) => r);
    if (val.info.access !== null) {
        let tmp_rights = val.info.access.map((r) => r);
        if (tmp_rights.length > 0) {
            tmp_rights.forEach((r) => {
                JSON.parse(r.acces_text).forEach((r) => {
                    if (!rights.value.includes(r))
                        rights.value.push(r);
                });
            });
        } else {
            rights.value = ["immeubles", "résidents", "fournisseurs", "mails", "évènements"];
        }
    }

    await store.dispatch("immeuble/getImmeubleMini", route.params.id);
    item.value = await store.getters["immeuble/oneImmeubleMini"];
    console.log(item.value);

    mainLoading.value = false;
    getFullImmeuble();
});


const getFullImmeuble = async () => {
    await store.dispatch("immeuble/getImmeuble", route.params.id);
    item.value = await store.getters["immeuble/oneImmeuble"];

    item.value.contrats = item.value.contrats.sort((a, b) => {
        if (a.ext_fournisseur.nom < b.ext_fournisseur.nom) return -1;
        if (a.ext_fournisseur.nom > b.ext_fournisseur.nom) return 1;
        return 0;
    }).sort((a, b) => {
        if (a.ext_fournisseur_type < b.ext_fournisseur_type) return -1;
        if (a.ext_fournisseur_type > b.ext_fournisseur_type) return 1;
        return 0;
    }).sort((a) => {
        if (a.date_fin_contrat !== null ) return 0;
        return -1;
    });

    contratsLoading.value = false;
/*
    item.value.coplots_actifs.forEach(element => {
        let s = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === element.EXTAPI_PERSO_ID);
        if (element.perso_mini) {
            element.perso_mini.km = getDistanceFromLatLonInKm(element.perso_mini.lat, element.perso_mini.lng, item.value.lat, item.value.lng);
        }
        if (s === -1) {
            if (element.date_vente === null) {
                if (element.lots === undefined) {
                    element.lots = [];
                }
                cops.value.push(element);
            }
        } else {
            if (element.date_vente === null) {
                cops.value[s].lots.push(element.lots[0]);
            }
        }
        element.lots.forEach(lot => {
            lot.baux_actifs.forEach(tbail => {
                tbail.ext_locataire.forEach(loca => {
                    let s2 = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID);
                    if (s2 === -1) {
                        let lt = { ...loca, bail: { lots: [{ ...lot, perso_mini: element.perso_mini }] } };
                        cops.value.push(lt);
                    }
                });
            });
        });
    });*/
    item.value.lots.forEach(element => {
        let s = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === element.EXTAPI_PERSO_ID);
        if (element) {
            element.perso_mini.km = getDistanceFromLatLonInKm(element.perso_mini.lat, element.perso_mini.lng, item.value.lat, item.value.lng);

        }
        if (s === -1) {
            let elnew = {  bail: null, EXTAPI_IMM_ID: element.EXTAPI_IMM_ID, perso_mini: element.perso_mini, lots: [element], EXTAPI_PERSO_ID: element.EXTAPI_PERSO_ID };

            elnew.locations = [];
            if(element.locataires.length > 0){
                    element.locataires.forEach(locataire => {
                        if(locataire.bail_actif){
                            locataire.bail_actif.ext_locataire.forEach(loca => {
                                if(elnew.locations.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID) === -1){

                                    elnew.locations.push(loca)
                                }
                            });
                        }
                    });
            }
            elnew.perso_mini.km = element.perso_mini.km;
            cops.value.push(elnew);
        } else {

            if(element.locataires.length > 0){
                    element.locataires.forEach(locataire => {
                        if(locataire.bail_actif){
                            locataire.bail_actif.ext_locataire.forEach(loca => {
                                if(cops.value[s].locations.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID) === -1){
                                    cops.value[s].locations.push(loca)
                                }
                            });
                        }
                    });
            }
            cops.value[s].perso_mini.km = element.perso_mini.km;
            // if  cops.value[s].lots is not an array, make it an array
            if (Array.isArray(cops.value[s].lots)) {
                //cops.value[s].lots = [];
                cops.value[s].lots.push(element);
            } else {
                console.log('NOT AN ARRAY', element);
            }


        }

        element.locataires.forEach(locataire =>
        {
            if(locataire.bail_actif){
                locataire.bail_actif.ext_locataire.forEach(loca => {
                    let s2 = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID);
                    if (s2 === -1) {
                        let lt = { ...loca, bail: { lots: [{ ...element, perso_mini: element.perso_mini }] } };
                        cops.value.push(lt);
                    }
                });


            }
        }
        )
        /*element.lots.forEach(lot => {
            lot.baux_actifs.forEach(tbail => {
                tbail.ext_locataire.forEach(loca => {
                    let s2 = cops.value.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID);
                    if (s2 === -1) {
                        let lt = { ...loca, bail: { lots: [{ ...lot, perso_mini: element.perso_mini }] } };
                        cops.value.push(lt);
                    }
                });
            });
        });*/
    });
    console.log(cops.value);
    residentsLoading.value = false;
    populateLotTypesAndFloors();

    item.value.mandats.forEach((mandat) => {
        let s = cops.value.find(el => el.EXTAPI_PERSO_ID === mandat.EXTAPI_PERSO_ID);
        if (s !== undefined) {
            mandat.cop = s;
        }
    });
    item.value.mandats = item.value.mandats.filter((mandat) => mandat.date_fin_effet === null);

    getHistorique();
    fetch('https://building.mycopro.fr/conf.php?immat=' + item.value.rnc.NumeroImmatriculation)
        .then(response => response.json())
        .then(data => {
            if (data?.parcelle?.geo_parcelle) {
                item.value.more_rnc = data;
                console.log('SHOULD HERE', mapRef.value);
                const p = JSON.parse(data?.parcelle?.geo_parcelle);
                console.log('phere', p);
                moreOnBuildingLoading.value = false;
            }
        });

    await store.dispatch("immeuble/mailsImmeuble", route.params.id);
    item.value.emails = await store.getters["immeuble/mailsImmeuble"]?.data;
};

const getNear = async () => {
    // if near_building item set, return

    if(modals.value.near_building.item.length > 0){
        return;
    }
    modals.value.near_building.loading = true;
    await store.dispatch("immeuble/nearImmeuble", route.params.id);
    const v = await store.getters["immeuble/nearImmeuble"];
    modals.value.near_building.item = v;
    modals.value.near_building.loading = false;

}


const getHistorique = async () => {
    await store.dispatch("immeuble/getImmeubleHistorique", route.params.id);
    item.value.historique = store.getters["immeuble/oneImmeubleHistorique"];
    console.log(item.value.historique);


    item.value.historique.coplots.forEach(element => {
        let s = historique_cops.value.findIndex(el => el.EXTAPI_PERSO_ID === element.EXTAPI_PERSO_ID);
        /*if (element.perso_mini) {
            element.perso_mini.km = getDistanceFromLatLonInKm(element.perso_mini.lat, element.perso_mini.lng, item.value.lat, item.value.lng);
        }*/
        if (s === -1) {
            if (element.date_vente !== null) {
                if (element.lots === undefined) {
                    element.lots = [];
                } else {
                    element.lots[0].date_vente = element.date_vente;
                }
                historique_cops.value.push(element);
            }
        } else {
            if (element.date_vente !== null) {
                element.lots[0].date_vente = element.date_vente;
                element.lots.forEach(el => {
                    if(historique_cops.value[s].lots.findIndex(l => l.id === el.id) === -1){
                        historique_cops.value[s].lots.push(el);
                    }
                });
                element.lots_sec.forEach(el => {
                    if(historique_cops.value[s].lots.findIndex(l => l.id === el.id) === -1){
                        historique_cops.value[s].lots.push(el);
                    }
                });
            }
        }
        element.lots.forEach(lot => {
            lot.baux.forEach(tbail => {
                let dtmp = new Date(tbail.date_fin_bail_effective)
                if(tbail.date_fin_bail_effective && dtmp < new Date()) {
                tbail.ext_locataire.forEach(loca => {
                    let s2 = historique_cops.value.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID);
                    if (s2 === -1) {
                        let lt = { ...loca, bail: { lots: [{ ...lot, perso_mini: element.perso_mini }] },bailinfo:tbail };
                        historique_cops.value.push(lt);
                    }
                });
                }

            });
        });
    });
};


const goLot = (lot) => {
    router.push({
        name: "Lot",
        params: { id: lot.id }
    });
};

/*
    const onMapReady = (map) => {
      console.log('MAPreadyM', map);
    };
*/
const goMail = (m) => {
    router.push({
        name: "Liste des mails",
        query: { id: m.outlook_id }
    });
};

const openNear = async () => {
    modals.value.near_building.show = true;
    getNear();

};

const downloadCsv = () => {
    var jsonData = [];
    if(affichageLots.value == true){
        jsonData.push(["nom", "prenom", "mail", "tel","adresse1","adresse2","cp","ville", "type", "locataire", "type_lot","num_lot","batiment","escalier","etage","porte"]);
    } else {
        jsonData.push(["nom", "prenom", "mail", "tel","adresse1","adresse2","cp","ville", "type", "locataire"]);

    }
    filterCops.value.forEach((row) => {
        var type = '';
        var lot_texte = '';
        var mail;
        var phone;
        if (row.bail) {
            row.bail.lots.forEach(lot => {
                lot_texte = lot.typedescr.texte + ' de ' + lot.perso_mini.nom + ' ' + lot.perso_mini.prenom;
            });
        } else {
            /*
            row.lots.forEach(lot => {
                lot.baux_actifs.forEach(bail => {
                    bail.ext_locataire.forEach(loc => {
                        lot_texte = loc.perso_mini.nom + ' ' + loc.perso_mini.prenom;
                    });
                });
            });
            row.lots.forEach(lot => {
                lot.bail_actif.ext_locataire.forEach(loc => {
                    lot_texte = loc.perso_mini.nom + ' ' + loc.perso_mini.prenom;
                });
            });*/
            row.locations.forEach(loc => {
                lot_texte = loc.nom + ' ' + loc.prenom;
            });
        }
        if (row.perso_mini.qualite.includes('P') && row.lots.find(l => l.bail_actif)) {
            type = 'BAILLEUR';
        } else if (row.perso_mini.km > 1) {
            type = 'BAILLEUR';
        } else {
            type = 'OCCUPANT';
        }
        if (row.perso_mini.mails.length > 0) {
            mail = row.perso_mini.mails[0].mail;
        } else {
            mail = '';
        }
        if (row.perso_mini.phones.length > 0) {
            phone = row.perso_mini.phones[0].phone;
        } else {
            phone = '';
        }
        var nom = row.perso_mini.nom;
        var prenom = row.perso_mini.prenom;
        if(affichageLots.value == true){
            row.lots.forEach(lot => {
                jsonData.push([nom, prenom, mail, phone, row.perso_mini.adresse1, row.perso_mini.adresse2, row.perso_mini.cp, row.perso_mini.ville, type, lot_texte,lot.typedescr.texte,lot.EXTAPI_LOT_ID,lot.bat,lot.escalier,lot.etage,lot.porte]);
            });
        } else {
            jsonData.push([nom, prenom, mail, phone, row.perso_mini.adresse1, row.perso_mini.adresse2, row.perso_mini.cp, row.perso_mini.ville, type, lot_texte]);
        }
    });
    exportToSpreadsheet(jsonData, 'export');
};


const downloadCsvHistorique = () => {
    var jsonData = [];
    jsonData.push(["nom", "prenom", "mail", "tel", "type", "locataire"]);
    filterHistoriqueCops.value.forEach((row) => {
        var type = '';
        var lot_texte = '';
        var mail;
        var phone;
        if (row.bail) {
            row.bail.lots.forEach(lot => {
                lot_texte = lot.typedescr.texte + ' de ' + lot.perso_mini.nom + ' ' + lot.perso_mini.prenom;
            });
        } else {
            row.lots.forEach(lot => {
                lot.baux_actifs.forEach(bail => {
                    bail.ext_locataire.forEach(loc => {
                        lot_texte = loc.perso_mini.nom + ' ' + loc.perso_mini.prenom;
                    });
                });
            });
        }
        if (row.perso_mini.qualite.includes('P') && row.lots.find(l => l.baux_actifs.find(c => c.ext_locataire))) {
            type = 'BAILLEUR';
        } else if (row.perso_mini.km > 1) {
            type = 'BAILLEUR';
        } else {
            type = 'OCCUPANT';
        }
        if (row.perso_mini.mails.length > 0) {
            mail = row.perso_mini.mails[0].mail;
        } else {
            mail = '';
        }
        if (row.perso_mini.phones.length > 0) {
            phone = row.perso_mini.phones[0].phone;
        } else {
            phone = '';
        }
        var nom = row.perso_mini.nom;
        var prenom = row.perso_mini.prenom;
        jsonData.push([nom, prenom, mail, phone, type, lot_texte]);
    });
    exportToSpreadsheet(jsonData, 'export');
};

const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371;
    var dLat = deg2rad(lat2 - lat1);
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}


const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
}
const newEventImmeuble = () => {
    modals.value.new_event.show = true;
    /*
    console.log(item.value)
    router.push({
        name: "Liste des évènements",
        params: { newEventProp: JSON.stringify({ 'immeuble_id': item.value.id, 'immeuble': item.value }) }
    })*/
}
const goPerso = (id) => {
    router.push({
        name: "Perso",
        params: { id: id },
    });

}
/*
const openAdresse = () => {
window.open('https://www.google.com/maps/search/?api=1&query=' + item.value.adresse1 + ' ' + item.value.adresse2 + ' ' + item.value.cp + ' ' + item.value.ville, '_blank');

}*/

const showChildren = (row) => {
    console.log(row);
    item.value.attributes.forEach(element => {
        if (element.id == row.id) {
            console.log(element);
            element.show_children = !element.show_children;
        }
    });
    // simplifier
    item.value.attributes.push({})
    item.value.attributes.pop()
}

const showAllResidents = () => {
    residents.value = 'all';
    resident_search_placeholder.value = "Rechercher un résident";

}
const showOnlyCops = () => {
    residents.value = 'cop';
    resident_search_placeholder.value = "Rechercher un copropriétaire";

}
const showOnlyLocs = () => {
    residents.value = 'loc';
    resident_search_placeholder.value = "Rechercher un locataire";

}

const changeAttrImmeuble = (row) => {
    modals.value.edit_attributs.show = true;
    modals.value.edit_attributs.immeuble_id = route.params.id;
    modals.value.edit_attributs.id = row.id;
    modals.value.edit_attributs.name = row.name;
    modals.value.edit_attributs.attr_type = row.attr_type;
    console.log(row);
    switch (row.attr_type) {
        case "texte":
            modals.value.edit_attributs.value = JSON.parse(row.value);
            break;
        case "codes":
            modals.value.edit_attributs.codes = JSON.parse(row.value);
            break;
        case "fichiers":
            modals.value.edit_attributs.fichiers = row.fichiers;
            break;
        case "number":
            modals.value.edit_attributs.number = row.value;
            break;
        case "bool":
            modals.value.edit_attributs.bool = row.v
    }
    modals.value.edit_attributs.name = row.name;
}

const isMembreCS = (row) => {
    //if perso in item.membrecs
    if( item.value.membres_cs.find((m) => m.EXTAPI_PERSO_ID === row.perso_mini.EXTAPI_PERSO_ID) !== undefined ) {
        return item.value.membres_cs.find((m) => m.EXTAPI_PERSO_ID === row.perso_mini.EXTAPI_PERSO_ID).membrecs == 1;
    }
    return false;
}
const isPresidentCS = (row) => {
    //if perso in item.membrecs

    if( item.value.membres_cs.find((m) => m.EXTAPI_PERSO_ID === row.perso_mini.EXTAPI_PERSO_ID) !== undefined ) {
        return item.value.membres_cs.find((m) => m.EXTAPI_PERSO_ID === row.perso_mini.EXTAPI_PERSO_ID).membrecs == 2;
    }
    return false;
}

const isMoreThanThreeYearsOld = (date) => {
    const d = new Date(date);
    const now = new Date();
    const diff = now - d;
    const diffDays = diff / (1000 * 60 * 60 * 24);
    return diffDays > 1095;
}

const goFiles = ()=>{
    router.push({
        name: "Racine",
        query: { id: item.value.id }
    });
}


</script>
<template>
    <Transition name="fade">
        <Loading v-if="mainLoading" />
        <div v-else>
            <div class="py-1 container-fluid">
                <Modal v-model="modals.residents.show" closeable header="Résidents">

                <div style="display:flex">

                    <div class="btn-group" role="group" aria-label="Basic example">
                        <base-button size="sm" outline type="secondary" :class="residents == 'all' ? 'active' : ''"
                            @click="showAllResidents">Tous</base-button>
                        <base-button size="sm" outline type="secondary" :class="residents == 'cop' ? 'active' : ''"
                            @click="showOnlyCops">Copropriétaires</base-button>
                        <base-button size="sm" outline type="secondary" :class="residents == 'loc' ? 'active' : ''"
                            @click="showOnlyLocs">Locataires</base-button>
                    </div>
                    &nbsp;
                    <base-button v-if="affichageLots" size="xs" type="warning" @click="affichageLots = false">
                        <i class="fa fa-eye-slash"></i> Masquer les lots
                    </base-button>
                    <base-button v-else size="xs"  type="warning" @click="affichageLots = true">
                        <i class="fa fa-eye"></i> Afficher les lots
                    </base-button>
                    <div style="flex:1"></div>
                    <div style="display:block"><span class="badge badge-primary">Total : {{ filterCops.length }}</span></div>&nbsp;


<base-button size="xs" outline type="primary" @click="downloadCsv"><i
                            class="fa fa-download"></i>Télécharger</base-button>

                </div>
                    <div class="row">
                    <div class="col-12 col-md-3 mb-3">
                        <label for="lotTypeSelect" class="form-label">Type de lot</label>
                        <select id="lotTypeSelect" v-model="selectedLotType" class="form-select">
                            <option value="all">Tous les types</option>
                            <option v-for="type in lotTypes" :key="type" :value="type">
                            {{ type }}
                            </option>
                        </select>
                    </div>

                    <div class="col-12 col-md-3 mb-3">
                        <label for="floorSelect" class="form-label">Étage</label>
                        <select id="floorSelect" v-model="selectedFloor" class="form-select">
                            <option value="all">Tous les étages</option>
                            <option v-for="floor in floors" :key="floor" :value="floor">{{ floor }}</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-3 mb-3">
                        <label for="floorSelect" class="form-label">Bâtiment</label>
                        <select id="floorSelect" v-model="selectedBatiment" class="form-select">
                            <option value="all">Tous les bâtiments</option>
                            <option v-for="batiment in batiments" :key="batiment" :value="batiment">{{ batiment }}</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-3 mb-3">
                        <label for="floorSelect" class="form-label">Tantiemes</label>
                        <select id="floorSelect" v-model="selectedTantieme" class="form-select">
                            <option value="all">Tous les tantiemes</option>
                            <option v-for="tantieme in tantiemes" :key="tantieme.id" :value="tantieme.id">{{ tantieme.val }}</option>
                        </select>
                    </div>

                    </div>





                    <ArgonInput v-model="searchcop" size="small" :placeholder="resident_search_placeholder" />

                    <div style="overflow-y: scroll;height: calc(100vh - 370px);">

                        <Loading v-if="residentsLoading" />
                        <table v-else class="table-responsive align-items-center table-flush w-100"
                            header-row-class-name="thead-light hidden-columns">
                            <tr>
                                <td>
                                                        <div  class="row text-xs font-italic">
                                                            <div class="col-sm-3">Nom</div>
                                                            <div class="col-sm-1">Tantièmes Généraux</div>
                                                            <div class="col-sm-1" v-if="selectedTantieme !== 'all'">Tantièmes Sélectionnés</div>
                                                            <div class="col-sm-4">Email</div>
                                                            <div class="col-sm-2">Téléphone</div>
                                                        </div>

                                </td>
                            </tr>
                            <tr v-for="row in filterCops" :key="row.perso_mini.id">
                                <td>
                                    <div class="d-flex align-items-start flex-column justify-content-center"
                                        style=" padding: 5px; cursor: pointer;margin: 0px;">
                                        <div class="row" style="width:100%">
                                            <div class="col-md-3">
                                                <h6 class="mb-0 text-sm" @click="goPerso(row.perso_mini.id)">{{
            row.perso_mini.nom }} {{
            row.perso_mini.prenom }} <span v-if="isMembreCS(row)" class="badge badge-primary">CS</span>
                                                    <span v-if="isPresidentCS(row)" class="badge badge-danger">Président CS</span>
            </h6>


                                                <p class="mb-0  text-xs" v-if="row.bail"><a v-for="lot in row.bail.lots"
                                                        v-bind:key="lot.id" @click="goPerso(lot.perso_mini.id)">{{
            lot.typedescr.texte }} de {{ lot.perso_mini.nom }} {{
            lot.perso_mini.prenom }}</a></p>

                                                <p class="mb-0  text-xs" v-else>
                                                            <a v-for="b in row.locations" v-bind:key="b.id"
                                                                @click="goPerso(b.perso_mini.id)"> {{
            b.perso_mini.nom }} {{ b.perso_mini.prenom }} &nbsp; </a>


                                                </p>
                                            </div>
            <div class="col-md-1 text-xs" style="margin-top:5px;" v-if="!row.bail"> {{ row.sumTantiemes }}/{{ item.tantieme_sommes.tant_1 }}</div>
            <div class="col-md-1 text-xs" style="margin-top:5px;" v-if="!row.bail && selectedTantieme !== 'all'"> {{ row.sumTantiemesSelect }}/{{ item.tantieme_sommes[selectedTantieme] }}</div>
            <div class="col-md-1" v-if="row.bail"></div><div class="col-md-1" v-if="row.bail && selectedTantieme !== 'all'"></div>

                                            <div class="col-md-4 text-sm">
                                                <a v-if="row.perso_mini.mails.length > 0">{{
            row.perso_mini.mails[0].mail }}</a>

                                            </div>
                                            <div class="col-md-2 text-sm">
                                                <z v-if="row.perso_mini.phones.length > 0">{{
            row.perso_mini.phones[0].phone }}</z>


                                            </div>
                                            <div class="col-md-1">
                                            <span v-if="row.perso_mini.qualite.includes('P') && row.locations.length > 0"
                                                    class="badge badge-sm badge-danger">BAILLEUR </span>
                                                <span v-else-if="row.perso_mini.km > 1" class="badge badge-sm badge-info">
                                                    BAILLEUR</span>
                                                <span v-else class="badge badge-sm badge-success">OCCUPANT</span>

                                            </div>
                                            <div class="col-md-12" v-if="affichageLots">
                                                <div class="card">
                                                    <div >

                                                        <div  class="row text-xs font-italic">
                                                            <div class="col-sm-2">Type</div>
                                                            <div class="col-sm-1">N° de lot</div>
                                                            <div class="col-sm-1"></div>
                                                            <div class="col-sm-1" v-if="selectedTantieme != 'all'"></div>
                                                            <!--<div class="col-sm-2">Tantièmes généraux</div>
                                                            <div class="col-sm-2" v-if="selectedTantieme != 'all'">Tantièmes sélectionnés</div>-->
                                                            <div class="col-sm-1">Bâtiment</div>
                                                            <div class="col-sm-1">Escalier</div>
                                                            <div class="col-sm-1">Étage</div>
                                                            <div class="col-sm-1">Porte</div>
                                                        </div>
                                                        <div v-for="lot in row.lots" :key="lot.id" class="row text-xs">
                                                            <div class="col-sm-2">{{ lot.typedescr.texte }}</div>
                                                            <div class="col-sm-1">{{ lot.EXTAPI_LOT_ID }}</div>
                                                            <div class="col-sm-1">{{ lot.tant_1 }}</div>
                                                            <div class="col-sm-1" v-if="selectedTantieme != 'all'">{{ lot[selectedTantieme] }}</div>
                                                            <div class="col-sm-1">{{ lot.bat }}</div>
                                                            <div class="col-sm-1">{{ lot.escalier }}</div>
                                                            <div class="col-sm-1">{{ lot.etage }}</div>
                                                            <div class="col-sm-1">{{ lot.porte }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </td>
                            </tr>

                        </table>
                    </div>

                </Modal>
                <Modal v-model="modals.historique.show" closeable header="Historique des résidents">

                <div style="display:flex">

                    <div class="btn-group" role="group" aria-label="Basic example">
                        <base-button size="sm" outline type="secondary" :class="type_historique == 'ventes' ? 'active' : ''"
                            @click="type_historique = 'ventes'">Ventes</base-button>
                        <base-button size="sm" outline type="secondary" :class="type_historique == 'baux' ? 'active' : ''"
                            @click="type_historique ='baux'">Baux</base-button>
                        <base-button size="sm" outline type="secondary" :class="type_historique == 'dpe' ? 'active' : ''"
                            @click="type_historique = 'dpe'">DPE</base-button>
                    </div>
                    &nbsp;
                    <base-button v-if="affichageLots" size="xs" type="warning" @click="affichageLots = false">
                        <i class="fa fa-eye-slash"></i> Masquer les lots
                    </base-button>
                    <base-button v-else size="xs"  type="warning" @click="affichageLots = true">
                        <i class="fa fa-eye"></i> Afficher les lots
                    </base-button>
                    <div style="flex:1"></div>
                    <div style="display:block"><span class="badge badge-primary">Total : {{ filterHistoriqueCops.length }}</span></div>&nbsp;


<base-button size="xs" outline type="primary" @click="downloadCsvHistorique"><i
                            class="fa fa-download"></i>Télécharger</base-button>

                </div>


                    <div class="row">
                    <div class="col-12 col-md-3 mb-3">
                        <label for="lotTypeSelect" class="form-label">Type de lot</label>
                        <select id="lotTypeSelect" v-model="selectedLotType" class="form-select">
                            <option value="all">Tous les types</option>
                            <option v-for="type in lotTypes" :key="type" :value="type">
                            {{ type }}
                            </option>
                        </select>
                    </div>

                    <div class="col-12 col-md-3 mb-3">
                        <label for="floorSelect" class="form-label">Étage</label>
                        <select id="floorSelect" v-model="selectedFloor" class="form-select">
                            <option value="all">Tous les étages</option>
                            <option v-for="floor in floors" :key="floor" :value="floor">{{ floor }}</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-3 mb-3">
                        <label for="floorSelect" class="form-label">Bâtiment</label>
                        <select id="floorSelect" v-model="selectedBatiment" class="form-select">
                            <option value="all">Tous les bâtiments</option>
                            <option v-for="batiment in batiments" :key="batiment" :value="batiment">{{ batiment }}</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-3 mb-3">
                    </div>

                    </div>





                    <ArgonInput v-model="searchcop" size="small" :placeholder="resident_search_placeholder" />

                    <div style="overflow-y: scroll;height: calc(100vh - 370px);">

                        <Loading v-if="residentsHistoriqueLoading" />
                        <table v-else class="table-responsive align-items-center table-flush w-100"
                            header-row-class-name="thead-light hidden-columns">
                            <tr>
                                <td>
                                                        <div  class="row text-xs font-italic">
                                                            <div class="col-sm-3">Nom</div>
                                                            <div class="col-sm-1">Date de sortie</div>
                                                        </div>

                                </td>
                            </tr>
                            <tr v-for="row in filterHistoriqueCops" :key="row.perso_mini.id">
                                <td>
                                    <div class="d-flex align-items-start flex-column justify-content-center"
                                        style=" padding: 5px; cursor: pointer;margin: 0px;">
                                        <div class="row" style="width:100%">
                                            <div class="col-md-3">
                                                <h6 class="mb-0 text-sm" @click="goPerso(row.perso_mini.id)">{{
            row.perso_mini.nom }} {{
            row.perso_mini.prenom }} <span v-if="isMembreCS(row)" class="badge badge-primary">CS</span>
                                                    <span v-if="isPresidentCS(row)" class="badge badge-danger">Président CS</span>
            </h6>


                                                <p class="mb-0  text-xs" v-if="row.bail"><a v-for="lot in row.bail.lots"
                                                        v-bind:key="lot.id" @click="goPerso(lot.perso_mini.id)">{{
            lot.typedescr.texte }} de {{ lot.perso_mini.nom }} {{
            lot.perso_mini.prenom }}</a></p>

                                                <p class="mb-0  text-xs" v-else>
                                                    <a v-for="l in row.lots" v-bind:key="l.id">
                                                        <a v-for="c in l.baux" v-bind:key="c.id">
                                                            <a v-for="b in c.ext_locataire" v-bind:key="b.id"
                                                                @click="goPerso(b.perso_mini.id)"> {{
            b.perso_mini.nom }} {{ b.perso_mini.prenom }} </a>

                                                        </a>

                                                    </a>

                                                </p>
                                            </div>
            <div class="col-md-1 text-xs" style="margin-top:5px;"  v-if="!row.bail"> {{ new Date(row.date_vente).toLocaleString('fr-FR',{
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    }) }}</div>
            <div class="col-md-1 text-xs" v-if="row.bail">
{{ new Date(row.bailinfo?.date_fin_bail_effective).toLocaleString('fr-FR',{
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    }) }}

            </div><div class="col-md-1" v-if="row.bail && selectedTantieme !== 'all'"></div>

                                           <!--<div class="col-md-4 text-sm">
                                                <a v-if="row.perso_mini.mails.length > 0">{{
            row.perso_mini.mails[0].mail }}</a>

                                            </div>
                                            <div class="col-md-2 text-sm">
                                                <z v-if="row.perso_mini.phones.length > 0">{{
            row.perso_mini.phones[0].phone }}</z>


                                            </div>-->
                                            <div class="col-md-1">
                                                <span v-if="row.perso_mini.qualite.includes('P') && row.lots.find(l => l.baux.find(c => c.ext_locataire))"
                                                    class="badge badge-sm badge-danger">BAILLEUR </span>
                                                <span v-else-if="row.perso_mini.km > 1" class="badge badge-sm badge-info">
                                                    BAILLEUR</span>
                                                <span v-else class="badge badge-sm badge-success">OCCUPANT</span>


                                            </div>
                                            <div class="col-md-12" v-if="affichageLots">
                                                <div class="card">
                                                    <div >

                                                        <div  class="row text-xs font-italic">
                                                            <div class="col-sm-2">Type</div>
                                                            <div class="col-sm-1">N° de lot</div>
                                                            <div class="col-sm-1"></div>
                                                            <div class="col-sm-1" v-if="selectedTantieme != 'all'"></div>
                                                            <div class="col-sm-1">Bâtiment</div>
                                                            <div class="col-sm-1">Escalier</div>
                                                            <div class="col-sm-1">Étage</div>
                                                            <div class="col-sm-1">Porte</div>
                                                        </div>
                                                        <div v-for="lot in row.lots" :key="lot.id" class="row text-xs">
                                                            <div class="col-sm-2" @click="goLot(lot)">{{ lot.typedescr.texte }}</div>
                                                            <div class="col-sm-1">{{ lot.EXTAPI_LOT_ID }}</div>
                                                            <div class="col-sm-2">{{ lot.date_vente }}</div>
                                                            <div class="col-sm-1">{{ lot.bat }}</div>
                                                            <div class="col-sm-1">{{ lot.escalier }}</div>
                                                            <div class="col-sm-1">{{ lot.etage }}</div>
                                                            <div class="col-sm-1">{{ lot.porte }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </td>
                            </tr>

                        </table>
                    </div>

                </Modal>
                <Modal v-model="modals.equipe.show" closeable header="Équipe de gestion">

                    <table class="table">
                        <tr v-for="c in item.collaborateurs" :key="c.id">
                            <td>{{ c.user.given_name }} {{ c.user.family_name }}</td>
                            <td>
                                <a v-if="c.user.email" class="btn btn-xs btn-cyan btn-round mb-0"
                                    :href="'mailto:' + c.user.email"><i class="fa fa-envelope"></i></a>
                                {{ c.user.email }}
                            </td>
                            <td>
                                <a v-if="c.phone" class="btn btn-xs btn-cyan btn-round mb-0" :href="'tel:' + c.phone"><i
                                        class="fa fa-phone"></i></a>

                                {{ c.phone }}
                            </td>
                            <td>
                                <a v-if="c.mobile_phone_pro" class="btn btn-xs btn-cyan btn-round mb-0"
                                    :href="'tel:' + c.mobile_phone_pro"><i class="fa fa-phone"></i></a>
                                {{ c.mobile_phone_pro }}
                            </td>
                            <td>{{ c.qualite }}</td>
                            <td v-if="c.metier == 'copropriete'">Copropriété</td>
                            <td v-else-if="c.metier == 'gerance'">Gérance</td>
                            <td v-else-if="c.metier == 'transaction'">Transaction</td>
                            <td v-else-if="c.metier == 'location'">Location</td>
                            <td v-else-if="c.metier == 'imm_entreprise'">Immo. d'entreprise</td>
                        </tr>
                    </table>
                </Modal>
                <Modal v-model="modals.new_event.show" closeable header="Nouvel évènement">
                    <AjoutEvenementImmeuble v-model="modals.new_event" :immeubleprop="item" />
                </Modal>


                <Modal v-model="modals.contrats.show" closeable header="Contrats">

                    <table class="table text-sm">
                        <thead>
                            <th>Statut</th>
                            <th>Type</th>
                            <th>Fournisseur</th>
                            <th>Libellé</th>
                            <th>Date fin</th>
                            <th>Date dernière facture</th>
                            <th>Montant</th>
                        </thead>
                        <tbody>

                            <tr v-for="row in item.contrats" :key="row.id">
                                <td>
                                    <i v-if="row.date_fin_contrat" class="fa fa-times text-danger"></i>
                                    <i v-else-if="isMoreThanThreeYearsOld(row.contrat_date_derniere_facture)" class="fa fa-hourglass-start text-warning"></i>
                                    <i v-else class="fa fa-check text-success"></i>
                                </td>
                                <td>{{ row.ext_fournisseur_type }}</td>
                                <td> <div v-if="row.ext_fournisseur">{{ row.ext_fournisseur.nom }}</div></td>

                                <td>{{ row.contrat_libelle }}</td>
                                <td>{{ row.date_fin_contrat }}</td>
                                <td>{{ row.contrat_date_derniere_facture }}</td>
                                <td class="text-end">  {{ row.contrat_montant_derniere_facture }} €</td>

                            </tr>
                        </tbody>
                    </table>
                </Modal>



                <Modal v-model="modals.attributs.show" closeable header="Ajouter un attribut à l'immeuble">
                    <AjoutAttributImmeuble v-model="item.attributes" :closeModal="() => { modals.attributs.show = false }"
                        :modalAttributes="modals.attributs" :immeuble_id="$route.params.id" />
                </Modal>
                <Modal v-model="modals.edit_attributs.show" closeable header="Modifier un attribut de l'immeuble">
                    <EditionAttributImmeuble v-model="item.attributes"
                        :closeModal="() => { modals.edit_attributs.show = false }" :modalAttributes="modals.edit_attributs"
                        :immeuble_id="$route.params.id" />
                </Modal>
                <Modal v-model="modals.near_building.show" closeable header="À proximité de l'immeuble">

                    <Loading v-if="modals.moreOnBuildingLoading" />
                    <div class="row" v-else>
                        <div class="col-6">

                            <GoogleMap ref="mapRef" id="map" api-key="AIzaSyBkiDeWIzazHYmkWT3BxPRIvjXbrFrGVIw"
                                style="width: 100%; height: calc(100vh - 180px)"
                                :center="{ lat: parseFloat(item?.lat), lng: parseFloat(item?.lng) }"
                                :libraries="['drawing']" :zoom="17" language="fr" gestureHandling="greedy"
                                :panControl="0" mapTypeId="satellite" :keyboardShortcuts="1">

                            </GoogleMap>
                            <!-- <button class="btn btn-success" @click="openAdresse()">Ouvrir dans Google Maps <i class="fa fa-location-dot"></i></button>-->


                        </div>
                        <div class="col-6" v-if="item.more_rnc">
                            <h6>Informations globales</h6>
                            <table class="table table-bordered text-sm">
                                <tr>
                                    <td><i class="fa-solid fa-draw-polygon" style="border: unset;"></i> Surface parcelle</td>
                                    <td>{{ item.more_rnc?.parcelle?.s_geom_parcelle }} ㎡</td>
                                </tr>
                                <tr>
                                    <td><i class="fa-solid fa-building" style="border: unset;"></i> Surface bâtiment</td>
                                    <td> {{ item.more_rnc?.batiment_groupe?.s_geom_groupe }} ㎡
                                    </td>
                                </tr>
                                <tr>
                                    <td><i class="fa-solid fa-stairs" style="border: unset;"></i> Nombre d'étages max (déduit)</td>
                                    <td>
                                        {{ nb_etages }}
                                    </td>
                                </tr>
                            </table>
                            <h6>Informations par bâtiment</h6>
                            <table class="table table-bordered">
                                <tr v-for="(cstr, i) in item.more_rnc?.batiment_cstr"
                                    :key="cstr.batiment_construction_id">
                                    <td>Bâtiment {{ i + 1 }}</td>
                                    <td>Hauteur : {{ cstr.hauteur }} m</td>
                                    <td>Surface : {{ cstr.s_geom_cstr }} ㎡</td>
                                </tr>
                            </table>


                        </div>
                        <div class="col-12">
                            <h6 class="mt-2">Ci-dessous — Informations complémentaires</h6>

                            <Loading v-if="modals.near_building.loading" />
                            <table class="table text-xs" v-else>
                                <thead>
                                    <th>Nom copropriété</th>
                                    <th>Distance</th>
                                    <th>Syndic</th>
                                    <th>Date cloture</th>
                                    <th>Date AG</th>
                                    <th>Date fin mandat</th>
                                </thead>

                                <tr v-for="item in modals.near_building.item" :key="item.id"
                                    @click="modals.near_building_detail.item = item; modals.near_building_detail.show = true;">
                                    <td>{{ item.NomUsageCopropriete }}</td>
                                    <td>{{ Math.round(item.distance * 1000) }}m </td>
                                    <td v-if="item.got">{{ item.got.raisonSocialeOuNomSyndic }}</td>
                                    <td v-if="item.got">{{
            item.got.immatCopropriete.donneesFinancieres.dateClotureComptes }}</td>
                                    <td v-if="item.got">{{ item.got.immatCopropriete.donneesFinancieres.dateAg }}</td>
                                    <td v-if="item.got">{{ item.got.immatCopropriete.infoRattachement.dateFinMandat }}
                                    </td>

                                </tr>

                            </table>
                        </div>

                    </div>



                </Modal>

                <Modal v-model="modals.near_building_detail.show" closeable
                    :header="modals.near_building_detail.item.NomUsageCopropriete">

                    <table>
                        <tr v-if="modals.near_building_detail.item.got">
                            <td>Mise à jour</td>
                            <td>{{ modals.near_building_detail.item.got.dateDerniereModif }}</td>
                        </tr>
                    </table>
                    <div class="row" v-if="modals.near_building_detail.item.got">

                        <div class="col-sm-6">
                            <h6>Info Rattachement</h6>
                            <table class="table">
                                <tr>
                                    <td>Administration Provisoire</td>
                                    <td class="text-end">
                                        <a class=" text-danger"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.infoRattachement.adminProvisoire == 'true'">Oui</a>
                                        <a class=" text-success" v-else>Non</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Date du début du mandat</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.infoRattachement.dateDebutMandat }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date de fin du mandat</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.infoRattachement.dateFinMandat }}
                                    </td>
                                </tr>
                            </table>
                            <br>
                            <h6>Données financières</h6>
                            <table class="table">
                                <tr>
                                    <td>Date dernière AG</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.dateAg }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date Clôture Compte</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.dateClotureComptes }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Charges Opérations Courantes</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.chargesOperationsCourantes }}€
                                    </td>
                                </tr>
                                <tr>
                                    <td>Montant des Impayés Coproprietaires</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.montantImpayesCoproprietaires }}€
                                    </td>
                                </tr>
                                <tr>
                                    <td>Présence d'employes</td>
                                    <td class="text-end">
                                        <a class=" text-success"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.presenceEmployes == 'true'">Oui</a>
                                        <a class=" text-danger" v-else>Non</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre de Coproprietaires statut impayé</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.nbCoproprietairesImpayes }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Montant des Dettes</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.montantDettes }}€
                                    </td>
                                </tr>
                                <tr>
                                    <td>Montant du Fonds Travaux</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.montantFondTravaux }}€
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date du début d'exercice</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.dateDebutExercice }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Premier exercice comptable</td>
                                    <td class="text-end">
                                        <a class=" text-success"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.premierExerciceComptable == 'true'">Oui</a>
                                        <a class=" text-danger" v-else>Non</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Comptes non approuvés</td>
                                    <td class="text-end">
                                        <a class=" text-danger"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.donneesFinancieres.comptesNonApprouves == 'true'">Oui</a>
                                        <a class=" text-success" v-else>Non</a>
                                    </td>
                                </tr>
                            </table>

                            <br>
                            <h6>Procédures</h6>

                            <table class="table">
                                <tr>
                                    <td>Arreté sur équipements communs</td>
                                    <td class="text-end">
                                        <a class=" text-danger"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.flagEquipementsCom == true">Oui</a>
                                        <a class=" text-success" v-else>Non</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Arreté pour Insalubrité</td>
                                    <td class="text-end">
                                        <a class=" text-danger"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.flagInsalubritePartiesCom == true">Oui</a>
                                        <a class=" text-success" v-else>Non</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mandataire adhoc</td>

                                    <td class="text-end">
                                        <a class=" text-danger"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.flagMandatAdhoc == true">Oui</a>
                                        <a class=" text-success" v-else>Non</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Ordonnance Carence</td>
                                    <td class="text-end">
                                        <a class=" text-danger"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.flagOrdonnanceCarence == true">Oui</a>
                                        <a class=" text-success" v-else>Non</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Péril Parties Communes</td>
                                    <td class="text-end">
                                        <a class=" text-danger"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.flagPerilPartiesCom == true">Oui</a>
                                        <a class=" text-success" v-else>Non</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sécurité Insalubrite Parties Communes</td>
                                    <td class="text-end">
                                        <a class=" text-danger"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.flagSecuriteInsalubritePartiesCom == true">Oui</a>
                                        <a class=" text-success" v-else>Non</a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-sm-6">
                            <h6>Identification</h6>
                            <table class="table">
                                <tr>
                                    <td>Numéro Immatriculation Copropriete</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.identification.numImmatriculationCopropriete }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date Reglement Copropriete</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.identification.dateReglementCopropriete }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Voie</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.identification.adressePrincipale.voie }}
                                    </td>
                                </tr>



                                <tr>
                                    <td>Syndicat Principal</td>
                                    <td class="text-end">
                                        <a class=" text-success"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.identification.syndicatPrincipal == 'true'">Oui</a>
                                        <a class=" text-danger" v-else>Non</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Syndicat Cooperatif</td>
                                    <td class="text-end">
                                        <a class=" text-success"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.identification.syndicatCooperatif == 'true'">Oui</a>
                                        <a class=" text-danger" v-else>Non</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Residence Service</td>
                                    <td class="text-end">
                                        <a class=" text-success"
                                            v-if="modals.near_building_detail.item.got.immatCopropriete.identification.residenceService == 'true'">Oui</a>
                                        <a class=" text-danger" v-else>Non</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Nombre d'Asl :</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.identification.nbAsl }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre d'Aful :</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.identification.nbAful }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Unions de Syndicats</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.identification.nbUnionsSyndicats }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre de lots :</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.identification.nbLots }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre de lots Habitation Bureau et Commerce :</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.identification.nbLotsHabBurCom }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre de lots Habitation :</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.identification.nbLotsHab }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre de lots Stationnement :</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.identification.nbLotsStationnement }}
                                    </td>
                                </tr>
                            </table>
                            <br>
                            <h6>Données techniques</h6>

                            <table class="table">
                                <tr>
                                    <td>Type de Chauffage</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.typeChauffage }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Énergie Chauffage Collectif</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.energieChauffageCollectifNonUrbain }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre d'ascenseurs</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.nbAscenseurs }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre de batiments au total</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.nbBatimentsTotal }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Période de construction</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.periodeConstruction }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Année de construction</td>
                                    <td class="text-end">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.anneeConstruction }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Plan Pluriannuel de travaux</td>
                                    <td class="text-end"
                                        v-if="modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.ppt">
                                        {{ modals.near_building_detail.item.got.immatCopropriete.donneesTechniques.ppt }}
                                    </td>
                                    <td class="text-end" v-else>NC</td>
                                </tr>
                            </table>
                        </div>

                    </div>



                </Modal>




                <base-header :class="item.pertemandat ? 'bg-gray' : 'bg-gradient-danger'" class="pb-1 border-radius-xl">
                    <div class="row align-items-center pt-4 pb-2">
                        <div class="col-lg-3 text-left">
                            <button class="btn btn-white mt-2 btn-xs" @click="$router.go(-1)">
                                <span class="btn-inner--icon"><i class="fas fa-arrow-left"></i></span> Retour
                            </button>
                        </div>
                        <div class="col-lg-6 text-center">
                            <h6 class="h5 text-white d-inline-block mb-0"> {{ item.EXTAPI_IMM_ID }} &nbsp;</h6>
                            <h6 class="h3 text-white d-inline-block mb-0">{{ item.nom }}

                            </h6>
                            <h6 class="h6 text-white d-inline-block mb-0"> &nbsp;
                                {{ item.pertemandat ? ' - Mandat syndic terminé le ' + item.pertemandat : '' }}</h6>
                        </div>
                        <div class="col-lg-3 text-end">
                            <button class="btn btn-white mt-2 btn-xs" @click="goFiles">
                                <span class="btn-inner--icon"><i class="fas fa-folder"></i></span> Intranet
                            </button>
                            &nbsp;
                            <button class="btn btn-white mt-2 btn-xs" @click="modals.equipe.show = true">
                                <span class="btn-inner--icon"><i class="fas fa-users"></i></span> Équipe de gestion
                            </button>

                        </div>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-lg-4">

                                    <mini-statistics-card @click="modals.residents.show = true"
                                        :title="{ text: 'Résidents', color: 'opacity-7 text-dark' }"
                                        :value="{ text: item.nblotsprinc, color: 'text-dark' }" :icon="{
            component: 'fa fa-users text-white',
            background: 'bg-warning',
            shape: 'rounded-circle',
        }" />
                                </div>
                                <div class="col-lg-4">

                                    <mini-statistics-card :title="{ text: 'Historique', color: 'opacity-7 text-dark' }"
                                    @click="modals.historique.show = true"
                                        :value="{ text: new Date(item.rnc?.DateReglementCopropriete).toLocaleString('fr-FR',{
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    }), color: 'text-dark' }" :icon="{
            component: 'fas fa-calendar text-white',
            background: 'bg-primary',
            shape: 'rounded-circle',
        }" />
                                </div>
                                <div class="col-lg-4">

                                    <mini-statistics-card
                                        :title="{ text: 'Satisfaction client', color: 'opacity-7 text-dark' }"
                                        :value="{ text: '?%', color: 'text-dark' }" :icon="{
            component: 'fas fa-smile text-white',
            background: 'bg-info',
            shape: 'rounded-circle',
        }" />
                                </div>

                            </div>


                        </div>
                        <div class="col-lg-4">

                            <mini-statistics-card @click="openNear()"
                                :title="{ text: 'Adresse', color: 'opacity-7 text-dark' }"
                                :value="{ text: item.adresse1 + ' ' + item.adresse2 + ' ' + item.cp + ' ' + item.ville, color: 'text-dark' }"
                                :icon="{
            component: 'fas fa-location-arrow text-white',
            background: 'bg-success',
            shape: 'rounded-circle',
        }" />

                        </div>





                    </div>
                </base-header>

            </div>
            <div class="py-1 container-fluid">
                <div class="row">
                    <div class="col-lg-3  ">
                        <div class="card p-3" style="height: calc(100vh - 350px);">

                            <div class="btn-group" role="group" aria-label="Basic example">
                                <base-button size="xs" outline type="dark_yellow"
                                    :class="cs_or_mandats == 'cs' ? 'active' : ''" @click="cs_or_mandats = 'cs'">Conseil
                                    Syndical</base-button>
                                <base-button size="xs" outline type="dark_yellow"
                                    :class="cs_or_mandats == 'mandats' ? 'active' : ''"
                                    @click="cs_or_mandats = 'mandats'">Gestion locative</base-button>
                            </div>

                            <div v-if="cs_or_mandats == 'cs'" style="overflow-y: scroll;">

                                <table class="table-responsive align-items-center table-flush">
                                    <tr v-for="row in item.membres_cs" v-bind:key="row.id">
                                        <div class="d-flex align-items-start flex-column justify-content-center"
                                            style=" padding: 5px; cursor: pointer;margin: 0px;">
                                            <h6 class="mb-0 text-sm" @click="goPerso(row.perso_mini_cs.id)"
                                                v-if="row.perso_mini_cs">{{
            row.perso_mini_cs.nom }}</h6>
                                            <h6 class="mb-0 text-sm" @click="goPerso(row.perso_mini.id)" v-else>{{
            row.perso_mini.nom }}</h6>

                                            <p class="mb-0  text-xs" v-if="row.perso_mini_cs"
                                                @click="goPerso(row.perso_mini_cs.id)">
                                                <span v-if="row.membrecs == '2'"><b>Président(e)</b></span>
                                                <!--Ref : {{ row.perso_mini_cs.EXTAPI_PERSO_ID }} -->
                                                <a v-if="row.perso_mini.phones[0]">{{ row.perso_mini.phones[0].phone
                                                    }}</a>
                                            </p>
                                            <p class="mb-0  text-xs" v-else @click="goPerso(row.perso_mini.id)">
                                                <span v-if="row.membrecs == '2'"><b>Président(e)</b></span>
                                                <!--Ref : {{ row.EXTAPI_PERSO_ID }} -->
                                                <a v-if="row.perso_mini.phones[0]">{{ row.perso_mini.phones[0].phone
                                                    }}</a>
                                            </p>

                                        </div>
                                    </tr>
                                </table>
                            </div>
                            <div v-if="cs_or_mandats == 'mandats'" style="overflow-y: scroll;">
                                <table class="table-responsive align-items-center table-flush">
                                    <tr v-for="row in item.mandats" v-bind:key="row.id">
                                        <div class="d-flex align-items-start flex-column justify-content-center"
                                            style=" padding: 5px; cursor: pointer;margin: 0px;"
                                            :class="row.date_fin_effet == null ? '' : 'bg-gray'">
                                            <h6 class="mb-0 text-sm text-left" @click="goPerso(row.perso_mini.id)">{{
            row.perso_mini.nom }} {{
            row.perso_mini.prenom }}</h6>
                                            {{ row.date_fin_effet }}
                                            <div v-if="row.cop">

                                                <p class="mb-0  text-xs" v-if="row.cop.bail"><a
                                                        v-for="lot in row.cop.bail.lots" v-bind:key="lot.id"
                                                        @click="goPerso(lot.perso_mini.id)">{{ lot.typedescr.texte }} de
                                                        {{ lot.perso_mini.nom }} {{
            lot.perso_mini.prenom }}</a></p>

                                                <p class="mb-0  text-xs" v-else>
                                                    <a v-for="l in row.cop.lots" v-bind:key="l.id">
                                                   <!-- <a v-for="c in l.baux_actifs" v-bind:key="c.id">
                                                            <a v-for="b in c.ext_locataire" v-bind:key="b.id"
                                                                @click="goPerso(b.perso_mini.id)"> {{
            b.perso_mini.nom }} {{ b.perso_mini.prenom }} </a>

                                                        </a>
-->
                                                    </a>

                                                </p>
                                            </div>
                                        </div>
                                    </tr>
                                </table>

                            </div>

                        </div>

                    </div>
                    <div class="col-lg-6">
                        <div class="card p-3" style="height: calc(100vh - 350px);">
                            <div style="display: flex;">
                                <div class="btn-group" style="width:100%" role="group" aria-label="Basic example">
                                    <base-button size="xs" type="primary" outline
                                    class="w-100"
                                        :class="evenements_type == 'emails' ? 'active' : ''"
                                        @click="evenements_type = 'emails'">Emails détectés</base-button>
                                    <base-button size="xs" type="primary" outline
                                    class="w-100"
                                        :class="evenements_type == 'actuels' ? 'active' : ''"
                                        @click="evenements_type = 'actuels'">Évènements actuels</base-button>
                                    <base-button size="xs" type="primary" outline
                                    class="w-100"
                                        :class="evenements_type == 'futurs' ? 'active' : ''"
                                        @click="evenements_type = 'futurs'">Évènements futurs</base-button>
                                </div>
                            </div>
                            <div v-if="evenements_type == 'emails'" style="overflow-y: scroll;">
                                <table class="table text-sm">
                                    <tr v-for="m in item.emails" :key="m.id" @click="goMail(m)">
                                        <td>{{ JSON.parse(m.content).from }}</td>
                                        <td>{{ JSON.parse(m.content).subject }}</td>
                                    </tr>
                                </table>
                            </div>
                            <div v-if="evenements_type == 'actuels'" style="overflow-y: scroll;">
                                <div class="text-center" v-if="rights.includes('évènements')">
                                    <base-button size="xs" type="neutral" @click="newEventImmeuble"><i
                                            class="fa fa-plus"></i>Créer un évènement</base-button>

                                </div>



                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card p-3" style="height: calc(100vh - 350px);">
                            <div style="display: flex;">
                                <div class="btn-group" style="width:100%" role="group" aria-label="Basic example">
                                    <base-button size="xs" type="purple" outline
                                        :class="attributs_or_contrats == 'attributs' ? 'active' : ''"
                                        @click="attributs_or_contrats = 'attributs'">Attributs</base-button>
                                    <base-button size="xs" type="purple" outline
                                        :class="attributs_or_contrats == 'contrats' ? 'active' : ''"
                                        @click="attributs_or_contrats = 'contrats'">Contrats</base-button>
                                </div>
                            </div>
                            <div v-if="attributs_or_contrats == 'attributs'" style="overflow-y: scroll;">
                                {{ item.rnc?.NumeroImmatriculation }}

                                <base-button size="sm" type="neutral" @click="modals.attributs.show = true">
                                    <i class="fas fa-plus"></i>Ajouter</base-button>
                                <table class="table-responsive align-items-center table-flush">
                                    <tr v-if="modals.near_building.item[0]">
                                        <td class="text-xs">Immatriculation RNC :</td>
                                        <td class="text-xs" v-if="modals.near_building.item[0]">{{
            modals.near_building.item[0].NumeroImmatriculation }}</td>
                                    </tr>
                                    <tr v-if="modals.near_building.item[0]">
                                        <td class="text-xs">Fin de mandat :</td>
                                        <td class="text-xs" v-if="modals.near_building.item[0]">{{
            modals.near_building.item[0].DateFinMandat }}</td>

                                    </tr>
                                    <tr v-for="row in item.attributes.filter(item => item.parent_id == null)"
                                        v-bind:key="row.id">

                                        <div v-if="row.attr_type != 'fichiers'"
                                            @click="changeAttrImmeuble(row); modals.attributs.parent_id = 0"
                                            class="d-flex align-items-start flex-column justify-content-center"
                                            style=" padding: 5px; cursor: pointer;margin: 0px;">


                                            <a><i v-if="row.attr_type == 'codes'" class="fas fa-key"></i><i
                                                    v-if="row.attr_type == 'texte'" class="fas fa-sticky-note"></i> {{
            row.name }}</a>
                                            <a v-if="row.attr_type == 'texte'">{{ JSON.parse(row.value) }}</a>

                                        </div>

                                        <div v-else @click="showChildren(row)"
                                            class="d-flex align-items-start flex-column justify-content-center"
                                            style=" padding: 5px; cursor: pointer;margin: 0px;">

                                            <a><i v-if="row.attr_type == 'fichiers'" class="fas fa-folder"></i> {{
            row.name }}</a>

                                        </div>


                                        <button v-if="row.attr_type == 'fichiers' && row.show_children"
                                            class="btn btn-xs btn-white" @click="changeAttrImmeuble(r);">
                                            <i class="fas fa-pen"></i>Modifier</button>

                                        <button v-if="row.attr_type == 'fichiers' && row.show_children"
                                            class="btn btn-xs btn-white"
                                            @click="modals.attributs.show = true; modals.attributs.parentId = row.id; modals.attributs.parent = row; modals.attributs.attr_type = 'fichiers'; modals.attributs.force = true;">
                                            <i class="fas fa-plus"></i>Sous-dossier</button>
                                        <div v-if="row.children && row.show_children" class="card w-100 bg-light p-2">

                                            <div v-for="r in row.children" :key="r.id" class="ml-3">
                                                <div @click="changeAttrImmeuble(r)"
                                                    class="d-flex align-items-start flex-column justify-content-center"
                                                    style=" padding: 5px; cursor: pointer;margin: 0px;">


                                                    <a><i v-if="r.attr_type == 'fichiers'" class="fas fa-folder"></i>
                                                        <i v-if="r.attr_type == 'codes'" class="fas fa-key"></i>
                                                        <i v-if="r.attr_type == 'texte'" class="fas fa-sticky-note"></i>
                                                        {{ r.name }}
                                                    </a>
                                                    <a v-if="r.attr_type == 'texte'">{{ JSON.parse(r.value) }}</a>

                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="row.fichiers">
                                            <div v-for="f in row.fichiers.filter(r => r.show_children===true)"
                                                :key="f.id" class="ml-3">
                                                {{ f.name }}

                                            </div>

                                        </div>
                                    </tr>
                                </table>
                            </div>
                            <div v-if="attributs_or_contrats == 'contrats'" style="overflow-y: scroll;">
                                <ArgonInput v-model="searchcontrat" size="small"
                                    placeholder="Rechercher un contrat..." />
                                <button v-if="!contratsLoading" class="btn btn-xs btn-white"
                                    @click="modals.contrats.show = true"><i class="fa fa-info"></i> Afficher tous les contrats</button>

                                <Loading v-if="contratsLoading" />
                                <table v-else class="table-responsive align-items-center table-flush">
                                    <tr v-for="row in filterContrat" :key="row.id">
                                        <div class="table-actions" style="display: flex;">
                                            <!--{{ row.contrat_libelle }}{{ row.ext_fournisseur_type }} {{ row.ext_fournisseur_idspe }}-->
                                            <div style="flex:1">
                                                <h5 class="text-sm" v-if="row.ext_fournisseur">{{
                                                    row.ext_fournisseur.nom }}</h5>
                                                <p class="text-xs">{{ row.contrat_libelle }}</p>

                                            </div>
                                            <div class="text-end text-xs" style="flex:1;"
                                                v-if="row.ext_fournisseur.dl_fournisseur">{{
                                                row.ext_fournisseur.dl_fournisseur.tva }}</div>
                                        </div>
                                    </tr>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>
