<template>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="bg-gradient-purple p-1 border-radius-xl d-flex">
          <h6 class="mb-0 text-white px-2">Liste des fournisseurs</h6>
          <div style="flex:1"></div>
          <button class="btn btn-xs btn-round btn-white mb-0" @click="router.push({name:'Ajouter un fournisseur'})">
            <i class="fa fa-plus me-1"></i> Ajouter un fournisseur
          </button>
        </div>

        <div class="container text-center mb-2">
          <div class="mt-2">
            <ArgonInput icon="fa fa-search" formgroup_classes="mb-1" iconDir="left" placeholder="Rechercher avec un nom ou un SIREN" v-model="search"/>
            <el-select
              style="color: red;"
              class="select-primary pagination-select col-sm-4"
              v-model="sort"
              placeholder="Tri"
              name="Tri"
              size="small"
            >
              <el-option
                class="select-primary"
                key="thirdparty_name"
                label="Tri par ordre alphabétique"
                value="thirdparty_name"
              />
              <el-option
                class="select-primary"
                key="events.last"
                label="Trier par dernier évènement"
                value="events.last"
              />
            </el-select>
            <el-select
              class="select-primary pagination-select col-sm-3"
              v-model="localisation"
              placeholder="Localisation"
              filterable
              size="small"
            >
              <el-option v-for="r in departements" :key="r.id" :label="r.name" :value="r.id"/>
            </el-select>

            <!-- Sélecteur de code NAF
            <el-select
              class="select-primary pagination-select col-sm-3"
              v-model="nafCode"
              placeholder="Code NAF"
              size="small"
            >
              <el-option v-for="n in metiers" :key="n.code" :label="n.libelle" :value="n.code"/>
            </el-select> -->
            <!--<el-select
              class="select-primary pagination-select col-sm-3"
              v-model="onlyme"
              placeholder="Affichage"
              size="small"
            >
              <el-option
                class="select-primary"
                key="Seulement mes immeubles"
                label="Seulement mes immeubles"
                value="Seulement mes immeubles"
              />
              <el-option
                class="select-primary"
                key="Tous les immeubles actifs du parc"
                label="Tous les immeubles actifs du parc"
                value="Tous les immeubles actifs du parc"
              />
              <el-option
                class="select-primary"
                key="Tous les immeubles du parc"
                label="Tous les immeubles du parc"
                value="Tous les immeubles du parc"
              />
            </el-select> -->
          </div>
            <div class="btn-group" v-if="!metiers_complet">


                <button class="btn-metiers btn btn-xs btn-purple " @click="changeCat('all')" id="cat_all">Tous les métiers</button>
                <button class="btn-metiers btn-cat btn btn-xs"  @click="changeCat('nettoyage')" id="cat_nettoyage"><i class="fa fa-broom"></i> Nettoyage</button>
                <button class="btn-metiers btn btn-xs " @click="changeCat('elec')" id="cat_elec"><i class="fa fa-bolt"></i> Électriciens</button>
                <button class="btn-metiers btn btn-xs"  @click="changeCat('plomb')" id="cat_plomb"><i class="fa fa-wrench"></i> Plombiers</button>
                <button class="btn-metiers btn btn-xs "  @click="changeCat('peintre')" id="cat_peintre"><i class="fa fa-brush"></i> Peintres</button>
                <button class="btn-metiers btn btn-xs "  @click="changeCat('macon')" id="cat_macon"><i class="fa fa-hammer"></i> Maçons</button>
                <button class="btn-metiers btn btn-xs "  @click="changeCat('paysage')" id="cat_paysage"><i class="fa fa-leaf"></i> Paysagistes</button>
                <button class="btn-metiers btn btn-xs "  @click="changeCat('menui')" id="cat_menui"><i class="fa fa-screwdriver"></i> Menuisiers</button>
                <button class="btn-metiers btn btn-xs"  @click="changeCat('asc')" id="cat_asc"><i class="fa fa-elevator"></i> Ascensoristes</button>
                <button class="btn-metiers btn btn-xs" @click="changeCat('jur')" id="cat_jur" ><i class="fa fa-balance-scale"></i> Juridique</button>
                <button class="btn btn-xs" @click="metiers_complet = true"><i class="fa fa-more"></i> Autres</button>





            </div>
            <div v-else>
                <button class="btn btn-xs btn-purple" @click="metiers_complet = false"> ‹ Revenir à la liste </button>
                <vSelect style="color:black" :options="metiers" label="libelle" :clearable="false"
                                placeholder="Choisir un métier" v-model="metier" :filter="filterMetier"
                                @update:modelValue="onMetierChange" class="style-chooser-light-purple">
                                <template v-slot:no-options>Aucun métier trouvé.</template>
                                <template #option="{ libelle, code }">
                                    <div>{{ libelle }} - {{ code }}
                                    </div>
                                </template>
                            </vSelect>
            </div>

        </div>

        <div>
        </div>

        <div class="card">
          <div class="px-0 pb-0 pt-1 card-body">
            <div class="table-responsive" id="infinite-list" style="height:calc(100vh - 200px)" @scroll="handleScroll">
              <table class="table table-responsive">
                <tbody class="text-sm">
                  <tr v-for="four in updatedList" :key="four.id" @click="openFour(four)"
                  :style="four.liquidation == 1 || four.etatAdministratifEtablissement == 'F' ? 'background-color: #f8d7da' : ''"
                  >
                    <td>{{ four.siren }} </td>
                    <td class="text-purple">{{ four.denominationUniteLegale }} {{ four.denominationUsuelle1UniteLegale }}
                        {{ four.prenom1UniteLegale }}   {{ four.nomUniteLegale }}

                    </td>
                    <td style="flex:1;width: 100%;">{{ four.naf.libelle }}</td>
                    <td style="" v-if="four.fournisseur_near">
                        {{ four.fournisseur_near.numeroVoieEtablissement}}
                        {{ four.fournisseur_near.indiceRepetitionEtablissement}}
                        {{ four.fournisseur_near.typeVoieEtablissement}}
                        {{ four.fournisseur_near.libelleVoieEtablissement}}
                        {{ four.fournisseur_near.codePostalEtablissement}}
                        {{ four.fournisseur_near.libelleCommuneEtablissement}}
                    </td>
                    <td v-else>

                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import _ from 'lodash'
import ArgonInput from '../../../components/ArgonInput.vue'
import { ElSelect, ElOption } from 'element-plus'
import vSelect from "vue-select";

const store = useStore()
const router = useRouter()

const sort = ref('thirdparty_name')
//const onlyme = ref('Seulement mes immeubles')
const updatedList = ref([])
const page_t = ref(1)
const search = ref('')
const metiers = ref([])
const naf = ref('')
const metiers_complet = ref(false)
const localisation = ref('')

//const roleList = computed(() => getList())
const metaPage = computed(() => store.getters['fournisseur/fournisseursList']?.meta)


const getRoleList = _.debounce(async (params) => {
    const lat = JSON.parse(localStorage.getItem('agence')).lat
    const lng = JSON.parse(localStorage.getItem('agence')).lng
  await store.dispatch('fournisseur/fournisseursList', {
    ...(params.sort ? { sort: params.sort } : {}),
    ...(params.query ? { search: params.query } : {}),
    ...(params.naf ? { naf: params.naf } : {}),
    ...(params.localisation ? { localisation: params.localisation } : {}),
    lat: lat,
    lng: lng,
    page: params.nr,
    size: 25,
  })

  updateList()
}, 300)

const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
}
const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371;
    var dLat = deg2rad(lat2 - lat1);
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}

const updateList = () => {
  const agence = JSON.parse(localStorage.getItem('agence'))
  if (store.getters['fournisseur/fournisseursList']) {
    var fournisseurs = store.getters['fournisseur/fournisseursList'].data

        if (store.getters['fournisseur/fournisseursList'].meta.current_page === 1) {
            fournisseurs = store.getters['fournisseur/fournisseursList'].data
        } else {
            fournisseurs = [...updatedList.value, ...store.getters['fournisseur/fournisseursList'].data]
        }

    // get t
    updatedList.value = fournisseurs.map((four) => {
        if(four.fournisseur_near ){
            if(four.fournisseur_near.length > 0){
                four.fournisseur_near = four.fournisseur_near[0]
            }
            return four
        }

      if (four.fournisseurs_limited) {
        let closest = null
        let minDistance = Number.MAX_VALUE
        if(four.fournisseurs_limited.length == 0){
            four.fournisseur_near = null
            return four
        }
        if(four.fournisseurs_limited.length == 1){
            four.fournisseur_near = four.fournisseurs_limited[0]
            return four
        }
        four.fournisseurs_limited.forEach(f => {
          const distance = getDistanceFromLatLonInKm(
            f.latitude,
            f.longitude,
            agence.lat,
            agence.lng
          )
          if (distance < minDistance) {
            minDistance = distance
            closest = f
          }
        })
        four.fournisseur_near = closest

        if (!closest){
            four.fournisseur_near = four.fournisseurs_limited[0]
        }
      }
      return four
    })
  }
}


const  departements = [
            { id:1,
            name: '01 - Ain'
            },
            { id:2,
            name: '02 - Aisne'
            },
            { id:3,
            name: '03 - Allier'
            },
            { id:4,
            name: '04 - Alpes-de-Haute-Provence'
            },
            { id:5,
            name: '05 - Hautes-Alpes'
            },
            { id:6,
            name: '06 - Alpes-Maritimes'
            },
            { id:7,
            name: '07 - Ardèche'
            },
            { id:8,
            name: '08 - Ardennes'
            },
            { id:9,
            name: '09 - Ariège'
            },
            { id:10,
            name: '10 - Aube'
            },
            { id:11,
            name: '11 - Aude'
            },
            { id:12,
            name: '12 - Aveyron'
            },
            { id:13,
            name: '13 - Bouches-du-Rhône'
            },
            { id:14,
            name: '14 - Calvados'
            },
            { id:15,
            name: '15 - Cantal'
            },
            { id:16,
            name: '16 - Charente'
            },
            { id:17,
            name: '17 - Charente-Maritime'
            },
            { id:18,
            name: '18 - Cher'
            },
            { id:19,
            name: '19 - Corrèze'
            },
            { id:20,
            name: '20 - Corse'
            },
            { id:21,
            name: '21 - Côte-d\'Or'
            },
            { id:22,
            name: '22 - Côtes d\'Armor'
            },
            { id:23,
            name: '23 - Creuse'
            },
            { id:24,
            name: '24 - Dordogne'
            },
            { id:25,
            name: '25 - Doubs'
            },
            { id:26,
            name: '26 - Drôme'
            },
            { id:27,
            name: '27 - Eure'
            },
            { id:28,
            name: '28 - Eure-et-Loir'
            },
            { id:29,
            name: '29 - Finistère'
            },
            { id:30,
            name: '30 - Gard'
            },
            { id:31,
            name: '31 - Haute-Garonne'
            },
            { id:32,
            name: '32 - Gers'
            },
            { id:33,
            name: '33 - Gironde'
            },
            { id:34,
            name: '34 - Hérault'
            },
            { id:35,
            name: '35 - Ille-et-Vilaine'
            },
            { id:36,
            name: '36 - Indre'
            },
            { id:37,
            name: '37 - Indre-et-Loire'
            },
            { id:38,
            name: '38 - Isère'
            },
            { id:39,
            name: '39 - Jura'
            },
            { id:40,
            name: '40 - Landes'
            },
            { id:41,
            name: '41 - Loir-et-Cher'
            },
            { id:42,
            name: '42 - Loire'
            },
            { id:43,
            name: '43 - Haute-Loire'
            },
            { id: 44,
            name: '44 - Loire-Atlantique'
            },
            { id:45,
            name: '45 - Loiret'
            },
            { id:46,
            name: '46 - Lot'
            },
            { id:47,
            name: '47 - Lot-et-Garonne'
            },
            { id:48,
            name: '48 - Lozère'
            },
            { id:49,
            name: '49 - Maine-et-Loire'
            },
            { id:50,
            name: '50 - Manche'
            },
            { id:51,
            name: '51 - Marne'
            },
            { id:52,
            name: '52 - Haute-Marne'
            },
            { id:53,
            name: '53 - Mayenne'
            },
            { id:54,
            name: '54 - Meurthe-et-Moselle'
            },
            { id:55,
            name: '55 - Meuse'
            },
            { id:56,
            name: '56 - Morbihan'
            },
            { id:57,

            name: '57 - Moselle'
            },
            { id:58,
            name: '58 - Nièvre'
            },
            { id:59,
            name: '59 - Nord'
            },
            { id:60,
            name: '60 - Oise'
            },
            { id:61,
            name: '61 - Orne'
            },
            { id:62,
            name: '62 - Pas-de-Calais'
            },
            { id:63,
            name: '63 - Puy-de-Dôme'
            },
            { id:64,
            name: '64 - Pyrénées-Atlantiques'
            },
            { id:65,
            name: '65 - Hautes-Pyrénées'
            },
            { id:66,
            name: '66 - Pyrénées-Orientales'
            },
            { id:67,
            name: '67 - Bas-Rhin'
            },
            { id:68,
            name: '68 - Haut-Rhin'
            },
            { id:69,
            name: '69 - Rhône'
            },
            { id:70,
            name: '70 - Haute-Saône'
            },
            { id:71,
            name: '71 - Saône-et-Loire'
            },
            { id:72,
            name: '72 - Sarthe'
            },
            { id:73,
            name: '73 - Savoie'
            },
            { id:74,
            name: '74 - Haute-Savoie'
            },
            { id:75,
            name: '75 - Paris'
            },
            { id:76,
            name: '76 - Seine-Maritime'
            },
            { id:77,
            name: '77 - Seine-et-Marne'
            },
            { id:78,
            name: '78 - Yvelines'
            },
            { id:79,
            name: '79 - Deux-Sèvres'
            },
            { id:80,
            name: '80 - Somme'
            },
            { id:81,
            name: '81 - Tarn'
            },
            { id:82,
            name: '82 - Tarn-et-Garonne'
            },
            { id:83,
            name: '83 - Var'
            },
            { id:84,
            name: '84 - Vaucluse'
            },
            { id:85,
            name: '85 - Vendée'
            },
            { id:86,
            name: '86 - Vienne'
            },
            { id:87,
            name: '87 - Haute-Vienne'
            },
            { id:88,
            name: '88 - Vosges'
            },
            { id:89,
            name: '89 - Yonne'
            },
            { id:90,
            name: '90 - Territoire de Belfort'
            },
            { id:91,
            name: '91 - Essonne'
            },
            { id:92,
            name: '92 - Hauts-de-Seine'
            },
            { id:93,
            name: '93 - Seine-Saint-Denis'
            },
            { id:94,
            name: '94 - Val-de-Marne'
            },
            { id:95,
            name: '95 - Val-d\'Oise'
            },
            { id:971,
            name: '971 - Guadeloupe'
            },
            { id:972,

            name: '972 - Martinique'
            },
            { id:973,
            name: '973 - Guyane'
            },
            { id:974,
            name: '974 - La Réunion'
            },
            { id:976,
            name: '976 - Mayotte'
            },
            { id:977,
            name: '977 - Saint-Barthélemy'
            },
            { id:978,
            name: '978 - Saint-Martin'
            },
            { id:987,
            name: '987 - Polynésie française'
            },
            { id:988,
            name: '988 - Nouvelle-Calédonie'
            },
            { id:989,
            name: '989 - Wallis-et-Futuna'
            },
            { id:99,
            name: '99 - Etranger'
            }
        ];

const openFour = (row) => {
  router.push({
    name: 'Fournisseur',
    params: { id: row.siren }
  })
}

const changeCat = (cat) => {
    document.querySelectorAll('.btn-metiers').forEach((el) => {
        el.classList.remove('btn-purple')
    })
    document.getElementById('cat_' + cat).classList.add('btn-purple')


    switch(cat) {
        case 'all':
            naf.value = ''
            break
        case 'nettoyage':
            naf.value = '81.21Z'
            break
        case 'elec':
            naf.value = '43.21A'
            break
        case 'plomb':
            naf.value = '43.22A'
            break
        case 'peintre':
            naf.value = '43.34Z'
            break
        case 'macon':
            naf.value = '41.20A'
            break
        case 'paysage':
            naf.value = '81.30Z'
            break
        case 'menui':
            naf.value = '43.32A'
            break
        case 'asc':
            naf.value = '43.29B'
            break
        case 'jur':
            naf.value = '69.10Z'
            break
    }
}

const getMetiers = async () => {
    /*const search = search_tmp
    console.log(search)*/
    //loading(true)

    await store.dispatch('fournisseur/metiersList', {})


    const imm = store.getters['fournisseur/metiersList']
    console.log(imm)
    metiers.value = imm;

}

const getDataFromPage = async (page) => {
  await getRoleList({
    sort: sort.value,
    naf: naf.value,
    localisation: localisation.value,
    query: search.value,
    nr: page,
    perpage: 15
  })
}

const reactiveSearch = async () => {
  await getRoleList({
    sort: sort.value,
    naf: naf.value,
    query: search.value,
    localisation: localisation.value,
    nr: 1,
    perpage: 15
  })
}

const handleScroll = (e) => {
  const masonry = e.target
  if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
    if (metaPage.value.current_page === 1) {
      page_t.value = 1
    }
    if (page_t.value <= metaPage.value.current_page && page_t.value <= metaPage.value.last_page) {
      page_t.value += 1
      getDataFromPage(page_t.value)
    }
  }
}

onMounted(async () => {
  await getRoleList({
    sort: sort.value,
    naf: naf.value,
    localisation: localisation.value,
    query: '',
    nr: 1,
    perpage: 15
  })
  getMetiers()
})

watch([sort, naf,localisation, search], reactiveSearch)
</script>

<style>
.dataTable-top {
  display: none;
}
.el-radio-button {
  color: red;
  padding: 20em;
}
.el-radio-button .el-radio-button__inner {
  color: blue;
}
.el-radio-button .el-radio-button__inner:hover {
  color: red;
}
</style>
